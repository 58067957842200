import { render, staticRenderFns } from "./TemperaturesFromDateToDate.vue?vue&type=template&id=870136c2&scoped=true&"
import script from "./TemperaturesFromDateToDate.vue?vue&type=script&lang=js&"
export * from "./TemperaturesFromDateToDate.vue?vue&type=script&lang=js&"
import style0 from "./TemperaturesFromDateToDate.vue?vue&type=style&index=0&id=870136c2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "870136c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VTab,VTabItem,VTabs,VTabsItems,VTooltip})
