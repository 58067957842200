<!--
  Vendored in from https://github.com/jperelli/vue2-leaflet-polylinedecorator
  due to experiencing https://github.com/jperelli/vue2-leaflet-polylinedecorator/issues/2
  when trying to use the prebuilt version (2.0.1) published on NPM.
-->

<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet-polylinedecorator'

import { findRealParent, propsBinder } from 'vue2-leaflet'

const props = {
  paths: {
    type: Array,
    default: () => []
  },
  patterns: {
    type: Array,
    default: () => []
  },
  visible: {
    type: Boolean,
    custom: true,
    default: true
  }
}

export default {
  name: 'LPolylineDecorator',
  props,
  data () {
    return {
      ready: false
    }
  },
  mounted () {
    const polyline = L.polyline(this.paths)
    const options = { patterns: this.patterns }
    this.mapObject = L.polylineDecorator(polyline, options)
    L.DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, props)
    this.ready = true
    this.parentContainer = findRealParent(this.$parent)
    if (this.parentContainer) {
      this.parentContainer.addLayer(this, !this.visible)
    }
  },
  beforeDestroy () {
    if (this.parentContainer) {
      this.parentContainer.removeLayer(this)
    }
  },
  methods: {
    setVisible (newVal, oldVal) {
      if (newVal === oldVal) return
      if (this.parentContainer) {
        if (newVal) {
          this.parentContainer.addLayer(this)
        }
        else {
          this.parentContainer.removeLayer(this)
        }
      }
    },
    addLatLng (value) {
      if (this.mapObject) {
        this.mapObject.addLatLng(value)
      }
    }
  }
}
</script>
