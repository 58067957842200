<template>
  <v-row class="fill-height">
    <v-col class="col-max-height">
      <current-fuel-tank-capacity />
    </v-col>
    <v-col class="col-max-height">
      <fuel-probe-info-from-date-to-date />
    </v-col>
    <v-col class="col-max-height">
      <fuel-dispensing />
    </v-col>
  </v-row>
</template>

<script>

import CurrentFuelTankCapacity from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-details-section/sections/CurrentFuelTankCapacity'
import FuelDispensing from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-details-section/sections/FuelDispensing'
import FuelProbeInfoFromDateToDate from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-details-section/sections/FuelProbeInfoFromDateToDate'

export default {

  name: 'ProbeDetailsSections',

  components: {
    CurrentFuelTankCapacity,
    FuelDispensing,
    FuelProbeInfoFromDateToDate
  }
}
</script>
