<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <splitpanes
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
      @resize="resizeMap = !resizeMap"
    >
      <pane
        size="85"
        max-size="85"
        min-size="85"
      >
        <!-- lazy because of problems with vuetify and vue-leaflet https://github.com/vue-leaflet/Vue2Leaflet/issues/96 -->
        <v-lazy class="h-full">
          <!-- Important to have wrapping div when using `v-lazy`, otherwise map layout will be broken -->
          <div class="h-full">
            <map-base-tracking-history
              class="h-full"
              :zoom-on="zoomOn"
              ref="mapTrackingHistory"
              @set-position-marker-radius="radius => setPositionMarkerRadius(radius)"
            >
              <template #controls>
                <expendable-side-nav
                  :keep-expanded="keepExpanded"
                  :ignore-internal-selection="true"
                >
                  <v-tabs height="35px">
                    <v-tab class="tracking-tab" active-class="tracking-tab-active">
                      {{ $t('satellite-tracking/history.options') }}
                    </v-tab>
                    <v-tab-item>
                      <map-controls />
                    </v-tab-item>
                  </v-tabs>
                </expendable-side-nav>
              </template>
              <template
                v-if="getSelectedTrips && getSelectedTrips.length"
              >
                <map-route
                  v-for="route in getSelectedTrips"
                  :key="route.key"
                  :vehicle-type="getVehicleType()"
                  :vehicle-id="getVehicleId()"
                  :route="route"
                  :is-route-clicked="clickedRouteKey === route.key"
                  :position-marker-radius="positionMarkerRadius"
                  :alarm-icons="alarmIcons"
                  @clicked-route="setClickedRoute"
                />
              </template>
              <template
                v-if="getSelectedTask && getSelectedTask.length"
              >
                <map-task
                  v-for="route in getSelectedTask"
                  :key="route.key"
                  :route="route"
                />
              </template>
              <template
                v-if="getSelectedTripChunks && getSelectedTripChunks.length"
              >
                <map-task
                  v-for="route in getSelectedTripChunks"
                  :key="route.key"
                  :route="route"
                  :weight="3"
                />
              </template>
            </map-base-tracking-history>
          </div>
        </v-lazy>
      </pane>
      <pane
        size="15"
        min-size="15"
        max-size="15"
      >
        <report-controls
          v-model="selection"
          @dialog-open-status="dialogOpenStatus = $event"
          @vehicle-selected-status="vehiclesSelectedStatus = $event"
          @input-tasks="selectionTasks = $event"
          @input-overlap="selectionOverlap = $event"
          @report="selectionReport = $event"
        />
      </pane>
    </splitpanes>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import MapBaseTrackingHistory from '@/modules/road-maintenance-module/reports/components/MapBaseTrackingHistory'
import MapControls from '@/modules/road-maintenance-module/reports/components/MapControls'
import MapRoute from '@/modules/road-maintenance-module/reports/components/MapRoute'
import MapTask from '@/modules/road-maintenance-module/reports/components/MapTask'
import ExpendableSideNav from '@/global/components/ExpendableSideNav'
import ReportControls from '@/modules/road-maintenance-module/reports/components/ReportControls.vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash'
import {
  updatePageToBeFullHeightOfTheViewport, removeHeightStylesFromTheMainElement
} from '@/global/services/helpers/elementStyles'
import polyUtil from 'polyline-encoded'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('road-maintenance/tasks-report')

export default {
  name: 'RoadMaintenanceReport',

  components: {
    MapBaseTrackingHistory,
    MapControls,
    MapRoute,
    ExpendableSideNav,
    ReportControls,
    Splitpanes,
    Pane,
    MapTask
  },

  data () {
    return {
      positionMarkerRadius: 0,
      expandedView: false,
      routes: [],
      selection: {
        invalid: false,
        vehicles: [],
        from: null,
        to: null,
        task: null,
        company_scope_id: null
      },
      tripsLoaded: false,
      shaking: false,
      pickerActivator: null,
      resizeMap: false,
      dialogOpenStatus: false,
      vehiclesSelectedStatus: null,
      clickedRouteKey: null,
      selectionTasks: {
        invalid: false,
        task: null,
        company_scope_id: null
      },
      selectionOverlap: {
        invalid: false,
        vehicles: [],
        from: null,
        to: null,
        task: null,
        company_scope_id: null
      },
      selectionReport: {
        saltAmount: null,
        task: null,
        vehicles: [],
        from: null,
        to: null
      },
      selectedTask: {
        data: []
      }
    }
  },

  computed: {
    ...mapGetters([
      'alarmIcons',
      'checkboxes',
      'loading',
      'viewConfig',
      'getSelectedTrips',
      'getSelectedTripChunks',
      'getSelectedTask',
      'selectedDigitalInputType',
      'sensorActivityData',
      'tasks',
      'trips',
      'taskDetails',
      'taskOverlap'
    ]),

    keepExpanded () {
      return this.dialogOpenStatus
    },

    zoomOn () {
      if (this.checkboxes.autoCenter && this.routes.length) {
        return this.routes.map(route => route.positions).flat()
      }
      return null
    },

    expandButtonIcon () {
      return this.expandedView
        ? 'mdi-chevron-down'
        : 'mdi-chevron-up'
    },

    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    },

    mapComponentStyles () {
      return {
        height: this.expandedView ? '0%' : 'calc(100% - 35px)'
      }
    },

    tripListStyles () {
      return {
        height: this.expandedView ? '100%' : '0%'
      }
    },

    invalid () {
      return this.selection.invalid
    },

    vehicles () {
      this.setSelectedVehiclesCount(this.selection.vehicles.length)
      return this.selection.vehicles
    },

    from () {
      return this.selection.from
    },

    to () {
      return this.selection.to
    },

    companyScopeId () {
      return this.selection.company_scope_id
    },

    task () {
      return this.selection.task
    },

    positions (item) {
      return polyUtil.decode(item.polyline)
    }
  },

  mounted () {
    if (this.getSelectedTrips && this.getSelectedTrips.length) this.setSelectedTrips([])
    if (this.getSelectedTask && this.getSelectedTask.length) this.setSelectedTask([])
    if (this.getSelectedTripChunks && this.getSelectedTripChunks.length) this.setSelectedTripChunks([])
  },

  watch: {
    // We debounce the map resize to prevent the map from resizing too often because the resizeMap event is triggered on pixel change
    resizeMap: debounce(function (newVal) {
      this.$refs.mapTrackingHistory.invalidateMapSize()
    }, 200),

    // Load trips when a selection is made
    selection: {
      deep: true,
      handler () {
        if (!this.invalid) {
          this.clearCheckboxes()
          this.clearMapControlInputs()
          this.setCompanyScopeId(this.companyScopeId)
          this.setSelectedVehicles(this.vehicles.map(vehicle => vehicle.id))
          this.setDateRange({
            from: this.from,
            to: this.to
          })

          this.selectedTrips = []

          this.fetchTrips({
            vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
            from: this.from,
            to: this.to,
            company_scope_id: this.companyScopeId
          }).then(() => {
            this.tripsLoaded = true
            this.setSelectedTrips(this.trips)
            this.setSelectedTripChunks([])
          })
        }
        else {
          this.setSelectedTrips([])
        }
      }
    },
    selectionTasks: {
      deep: true,
      handler: async function () {
        if (!this.selectionTasks.invalid) {
          this.fetchTaskDetails({
            task_id: this.selectionTasks.task,
            company_scope_id: this.companyScopeId
          }).then(() => {
            this.setSelectedTask(this.taskDetails)
            this.setSelectedTripChunks([])
          })
        }
        else {
          this.setSelectedTask([])
        }
      }
    },
    selectionOverlap: {
      deep: true,
      handler: async function () {
        if (!this.selectionOverlap.invalid) {
          // go to backend and fetch all info
          this.fetchTaskOverlap({
            task_id: this.selectionOverlap.task,
            vehicle_ids: this.selectionOverlap.vehicles.map(vehicle => vehicle.id),
            from: this.selectionOverlap.from,
            to: this.selectionOverlap.to,
            company_scope_id: this.selectionOverlap.company_scope_id
          }).then(() => {
            this.setSelectedTask(this.taskOverlap.tasks)
            this.setSelectedTripChunks(this.taskOverlap.tripChunks)
          })
        }
        else {
          this.setSelectedTask([])
          this.setSelectedTripChunks([])
        }
      }
    },
    selectionReport: {
      deep: true,
      handler: async function () {
        if (this.selectionReport.saltAmount >= 0) {
          this.setLoading(true)

          const url = '/api/road-maintenance/task-overlap-report'

          return fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              task_id: this.selectionReport.task,
              vehicle_ids: this.selectionReport.vehicles.map(vehicle => vehicle.id),
              from: this.selectionReport.from,
              to: this.selectionReport.to,
              salt_amount: this.selectionReport.saltAmount
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(res => {
              if (!res.ok) {
                const responseStatusText = res.statusText
                const errorMessage = `${responseStatusText}`
                throw new Error(errorMessage)
              }

              return res.arrayBuffer()
            })
            .then(arrayBuffer => {
              // BE endpoint sends a readable stream of bytes
              const byteArray = new Uint8Array(arrayBuffer)
              const link = window.document.createElement('a')
              link.href = window.URL.createObjectURL(
                new Blob([byteArray], { type: 'application/pdf' })
              )
              link.download = this.$t('road-maintenance/tasks.report_title') + '.pdf'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
            .catch(error => {
              throw new Error('Error occurred:' + error)
            })
            .finally(() => {
              this.setLoading(false)
            })
        }
      }
    }
  },

  beforeCreate () {
    // We need to set height styles manually, because `main` element is located
    // in the parent component, so we can't set its styles from the child component
    updatePageToBeFullHeightOfTheViewport()
  },

  beforeDestroy () {
    // We need to unset styles manually before leaving the page (so it won't affect other index pages),
    // because `main` element is located in the parent component, so we can't set its styles from the child component
    removeHeightStylesFromTheMainElement()
  },

  async created () {
    await this.fetchConfig()
  },

  methods: {
    ...mapActions([
      'fetchConfig',
      'fetchTrips',
      'fetchTaskDetails',
      'setSelectedVehicles',
      'setSelectedVehiclesCount',
      'setCompanyScopeId',
      'fetchSensorActivityData',
      'fetchTripPositions',
      'fetchTripAlarms',
      'updateCheckboxes',
      'setSelectedDigitalInputType',
      'setDateRange',
      'fetchTasks',
      'setSelectedTrips',
      'setSelectedTripChunks',
      'setSelectedTask',
      'setLoading',
      'fetchTaskOverlap',
      'fetchReport'
    ]),

    getVehicleType () {
      // we can explicitly take first element because in tracking history it is available
      // to select only one vehicle
      return this.selection.vehicles?.[0]?.type?.category
    },

    getVehicleId () {
      return this.selection.vehicles?.[0]?.id
    },

    setPositionMarkerRadius (radius) {
      this.positionMarkerRadius = radius
    },

    setClickedRoute (routeKey) {
      this.clickedRouteKey = routeKey
    },

    clearCheckboxes () {
      this.updateCheckboxes({ field: 'showDinStatus', value: false })
      this.updateCheckboxes({ field: 'showRouteAlarms', value: false })
    },

    clearMapControlInputs () {
      this.setSelectedDigitalInputType(null)
    }
  }
}
</script>

<style scoped lang="scss">
#mainWrapper >>> .main-wrapper {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}

.animated-height {
  will-change: height;
  transition: height 0.25s ease-in;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: auto;
  min-width: 1.7rem;
  width: 1.7rem;
  padding: 0.7rem 0.2rem;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: normal;
  text-transform: none;
}
.v-tab--active {
  background: #E2E2E2;
}
::v-deep .v-tabs-slider-wrapper {
  transform: translateX(1.5rem);
  width: 3px !important;
  .v-tabs-slider {
    width: 3px !important;
    background-color: #6aac49;
  }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #F8F8F8 !important;
  &.data-table-header {
    padding-top: 1.5rem !important;
    vertical-align: center;
    span:after {
      content: "\a";
      white-space: pre;
    }
  }
}
</style>
