export function updatePageToBeFullHeightOfTheViewport () {
  const main = document.querySelector('main')
  main.style.height = 'calc(var(--vh, 1vh) * 100)'
  main.style.maxHeight = 'calc(var(--vh, 1vh) * 100)'
}

export function removeHeightStylesFromTheMainElement () {
  const main = document.querySelector('main')
  main.style.height = null
  main.style.maxHeight = null
}
