<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    :showUserLocationsValue="showUserLocations"
    :showPartnerLocationsValue="showPartnerLocations"
    @set-position-marker-radius="positionMarkerRadius => this.$emit('set-position-marker-radius', positionMarkerRadius)"
  >
    <template #controls>
      <slot name="controls"/>
    </template>
    <template #mapContent>
      <slot name="default"/>
    </template>
  </map-base>
</template>

<script>
import L from 'leaflet'
import polyUtil from 'polyline-encoded'
import { createNamespacedHelpers } from 'vuex'
import MapBase from '@/global/components/map/MapBase.vue'
const { mapGetters } = createNamespacedHelpers('road-maintenance/tasks-report')

export default {
  name: 'MapBaseTrackingHistory',

  components: {
    MapBase
  },

  props: {
    zoomOn: {
      type: Array,
      default: null,
      validator (positions) {
        return Array.isArray(positions) && positions.every(position => position instanceof L.LatLng)
      }
    }
  },

  data () {
    return {
      zoom: 14,
      showTrackerOptions: true,
      mapStyle: {
        zIndex: 0,
        flex: 1
      }
    }
  },

  computed: {
    ...mapGetters(['getSelectedTrips', 'checkboxes', 'getSelectedTask']),

    showUserLocations () {
      return this.checkboxes.showUserLocations
    },

    showPartnerLocations () {
      return this.checkboxes.showPartnerLocations
    }
  },

  watch: {
    zoomOn (positions) {
      if (positions) {
        this.$refs?.mapBase?.$refs?.map?.fitBounds(positions)
      }
    },
    getSelectedTrips (trips) {
      const positions = []
      trips.forEach(trip => {
        positions.push(polyUtil.decode(trip.polyline))
      })
      if (positions.length) {
        this.$refs?.mapBase?.$refs?.map?.fitBounds(positions)
      }
    },
    getSelectedTask (streets) {
      const positions = []
      streets.forEach(street => {
        positions.push(polyUtil.decode(street.polyline))
      })
      if (positions.length) {
        this.$refs?.mapBase?.$refs?.map?.fitBounds(positions)
      }
    }
  },

  methods: {
    // Invalidate map size function to be called from parent component so the missing tiles are fetched
    invalidateMapSize () {
      this.$refs?.mapBase?.$refs?.map?.mapObject?.invalidateSize()
    }
  }
}
</script>

<style>
/*
  If multiple controls are added into the controls slot, they should be separated with a v-divider.
  This styles the v-divider to maintain consistent spacing.
*/
.leaflet-control .v-divider{
  margin: 8px 0;
}
.v-icon {
  font-size: 1.5rem;
}
.v-label {
  font-size: 0.82rem;
}
.picker-activator-wrapper {
  position: absolute;
  top: 0.75rem;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
}
@media (max-width: 960px) {
  .picker-activator-wrapper {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 1.75rem;
    right: 1rem;
    z-index: 1000;
    transform: translateX(0);
  }
}
</style>
