<template>
  <v-card @keydown.enter.prevent="closeDatetimePicker">
    <v-card-text>
      <vehicle-picker
        v-model="vehicles"
        class="mt-2"
        :view-config="viewConfig"
        :max-select="10"
        :show-vehicles-with-assignments="true"
        :start-open="openVehiclesModal"
        @updated-scope="companyScopeId = $event"
        @has-selected-vehicles="vehiclesSelectedStatus = $event"
      />
      <v-row>
        <v-col>
          <v-menu
            v-model="fromMenu"
            transition="slide-y-transition"
            bottom
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="fromFormatted"
                :label="$t('base.from')"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="parseDmyDate(fromFormatted)"
              />
            </template>
            <datetime-picker
              v-model="from"
              :emit-default-time="true"
              :default-time="'00:00:00'"
              :max-date="today"
              :reset-tabs="resetFromTabs"
              @close-picker="fromMenu = false"
              :on-double-click="closeDatetimePicker"
            >
              <v-col class="white text-right pb-2 px-2">
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="fromMenu = false"
                >
                  {{ $t('base.ok') }}
                </v-btn>
              </v-col>
            </datetime-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="toMenu"
            transition="slide-y-transition"
            bottom
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="toFormatted"
                :label="$t('base.to')"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="parseDmyDate(toFormatted)"
              />
            </template>
            <datetime-picker
              v-model="to"
              :emit-default-time="true"
              :default-time="defaultToTime"
              :min-date="minDate"
              :max-date="maxToDate"
              :max-time="maxToTime"
              :reset-tabs="resetToTabs"
              @close-picker="toMenu = false"
              :on-double-click="closeDatetimePicker"
            >
              <v-col class="white text-right pb-2 px-2">
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="toMenu = false"
                >
                  {{ $t('base.ok') }}
                </v-btn>
              </v-col>
            </datetime-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-row class="picker-submit-btn-wrapper">
        <div class="picker-submit-btn">
          <v-btn
            :class="{ shaking }"
            @click="checkDataAndFireEvent()"
          >
            {{ $t('base.ok') }}
          </v-btn>
        </div>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('satellite-tracking/tracking-history')

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'VehiclesAndDatesPicker',

  components: {
    DatetimePicker,
    VehiclePicker
  },

  props: {
    activator: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      today: new Date().toISOString().slice(0, 10),
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null,
      vehicles: [],
      shaking: false,
      companyScopeId: null,
      fromMenu: false,
      toMenu: false,
      resetFromTabs: false,
      resetToTabs: false,
      openVehiclesModal: false,
      vehiclesSelectedStatus: null
    }
  },

  computed: {
    ...mapGetters(['viewConfig']),

    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    },

    maxToDate () {
      if (this.from) {
        const maxDate = dayjs(this.from).add(31, 'day')
        return maxDate.isAfter(this.today) ? this.today : maxDate.toISOString()
      }

      return this.today
    },

    maxToTime () {
      if (dayjs(this.to).isToday()) return dayjs().format('HH:mm')

      return this.defaultToTime
    }
  },

  watch: {
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },

    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    },

    vehiclesSelectedStatus (value) {
      this.$emit('vehicle-selected-status', value)
    }
  },

  created () {
    this.from = dayjs().startOf('day').toISOString()
    this.to = dayjs().toISOString()
    if (!this.selectedVehiclesCount) {
      this.openVehiclesModal = true
    }
  },

  methods: {
    checkDataAndFireEvent () {
      // Emit selection when button is clicked and data is ok
      if (this.vehicles.length && this.from && this.to) {
        this.$emit('input', {
          vehicles: this.vehicles,
          from: this.from,
          to: this.to,
          company_scope_id: this.companyScopeId
        })
      }
      // Otherwise, report that the selection is invalid
      else {
        this.shaking = true
        setTimeout(() => {
          this.shaking = false
        }, 600)
        this.$emit('input', {
          invalid: true
        })
      }
    },

    closeDatetimePicker () {
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    }
  }
}
</script>

<style scoped lang="scss">
.picker-submit-btn-wrapper {
  height: 50px;

  .picker-submit-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-25%, -50%);

    .shaking {
      animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
  }
}

</style>
