<template>
  <div>
    <!-- registration -->
    <v-row
      v-if="vehicleDetailsCheckboxes
        .vehicle
        .find(checkbox => checkbox.key === 'registration')
        .showInTooltip || getSingleTooltipId === vehicle.vehicleId"
      no-gutters
      class="black px-2 rounded fit-content"
    >
      <strong class="white--text text-no-wrap">
        {{ markerDetails.registration }}
      </strong>
    </v-row>
    <!-- ./ registration -->
    <!-- speed -->
    <v-row
      v-if="vehicleDetailsCheckboxes
        .marker
        .find(checkbox => checkbox.key === 'speed')
        .showInTooltip || getSingleTooltipId === vehicle.vehicleId"
      no-gutters
      class="grey darken-1 px-2 rounded mt-01 fit-content"
    >
      <strong class="white--text text-no-wrap">
        {{ markerDetails.speed }}
      </strong>
    </v-row>
    <!-- ./ speed -->
    <!-- voltage -->
    <v-row
      v-if="vehicleDetailsCheckboxes
        .marker
        .find(checkbox => checkbox.key === 'voltage')
        .showInTooltip || getSingleTooltipId === vehicle.vehicleId"
      no-gutters
      class="grey darken-1 px-2 rounded mt-01 fit-content"
    >
      <strong class="white--text text-no-wrap">
        {{ markerDetails.voltage }}
      </strong>
    </v-row>
    <!-- ./ voltage -->
    <!-- lastPositionChangeDateTime -->
    <v-row
      v-if="(vehicleDetailsCheckboxes
        .marker
        .find(checkbox => checkbox.key === 'lastPositionChangeDateTime')
        .showInTooltip || getSingleTooltipId === vehicle.vehicleId)
        && vehicle.positionChangeThreshold && (getPassedTime(vehicle.lastPositionChangeDateTime) >= vehicle.positionChangeThreshold) && markerDetails.speedNumber == 0"
      no-gutters
      class="grey darken-1 px-2 rounded mt-01 fit-content"
    >
      <strong class="white--text text-no-wrap">
        {{ markerDetails.lastPositionChangeDateTime }}
      </strong>
    </v-row>
    <!-- ./ lastPositionChangeDateTime -->
    <!-- details -->
    <div
      v-if="vehicleDetailsCheckboxes
        .details
        .find(checkbox => checkbox.key === 'details')
        .showInTooltip || getSingleTooltipId === vehicle.vehicleId"
      class="pa-2 mt-01 custom-vehicle-details"
    >
      <p class="my-0">
        <strong>
          {{ markerDetails.labelAndDriver }}
        </strong>
      </p>
      <p class="my-0">
        <strong>
          {{ markerDetails.altitude }}
        </strong>
      </p>
      <p class="my-0">
        <strong>
          {{ markerDetails.deviceTime }}
        </strong>
      </p>
      <template
        v-if="(markerDetails.digitalSensors && Object.keys(markerDetails.digitalSensors).length > 0) ||
          (markerDetails.bluetoothSensors && Object.keys(markerDetails.bluetoothSensors).length > 0) ||
          (markerDetails.temperatureSensors && Object.keys(markerDetails.temperatureSensors).length > 0) ||
          (markerDetails.analogSensors && Object.keys(markerDetails.analogSensors).length > 0)"
      >
        <p
          :key="'sensor-status-label'"
          class="my-0"
        >
          <strong>
            {{ `${this.$t('base/tracker.sensor_status')}:` }}

          </strong>
        </p>
      </template>

      <!-- digital sensors -->
      <template v-if="markerDetails.digitalSensors && Object.keys(markerDetails.digitalSensors).length > 0">
        <p
          v-for="([inputName, value], index) in Object.entries(markerDetails.digitalSensors)"
          :key="markerDetails.registration + '-din-' + index"
          class="my-0"
        >
          <strong>
            {{ `${inputName}: ${value}` }}
          </strong>
        </p>
      </template>
      <!-- ./ digital sensors -->

      <!-- bluetooth sensors -->
      <template v-if="markerDetails.bluetoothSensors && Object.keys(markerDetails.bluetoothSensors).length > 0">
        <p
          v-for="([inputName, value], index) in Object.entries(markerDetails.bluetoothSensors)"
          :key="markerDetails.registration + '-bt-' + index"
          class="my-0"
        >
          <strong>
            {{ `${inputName}: ${value}` }}
          </strong>
        </p>
      </template>
      <!-- ./ bluetooth sensors -->

      <!-- analog sensors -->
      <template v-if="markerDetails.analogSensors && Object.keys(markerDetails.analogSensors).length > 0">
        <p
          v-for="([inputName, value], index) in Object.entries(markerDetails.analogSensors)"
          :key="markerDetails.registration + '-ain-' + index"
          class="my-0"
        >
          <strong>
            {{ `${inputName}: ${value}` + (isNaN(value) ? '' : 'V') }}
          </strong>
        </p>
      </template>
      <!-- ./ analog sensors -->

      <!-- temperature sensors -->
      <template v-if="markerDetails.temperatureSensors && Object.keys(markerDetails.temperatureSensors).length > 0">
        <p
          v-for="([sensorName, value], index) in Object.entries(markerDetails.temperatureSensors)"
          :key="markerDetails.registration + '-temp-' + index"
          class="my-0"
        >
          <strong>
            {{ `${sensorName}: ${value}` }}
          </strong>
        </p>
      </template>
      <!-- ./ temperature sensors -->
    </div>
    <!-- ./ details -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { convertValueToNumberString } from '@/global/services/helpers/unitsConversion'
import dayjs from 'dayjs'
import { formatSqlDateTime } from '@/global/services/helpers/dates'

const {
  mapGetters: mapGettersTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'CustomMarkerTooltipDetails',

  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGettersTracking([
      'vehicleDetailsCheckboxes',
      'getInputTypes',
      'getSingleTooltipId'
    ]),

    markerDetails () {
      if (this.vehicle) {
        return {
          registration: this.vehicle.registration,
          labelAndDriver: this.getLabelAndDriver(),
          speed: `${this.vehicle.speed} ${this.vehicle.speedUnit}`,
          voltage: `${(convertValueToNumberString(this.vehicle.voltage, 2) ?? 0)} V`,
          altitude: `${this.getCheckboxLabel('altitude')}: ${this.vehicle.altitude}`,
          deviceTime: `${this.getCheckboxLabel('deviceTime')}: ${formatSqlDateTime(this.vehicle.lastDateTime)}`,
          lastPositionChangeDateTime: this.calculatePassedTimeFormatted(this.vehicle.lastPositionChangeDateTime),
          digitalSensors: this.vehicle.digitalSensors && JSON.parse(this.vehicle.digitalSensors),
          bluetoothSensors: this.vehicle.bluetoothSensors && JSON.parse(this.vehicle.bluetoothSensors),
          temperatureSensors: this.vehicle.temperatureSensors && JSON.parse(this.vehicle.temperatureSensors),
          analogSensors: this.vehicle.analogSensors && JSON.parse(this.vehicle.analogSensors),
          speedNumber: this.vehicle.speed
        }
      }
      return {
        registration: 'n/a',
        speed: 'n/a',
        voltage: 'n/a',
        altitude: 'n/a',
        deviceTime: 'n/a',
        lastPositionChangeDateTime: 'n/a',
        digitalSensors: null,
        bluetoothSensors: null,
        temperatureSensors: null,
        analogSensors: null,
        speedNumber: null
      }
    }
  },

  methods: {
    getLabelAndDriver () {
      const driver = this.vehicle?.driverFirstName ? ' (' + this.vehicle.driverFirstName + ' ' + this.vehicle.driverLastName + ')' : ''
      return this.vehicle.label + driver
    },

    /**
     * Returns checkbox label based on passed key
     * @param key
     * @returns {*}
     */
    getCheckboxLabel (key) {
      return this.vehicleDetailsCheckboxes.marker.find(marker => marker.key === key).label
    },

    transformDateToHumanReadable (dateString) {
      const fromNowTime = dayjs(dateString).subtract(1, 's')
      return `(${fromNowTime.fromNow()}) ${formatSqlDateTime(dateString)}`
    },

    getPassedTime (date) {
      var dateTime = Date.parse(date)
      var now = new Date().getTime()
      return (now - dateTime) / 1000
    },

    calculatePassedTimeFormatted (date) {
      const diff = Math.round(this.getPassedTime(date))
      const diffHours = Math.floor(diff / 3600)
      const minutes = ('0' + Math.floor((diff % 3600) / 60)).slice(-2)
      const hours = diffHours < 10 ? '0' + diffHours : diffHours
      return hours + ':' + minutes
    }
  }
}
</script>

<style scoped lang="scss">
.custom-vehicle-details {
  border: grey solid 2px;
  border-radius: 12px;
  white-space: nowrap;
  background-color: white;
}

.fit-content {
  width: fit-content;
}

.mt-01 {
  margin-top: 1px;
}
</style>
