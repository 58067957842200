<template>
  <div class="fill-height">
    <v-row class="py-0">
      <v-col />
      <v-col
        class="text-center col-12 col-sm-auto align-self-center"
        :class="{
          'pa-0 pt-3' : $vuetify.breakpoint.mdAndUp,
          'pa-6 mt-0' : $vuetify.breakpoint.smAndDown
        }"
      >
        <temp-sensor-chart-title />
      </v-col>
      <v-col
        class="py-0 text-right"
        :class="{'mt-8' : $vuetify.breakpoint.md}"
      >
        <temp-sensor-chart-conditions-button />
      </v-col>
    </v-row>
    <v-row class="py-0">
      <v-col class="pb-0">
        <temp-sensor-chart-builder v-if="getTemperaturesChartData && getTemperaturesChartData.length > 0" />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import TempSensorChartTitle from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/TempSensorChartTitle'
import TempSensorChartConditionsButton from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/TempSensorChartConditionsButton'
import TempSensorChartBuilder from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/TempSensorChartBuilder'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TempSensorChart',
  components: {
    TempSensorChartConditionsButton,
    TempSensorChartTitle,
    TempSensorChartBuilder
  },
  computed: {
    ...mapGettersTemperatureSensor(['getSelectedVehicle', 'getTemperaturesChartData'])
  }
}
</script>
