<template>
  <index-view
    max-width="750px"
    no-create
    view-name="alarm_events"
    :no-actions="true"
    :module="module"
    :model="model"
    :list="list"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    :fetch="fetch"
    :fetch-config="fetchConfig"
    :show-filters="showFilters"
    :initial-filters="initialFilters"
    @set-show-filters="event => setShowFilters(event)"
    @set-initial-filter="event => setInitialFilter(event)"
    @reset-initial-filter-loaded-keys="resetInitialFilterLoadedKeys"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('satellite-tracking/alarm-events')

export default {
  name: 'AlarmEventsView',
  components: {
    'index-view': indexView
  },

  props: {
    module: {
      type: String,
      default: 'satellite-tracking'
    },

    model: {
      type: String,
      default: 'alarm-events'
    }
  },

  computed: {
    ...mapGetters([
      'initialFilters',
      'list',
      'pagination',
      'showFilters',
      'sort',
      'viewConfig'
    ])
  },

  methods: {
    ...mapActions([
      'fetch',
      'fetchConfig',
      'setShowFilters',
      'setInitialFilter',
      'resetInitialFilterLoadedKeys'
    ])
  }
}
</script>
