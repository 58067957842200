<template>
  <div>
    <v-row
      v-for="(item, index) in legendItems"
      :key="index"
      class="align-center no-gutters"
    >
      <v-checkbox
        dense
        :input-value="item.filterStatus"
        :ripple="false"
        class="mt-0 pt-0"
        hide-details
        color="info"
        @change="updateLegendDetails(item, index)"
      >
        <template #label>
          <v-row class="no-gutters pr-1 ml-n1">
            <v-col
              class="pa-0 pr-1"
              cols="auto"
            >
              <img
                :src="item.img_path"
                alt="No pic"
                class="mb-0"
              >
            </v-col>
            <v-col class="pa-0 pt-2 pl-1">
              <p class="mb-0 cb-tracker-legend-style">
                {{ item.description }}
              </p>
            </v-col>
          </v-row>
        </template>
      </v-checkbox>
    </v-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import { cloneDeep } from 'lodash'

const {
  mapGetters: mapGettersTracking,
  mapActions: mapActionsTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'TrackerLegend',

  props: {
    legendItems: {
      type: Array,
      default: () => ([])
    }
  },

  computed: {
    ...mapGettersTracking(['vehicleDetailsCheckboxes'])
  },

  methods: {
    ...mapActionsTracking(['updateVehicleDetailsCheckboxes']),
    updateLegendDetails (legendItem, index) {
      const changedVehicleDetailsCheckboxes = cloneDeep(this.vehicleDetailsCheckboxes)
      changedVehicleDetailsCheckboxes.legend[0].items[index].filterStatus = !legendItem.filterStatus
      this.updateVehicleDetailsCheckboxes(changedVehicleDetailsCheckboxes)
    }
  }
}
</script>

<style scoped lang="scss">
.cb-tracker-legend-style{
  line-height: 0;
}
</style>
