<template>
  <v-row
    class="no-gutters flex-nowrap text-no-wrap"
  >
    <v-col
      class="pa-0 align-self-center"
      :class="{ 'col-auto': $vuetify.breakpoint.mobile }"
    >
      <title-button-chart-toggler-sensor1
        v-if="sensorIndex === 0"
        class="mr-1"
      />
      <title-button-chart-toggler-sensor2
        v-else-if="sensorIndex === 1"
        class="mr-1"
      />
      <title-button-chart-toggler-sensor3
        v-else-if="sensorIndex === 2"
        class="mr-1"
      />
      <title-button-chart-toggler-sensor4
        v-else
        class="mr-1"
      />
    </v-col>
    <v-col
      class="text-no-wrap align-self-center"
    >
      <p
        class="white--text mb-0"
        :class="getClassBasedOnSensorCheckBoxStatus"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{
              sensorName.length < 10
                ? sensorName
                : sensorName.substr(0, 7) + '...'
            }}
            </span>
          </template>
          <span>{{ sensorName }}</span>
        </v-tooltip>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TitleButtonChartTogglerSensor1
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/ChartTogglers/TitleButtonChartTogglerSensor1'
import TitleButtonChartTogglerSensor2
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/ChartTogglers/TitleButtonChartTogglerSensor2'
import TitleButtonChartTogglerSensor3
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/ChartTogglers/TitleButtonChartTogglerSensor3'
import TitleButtonChartTogglerSensor4
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/ChartTogglers/TitleButtonChartTogglerSensor4'
const {
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TitleTempSensorIconAndLabel',
  components: {
    TitleButtonChartTogglerSensor1,
    TitleButtonChartTogglerSensor2,
    TitleButtonChartTogglerSensor3,
    TitleButtonChartTogglerSensor4

  },
  props: {
    sensorName: {
      type: String,
      required: true
    },
    sensorIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGettersTemperatureSensor([
      'getColors',
      'getChartLineToggleStatuses'
    ]),
    getClassBasedOnSensorCheckBoxStatus () {
      switch (true) {
        case this.sensorIndex === 0 && this.getChartLineToggleStatuses.sensor1:
        case this.sensorIndex === 1 && this.getChartLineToggleStatuses.sensor2:
        case this.sensorIndex === 2 && this.getChartLineToggleStatuses.sensor3:
        case this.sensorIndex === 3 && this.getChartLineToggleStatuses.sensor4:
          return ''
        default: return 'font-weight-thin'
      }
    }
  }
}
</script>

<style scoped>
p {
  line-height: 1;
  font-size: 11px;
}
</style>
