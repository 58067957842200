<template>
  <expendable-side-nav
    :keep-expanded="keepExpanded"
  >
    <v-tabs height="35px">
      <v-tab
        class="tracking-tab"
        active-class="tracking-tab-active"
      >
        {{ $t('satellite-tracking/history.choose_vehicles') }}
      </v-tab>
      <v-tab
        class="tracking-tab"
        active-class="tracking-tab-active"
      >
        {{ $t('satellite-tracking/history.options') }}
      </v-tab>
      <v-tab-item>
        <div class="container pl-4">
          <vehicle-picker
            v-model="selectedVehicles"
            class="mt-4"
            show-list
            update-traccar-token
            trackers-only
            :title="$t('satellite-tracking/live.select_instructions')"
            :activeVehicles=true
            :preselected-vehicles="preselectedVehicles"
            :view-config="viewConfig"
            :show-picker="openVehiclesModalInternal"
            @input="$emit('vehicle-picker-input-changed', $event)"
            @fetch-input-types="$emit('digital-input-types-fetched', $event)"
            @list-vehicle-label-clicked="setClickedVehicleToStore"
            @dialog-open-status="dialogOpenStatus = $event"
          />
        </div>
      </v-tab-item>
      <v-tab-item class="pa-2">
        <v-row
          no-gutters
        >
          <v-col>
            <template
              v-for="(value, valueIndex) in vehicleDetailsCheckboxes"
            >
              <template
                v-for="(property, index) in value"
              >
                <div
                  v-if="property.showInMapControl"
                  :key="property.label + '-' + index"
                >
                  <v-checkbox
                    dense
                    :input-value="property.showInTooltip"
                    :ripple="false"
                    class="mt-0 pt-0 inactive-checkbox"
                    hide-details
                    color="info"
                    @change="updateVehicleDetails(!property.showInTooltip, valueIndex, index)"
                  >
                    <template #label>
                      <div class="d-flex align-center ml-n1">
                        <p class="mb-0 cb-label-style">
                          {{ property.label }}
                        </p>
                        <v-progress-circular
                          v-if="valueIndex === 'live_route' && liveRoutesLoading"
                          class="ml-2"
                          indeterminate
                          width="2"
                          size="15"
                        />
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </template>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="ml-6 pt-2">
            <tracker-legend
              v-if="vehicleDetailsCheckboxes.legend && vehicleDetailsCheckboxes.legend[0].showInTooltip"
              :legend-items="vehicleDetailsCheckboxes.legend[0].items"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <v-overlay
      id="controls-loader"
      :value="controlsLoading"
    >
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </expendable-side-nav>
</template>

<script>
import TrackerLegend from '@/modules/satellite-tracking-module/live-tracking/components/TrackerLegend.vue'
import { cloneDeep } from 'lodash'
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import { createNamespacedHelpers } from 'vuex'
import ExpendableSideNav from '@/global/components/ExpendableSideNav'
const { mapGetters: mapGettersTracking, mapActions: mapActionsTracking } = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'TrackerDataOptions',

  components: {
    ExpendableSideNav,
    VehiclePicker,
    TrackerLegend
  },

  props: {
    openVehiclesModal: {
      type: Boolean,
      default: false
    },
    configSelectedVehicles: {
      type: Array,
      default: () => ([])
    },
    useConfigSelectedVehicles: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      preselectedVehicles: [],
      openVehiclesModalInternal: false,
      vehiclesInitialized: false,
      propLoaded: false,
      urlParams: null,
      dialogOpenStatus: false
    }
  },

  computed: {
    ...mapGettersTracking([
      'vehicleDetailsCheckboxes',
      'getSelectedVehicles',
      'liveRoutesLoading',
      'viewConfig',
      'controlsLoading'
    ]),

    keepExpanded () {
      return this.liveRoutesLoading || this.dialogOpenStatus
    },

    selectedVehicles: {
      get () {
        return this.getSelectedVehicles
      },
      set (value) {
        this.setSelectedVehicles(value)
      }
    }
  },

  watch: {
    selectedVehicles: {
      deep: true,
      immediate: true,
      handler (selectedVehicles, oldSelectedVehicles) {
        // Don't update remembered vehicles if there is param for single vehicle preview
        if (this.urlParams?.has('vehicle')) {
          return
        }
        if (selectedVehicles && oldSelectedVehicles && selectedVehicles.length && selectedVehicles.length !== oldSelectedVehicles.length) {
          this.updateVehicleDetails(false, 'live_route', 0)
        }
        this.$emit('selected-vehicles', { selectedVehicles, oldSelectedVehicles })
      }
    },

    configSelectedVehicles: {
      deep: true,
      immediate: true,
      handler (configSelectedVehicles) {
        // Do not show remembered vehicles if one is set from the URL hash
        if (this.preselectedVehicles.length === 0 && this.useConfigSelectedVehicles) {
          this.preselectedVehicles = configSelectedVehicles
          if (this.preselectedVehicles.length === 0) {
            this.openVehiclesModalInternal = true
          }
        }
        else if (
          !this.useConfigSelectedVehicles &&
          this.vehiclesInitialized &&
          this.preselectedVehicles.length === 0
        ) {
          this.openVehiclesModalInternal = true
        }

        this.vehiclesInitialized = true
      }
    }
  },

  created () {
    this.setSelectedVehicles([])
    // this part of code is used when user click on fleet index page "show on map" icon
    // and get redirected to live tracking
    this.urlParams = new URLSearchParams(this.$route.hash.substr(1))
    if (this.urlParams.has('vehicle')) {
      this.preselectedVehicles = [Number(this.urlParams.get('vehicle'))]
    }
    // this part is for keeping already selected vehicles when user jumps from page to page
    // and at some point get back on live tracking
    else if (this.getSelectedVehicles.length > 0) {
      this.preselectedVehicles = this.getSelectedVehicles.map(vehicle => (vehicle.id))
    }
  },

  methods: {
    ...mapActionsTracking([
      'updateVehicleDetailsCheckboxes',
      'setSelectedVehicles',
      'setClickedVehicleFromVehiclePickerList'
    ]),

    updateVehicleDetails (showInTooltip, valueIndex, propertyIndex) {
      const changedVehicleDetailsCheckboxes = cloneDeep(this.vehicleDetailsCheckboxes)
      changedVehicleDetailsCheckboxes[valueIndex][propertyIndex].showInTooltip = showInTooltip
      this.updateVehicleDetailsCheckboxes(changedVehicleDetailsCheckboxes)
    },

    setClickedVehicleToStore (vehicle) {
      this.setClickedVehicleFromVehiclePickerList(vehicle)
    }
  }
}
</script>

<style scoped lang="scss">
.cb-label-style{
 font-size: 0.82rem;
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  height: auto;
  min-width: 1.7rem;
  width: 1.7rem;
  padding: 0.7rem 0.2rem;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: normal;
  text-transform: none;
}

.v-tab--active {
  background: #E2E2E2;
}

::v-deep .v-tabs-slider-wrapper {
  transform: translateX(1.5rem);
  width: 3px !important;

  .v-tabs-slider {
    width: 3px !important;
    background-color: #6aac49;
  }
}

#controls-loader {
  position: absolute;
}
</style>
