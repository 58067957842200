var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"tracking-history-table fill-height overflow-y-auto",attrs:{"headers":_vm.tripsConfig,"items":_vm.trips,"item-key":"key","show-select":"","item-class":_vm.itemRowBackground},on:{"update:items-per-page":_vm.setItemsPerPage,"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-checkbox',{attrs:{"value":isSelected,"readonly":item.disabled,"disabled":item.disabled,"ripple":false},on:{"change":function (event) { return select(!!event); }}})]}},{key:"top",fn:function(){return [_c('v-container',{staticClass:"trip-table-heading pt-0",attrs:{"fluid":""}},[_vm._t("expand_button"),_c('v-row',{staticClass:"align-center flex-sm-nowrap pa-2",class:[_vm.tripsLoaded ? 'justify-space-between' : 'justify-end']},[(_vm.tripsLoaded)?_c('div',{staticClass:"text-h5 flex-grow-1 flex-shrink-1"},[_vm._v(" "+_vm._s(_vm.tableTitle)+" ")]):_vm._e(),_vm._t("picker_activator")],2)],2)]},proxy:true},{key:"item.person.name",fn:function(ref){
var item = ref.item;
return [(_vm.checkUnassigned(item))?_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 mr-2"},[_vm._v(" "+_vm._s(item.person.label)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-card-account-details ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.person.id_card_value))])])],1):_c('span',[_vm._v(_vm._s(_vm.getPersonName(item)))])]}},{key:"item.startAddress",fn:function(ref){
var item = ref.item;
return [_c('reverse-geocoding-field',{key:("reverse-geocoding-field-" + (item.key)),attrs:{"address":item.startAddress,"lat-lng":{ lat: item.startLat, lng: item.startLng },"vehicle-id":item.vehicle.id,"text-alignment":'center',"loading-indicator-alignment":'center'}})]}},{key:"item.endAddress",fn:function(ref){
var item = ref.item;
return [_c('reverse-geocoding-field',{key:("reverse-geocoding-field-" + (item.key)),attrs:{"address":item.endAddress,"lat-lng":{ lat: item.endLat, lng: item.endLng },"vehicle-id":item.vehicle.id,"text-alignment":'center',"loading-indicator-alignment":'center'}})]}}],null,true),model:{value:(_vm.selectedTrips),callback:function ($$v) {_vm.selectedTrips=$$v},expression:"selectedTrips"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }