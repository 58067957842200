<template>
  <v-card
    color="primary-darken-4"
    class="fill-height"
    flat
  >
    <v-card-text class="pa-0">
      <virtual-scroller-header
        :options="statuses"
        @filter-updated="filter = $event"
      />
      <v-virtual-scroll
        :items="filteredItems"
        :item-height="24"
        height="170px"
        class="virtual-scroll-style"
      >
        <template #default="{ item }">
          <v-row
            class="map-pin-data py-1"
            no-gutters
            @click="setGeolocationAndTemperatureData(item)"
          >
            <v-col class="py-0 text-center">
              <p
                class="font-weight-bold text--darken-1 mb-0 py-0"
                :class="getTextColorClassBasedOnTemperatureRange(item.temperature, sensor.sensorName)"
              >
                {{ item.date }}
              </p>
            </v-col>
            <v-col class="text-center py-0">
              <p
                class="font-weight-bold text--darken-1 mb-0 py-0"
                :class="getTextColorClassBasedOnTemperatureRange(item.temperature, sensor.sensorName)"
              >
                {{ item.time }}
              </p>
            </v-col>
            <v-col
              class="text-center font-weight-bold text--darken-1 py-0"
              :class="getTextColorClassBasedOnTemperatureRange(item.temperature, sensor.sensorName)"
            >
              <p class="mb-0 py-0">
                {{ item.temperature ? item.temperature + ' °C' : 'n/a' }}
              </p>
            </v-col>
            <v-col
              class="text-center font-weight-bold text--darken-1 py-0"
              :class="getTextColorClassBasedOnTemperatureRange(item.temperature, sensor.sensorName)"
            >
              <p
                class="mb-0 py-0"
                :class="{ 'red--text': item.door_opened === true && getShowReferenceValues }"
              >
                {{ getDoorStatus(item) }}
              </p>
            </v-col>
            <!-- Empty col to match header structure -->
            <v-col />
          </v-row>
        </template>
      </v-virtual-scroll>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { latLng } from 'leaflet'
import { api } from '@/global/services/api'
import VirtualScrollerHeader
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-details-section/sections/fromDateToDateComponents/VirtualScrollerHeader'

const {
  mapGetters: mapGettersTemperatureSensor,
  mapActions: mapActionsTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TemperatureSensorCard',

  components: {
    VirtualScrollerHeader
  },

  props: {
    sensor: {
      type: Object,
      default: () => ({})
    },
    statuses: {
      type: Object,
      default: () => ({})
    },
    temperatureRanges: {
      type: Object,
      default: () => ({})
    },
    vehicle: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      filter: 'all'
    }
  },

  computed: {
    ...mapGettersTemperatureSensor(['getShowReferenceValues']),

    filteredItems () {
      switch (this.filter) {
        case 'open':
          return this.sensor.sensorData.filter(item => item.door_opened === true)
        case 'closed':
          return this.sensor.sensorData.filter(item => item.door_opened === false)
        default:
          return this.sensor.sensorData
      }
    }
  },

  methods: {
    ...mapActionsTemperatureSensor([
      'setTemperaturesFromDateToDatePinData',
      'setTemperaturesChartPinData'
    ]),

    getDoorStatus (item) {
      if (item.door_opened === true) return this.$t('base.open')
      if (item.door_opened === false) return this.$t('base.closed')
      return '-'
    },

    getTextColorClassBasedOnTemperatureRange (temperature, sensorName) {
      // Slider values are stored as [xx, yy] where xx is left slider value (min in this case)
      // and yy is right side of picker, max slider value in this case
      switch (true) {
        case !this.getShowReferenceValues:
          return 'white--text'
        case temperature > this.temperatureRanges[sensorName][1]:
          return 'red--text'
        case temperature < this.temperatureRanges[sensorName][0]:
          return 'blue--text'
        default:
          return 'white--text'
      }
    },

    async setGeolocationAndTemperatureData (item) {
      const vehicleId = this.vehicle[0].id
      const dateTime = item.date + ' ' + item.time
      const result = await api()['satellite-tracking']
        .get('temperature-sensor/get-location-for-sensor-data', {
          vehicle_id: vehicleId,
          date_time: dateTime
        })
      const data = {
        geolocation: latLng(result.data.lat, result.data.lon),
        tooltipData: [
          {
            label: this.$t('satellite-tracking/temperature_sensor.date'),
            value: item.date
          },
          {
            label: this.$t('satellite-tracking/temperature_sensor.time'),
            value: item.time
          },
          {
            label: this.$t('satellite-tracking/temperature_sensor.temperature'),
            value: item.temperature + ' °C'
          }
        ]
      }
      this.setTemperaturesFromDateToDatePinData(data)
      // remove chart marker if it's clicked on temperatures from-to
      this.setTemperaturesChartPinData({})
    }
  }
}
</script>

<style scoped lang="scss">
.map-pin-data{
  cursor: pointer;

  &:hover {
     background-color:  var(--v-primary-darken-2-base);
   }
}

p {
  font-size: 12px;
}

.virtual-scroll-style{
  width: 100%; overflow-x: hidden;

  &::-webkit-scrollbar-track
  {
    border: 3px solid var(--v-primary-darken-4-base);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 4px;
    background-color: var(--v-primary-darken-1-base);
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    min-height: 40px;
    -webkit-box-shadow: inset 0 0 6px rgba(166, 124, 124, 0.3);
    background-color: #555;
  }
}
</style>
