<template>
  <fieldset class="fill-height">
    <legend>
      {{ $t('satellite-tracking/fuel_probe.current_fuel_tank_capacity') }}
      <v-btn
        v-if="getSelectedVehicle"
        class="my-n3"
        icon
        @click="fetchFuelTanksData()"
      >
        <v-icon class="green--text">
          mdi-cached
        </v-icon>
      </v-btn>
    </legend>
    <v-row class="flex-nowrap">
      <template v-if="fuelTanksData && fuelTanksData.length > 0">
        <v-col
          v-for="fuelTank in fuelTanksData"
          :key="fuelTank.number"
          class="col-auto mr-n4"
        >
          <fuel-gauge
            :tank-value="fuelTank.percent_value"
            :fuel-probe-name="getFuelProbeName(fuelTank)"
            :fuel-tank-current-value-in-percent="fuelTank.percent_value"
            :fuel-tank-current-value-in-litters="fuelTank.liters_value"
          />
        </v-col>
      </template>
      <template v-else-if="fuelProbesData && fuelProbesData.length > 0">
        <v-col
          v-for="(dataset, index) in fuelProbesData"
          :key="'dataset_' + index"
          class="col-auto mr-n4"
        >
          <fuel-gauge
            :tank-value="dataset.data.slice(-1)[0].value"
            :fuel-probe-name="getFuelProbeName(dataset)"
            :fuel-tank-current-value-in-percent="getFuelValueInPercent(dataset.data.slice(-1)[0].value, dataset.max_size)"
            :fuel-tank-current-value-in-litters="dataset.data.slice(-1)[0].value"
            value-from-chart-data
          />
        </v-col>
      </template>
    </v-row>
  </fieldset>
</template>

<script>
import FuelGauge from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-details-section/sections/FuelGauge'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersFuelProbe,
  mapActions: mapActionsFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

export default {
  name: 'CurrentFuelTankCapacity',

  components: { FuelGauge },

  computed: {
    ...mapGettersFuelProbe(['getSelectedVehicle', 'fuelTanksData', 'fuelProbesData'])
  },

  methods: {
    ...mapActionsFuelProbe(['fetchFuelTanksData']),

    getFuelValueInPercent (fuelInLiters, maxTankCapacity) {
      return (fuelInLiters / maxTankCapacity * 100).toFixed(1)
    },

    getFuelProbeName (dataObject) {
      if (!dataObject.number) return dataObject.label

      return `${dataObject.label} ${dataObject.number}`
    }
  }
}
</script>
