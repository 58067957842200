<template>
  <l-tooltip
    :options="tooltipOptions"
  >
    <custom-marker-tooltip-details :vehicle="vehicle"/>
  </l-tooltip>
</template>

<script>
import { LTooltip } from 'vue2-leaflet'
import CustomMarkerTooltipDetails
  from '@/modules/satellite-tracking-module/live-tracking/components/CustomMarkerTooltipDetails'
import { createNamespacedHelpers } from 'vuex'
const {
  mapGetters: mapGettersTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')
export default {
  name: 'CustomMarkerTooltip',

  components: {
    LTooltip,
    CustomMarkerTooltipDetails
  },

  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGettersTracking(['getSingleTooltipId']),

    tooltipOptions () {
      return {
        permanent: true,
        direction: 'right',
        className: this.vehicle.vehicleId === this.getSingleTooltipId ? 'custom-vue-leaflet-tooltip selected-tooltip-z-index' : 'custom-vue-leaflet-tooltip tooltip-z-index',
        sticky: true,
        offset: [13, 0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.custom-vue-leaflet-tooltip {
  border: none !important;
  margin-left: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
}
</style>
<style>
.selected-tooltip-z-index {
  z-index: 2000 !important;
}
.tooltip-z-index {
  z-index: 1000 !important;
}
</style>
