<template>
  <v-container
    :class="{
      'overflow-y-auto': $vuetify.breakpoint.mdAndDown,
      'fill-height fixed-height-container': $vuetify.breakpoint.lgAndUp
    }"
  >
    <v-row
      :class="{'fill-height': !$vuetify.breakpoint.md}"
    >
      <v-col
        id="temperatureSensorDetails"
        class="py-0 pr-1 col-12 col-md-8"
      >
        <temperature-sensor-details />
      </v-col>
      <v-col
        id="map"
        class="pl-0 py-0 col-12 col-md-4"
        :class="{ 'fixed-height-map pl-4': $vuetify.breakpoint.smAndDown}"
      >
        <temperature-sensor-map />
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </v-container>
</template>

<script>

import TemperatureSensorMap from '@/modules/satellite-tracking-module/temperature-sensor/components/TemperatureSensorMap'
import TemperatureSensorDetails from '@/modules/satellite-tracking-module/temperature-sensor/components/TemperatureSensorDetails'
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions: mapActionsTemperatureSensor,
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

const {
  mapGetters: mapGettersConfig,
  mapActions: mapActionsBase
} = createNamespacedHelpers('base/config')

export default {
  name: 'TemperatureSensorIndexVue',

  components: {
    TemperatureSensorMap,
    TemperatureSensorDetails
  },

  computed: {
    ...mapGettersTemperatureSensor(['loading']),
    ...mapGettersConfig(['isCompanyScopeUpdated'])
  },

  watch: {
    isCompanyScopeUpdated: async function () {
      this.resetState()
      await this.fetchConfig()
      this.unsetCompanyScopeUpdated()
    }
  },

  mounted () {
    this.fetchConfig()
  },

  beforeDestroy () {
    this.resetState()
  },

  methods: {
    ...mapActionsTemperatureSensor([
      'fetchConfig',
      'resetState'
    ]),

    ...mapActionsBase(['unsetCompanyScopeUpdated'])
  }
}
</script>

<style scoped lang="scss">
.container {
  .fixed-height-container {
    // --NAVBAR_HEIGHT is height of navbar
    max-height: calc(100vh - var(--NAVBAR_HEIGHT));
  }
  #temperatureSensorDetails {
    max-height: 100%;
  }
  .fixed-height-map {
    height: 300px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100%;
  }
}
</style>
