<template>
  <map-base
    ref="mapBase"
    :map-options="mapOptions"
    :style="this.$store.state.isMenuNavOpen ? { zIndex: 0 } : ''"
    :show-user-locations-value="showUserLocations"
    :show-partner-locations-value="showPartnerLocations"
    :update-bounds="boundsUpdate"
    @handleDragStartEvent="handleDragEvent('start')"
    @handleDragEndEvent="handleDragEvent('end')"
    @set-position-marker-radius="positionMarkerRadius => midMarkerRadius = positionMarkerRadius"
    @zoom-control-click="clickedZoomControl"
    @mouse-wheel="mouseWheelDetected"
  >
    <template #mapContent>
      <cluster-features
        ref="features"
        :cluster-popup-details="clusterPopupDetails"
      />
      <v-marker-cluster
        v-if="markers.length > 0"
        :key="markerClusterKey"
        :options="clusterOptions"
        @clusterclick="openClusterPopUp($event)"
      >
        <template
          v-for="(vehicle) in markers"
        >
          <custom-moving-marker
            v-if="shouldShowMarker(vehicle.status)"
            :key="'moving-' + vehicle.vehicleId + '-' + markersComponentKey"
            :lat-lng="getMarkerLatLng(vehicle)"
            :icon="getIconObject(vehicle)"
            :options="getMarkerAdditionalOptions(vehicle)"
            :duration="1500"
            :marker-vehicle-data="vehicle"
            @click="setSingleTooltipIdToStore(vehicle.vehicleId)"
          >
            <custom-marker-tooltip
              :key="'tooltip-' + vehicle.vehicleId + '-' + tooltipComponentKey"
              :vehicle="vehicle"
            />
          </custom-moving-marker>
        </template>
      </v-marker-cluster>
      <live-routes
        :active-vehicle-ids="filteredVehicleIds"
        :markers="markers"
        :midMarkerRadius="midMarkerRadius"
      />
      <v-overlay
        id="map-loader"
        :value="loading || liveRoutesLoading"
        z-index="400"
      >
        <v-progress-circular
          indeterminate
          size="60"
        />
      </v-overlay>
    </template>
    <template #controls>
      <slot name="controls" />
    </template>
  </map-base>
</template>
<script>
import { icon } from 'leaflet'
import { cloneDeep } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import CustomMovingMarker from '@/modules/satellite-tracking-module/live-tracking/components/CustomMovingMarker'
import CustomMarkerTooltip from '@/modules/satellite-tracking-module/live-tracking/components/CustomMarkerTooltip'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import ClusterFeatures from '@/modules/satellite-tracking-module/live-tracking/components/ClusterFeatures'
import LiveRoutes from '@/modules/satellite-tracking-module/live-tracking/components/LiveRoutes'
import MapBase from '@/global/components/map/MapBase.vue'
import { getPassedSecondsFromDate } from '@/global/services/helpers/timeConversion'

const { mapGetters: mapGettersConfig } = createNamespacedHelpers('base/config')
const { mapGetters: mapGettersTracking, mapActions: mapActionsTracking } = createNamespacedHelpers('satellite-tracking/live-tracking')
const { mapGetters: mapGettersTrackingHistory } = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  name: 'LiveTrackingMap',
  components: {
    LiveRoutes,
    ClusterFeatures,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    CustomMovingMarker,
    CustomMarkerTooltip,
    MapBase
  },

  props: {
    routes: {
      type: Array,
      default: () => ([])
    },
    incomingLocations: {
      type: Array,
      default: () => ([])
    },
    showMap: {
      type: Boolean,
      default: true
    },
    // needed for legend filters and showing live routes.
    // TODO try to move it to vuex store
    incomingMarkers: {
      type: Array,
      default: () => ([])
    },
    markersCount: {
      type: Number,
      default: 0
    },
    refreshMarkerTooltip: {
      type: Function
    }
  },

  data () {
    return {
      mapOptions: {
        preferCanvas: true,
        doubleClickZoom: false
      },
      currentBounds: null,
      dragging: false,
      markers: [],
      locations: [],
      liveRoutes: {},
      clusterOptions: {
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
        disableClusteringAtZoom: 20,
        spiderfyOnMaxZoom: false
      },
      clusterPopupDetails: {
        vehiclesNumber: 0,
        title: '',
        vehicleList: []
      },
      markerClusterKey: 'cluster-key',
      markersComponentKey: 0,
      onDataChangeCache: {
        registeredTimeout: null,
        locations: [],
        markers: []
      },
      onDataChange: function (type, data) {
        if (this.onDataChangeCache.registeredTimeout) {
          clearTimeout(this.onDataChangeCache.registeredTimeout)
        }

        this.onDataChangeCache[type] = data

        if (this.dragging) {
          // user is dragging the map
          return
        }

        var that = this
        this.onDataChangeCache.registeredTimeout = setTimeout(function () {
          that.locations = that.onDataChangeCache.locations
          that.markers = that.onDataChangeCache.markers
        }, 100)
      },
      midMarkerRadius: null
    }
  },

  computed: {
    ...mapGettersConfig(['language']),
    ...mapGettersTracking([
      'vehicleDetailsCheckboxes',
      'getClickedVehicleFromVehiclePickerList',
      'getSingleTooltipId',
      'markerIcons',
      'tooltipComponentKey',
      'loading'
    ]),
    ...mapGettersTrackingHistory({
      liveRoutesLoading: 'loading'
    }),

    activeStatusFilters () {
      const activeLegendFilters = this.vehicleDetailsCheckboxes.legend[0].items?.filter(item => item.filterStatus)
      const mappedFilters = []
      activeLegendFilters.forEach(filter => {
        switch (filter.filterName) {
          case 'active':
            return mappedFilters.push('CONTACT', 'DRIVING', 'MOVING')
          case 'inactive_engine_on':
            return mappedFilters.push('IDLING')
          case 'inactive_engine_off':
            return mappedFilters.push('PARKING')
          case 'no_gps_signal':
            return mappedFilters.push('NO_GPS')
          case 'connection_lost':
            return mappedFilters.push('OFFLINE')
        }
      })
      return mappedFilters
    },

    filteredVehicleIds () {
      return this.markers.map(marker => marker.vehicleId)
    },

    centering () {
      return this.vehicleDetailsCheckboxes.centering ? this.vehicleDetailsCheckboxes.centering[0] : null
    },

    showUserLocations () {
      return this.vehicleDetailsCheckboxes.user_locations
        ? this.vehicleDetailsCheckboxes.user_locations[0].showInTooltip
        : false
    },

    showPartnerLocations () {
      return this.vehicleDetailsCheckboxes.partner_locations
        ? this.vehicleDetailsCheckboxes.partner_locations[0].showInTooltip
        : false
    }
  },

  watch: {
    incomingLocations (locations) {
      this.onDataChange('locations', locations)
    },

    incomingMarkers (markers) {
      this.onDataChange('markers', markers)
    },

    locations (locations) {
      this.centerMap(locations)
    },

    filteredVehicleIds: {
      // Center the map when vehicle selection is changed
      handler: function (val, oldVal) {
        this.$nextTick(() => {
          // On each checkbox change (not just legend filters) this watcher is triggered, so we have
          // to compare if vehicleIds are changed. If the new and old values are same it means that user has
          // clicked on some other checkbox than filter, and we should not update map bounds
          if (this.locations.length > 0 &&
            val.length !== oldVal.length &&
            oldVal.toString() !== val.toString()
          ) {
            this.$refs?.mapBase?.$refs?.map?.fitBounds(this.locations, {
              duration: 1,
              padding: [10, 10]
            })
          }
        })
      }
    },

    vehicleDetailsCheckboxes (checkboxes) {
      if (checkboxes.centering && checkboxes.centering[0]?.showInTooltip && this.locations.length) {
        this.$refs?.mapBase?.$refs?.map?.fitBounds(this.locations, {
          duration: 1,
          padding: [10, 10]
        })
      }

      if (this.vehicleDetailsCheckboxes === checkboxes) {
        this.refreshMarkerTooltip()
      }
    },

    'vehicleDetailsCheckboxes.disable_clustering' (disableClustering) {
      // Changing key as it needed for component force re-render and options to take effect
      if (disableClustering?.[0]?.showInTooltip) {
        this.clusterOptions.disableClusteringAtZoom = 8
        this.markerClusterKey = 'non-clustered'
      }
      else {
        this.clusterOptions.disableClusteringAtZoom = 20
        this.markerClusterKey = 'clustered'
      }
    },

    getClickedVehicleFromVehiclePickerList (vehicle) {
      const clickedVehicleId = vehicle.id
      const vehicleData = this.markers.find(vehicle => vehicle.vehicleId === clickedVehicleId)
      if (vehicleData) {
        this.$refs?.mapBase?.$refs?.map?.mapObject?.flyTo(this.getMarkerLatLng(vehicleData), 18)
      }
    }
  },

  async created () {
    await this.fetch()
  },

  mounted () {
    dayjs.extend(relativeTime)
    dayjs.locale(this.language)
  },

  methods: {
    ...mapActionsTracking([
      'fetch',
      'setMapType',
      'setLiveRoutesLoading',
      'setSingleTooltipId',
      'updateVehicleDetailsCheckboxes'
    ]),

    shouldShowMarker (vehicleStatus) {
      return this.activeStatusFilters.length === 0 || this.activeStatusFilters.includes(vehicleStatus)
    },

    setSingleTooltipIdToStore (vehicleId) {
      this.getSingleTooltipId === vehicleId
        ? this.setSingleTooltipId(null)
        : this.setSingleTooltipId(vehicleId)
      this.refreshMarkerTooltip()
    },

    openClusterPopUp (cluster) {
      this.clusterPopupDetails.vehiclesNumber = cluster.layer.getChildCount()
      this.clusterPopupDetails.vehicleList = cluster.layer.getAllChildMarkers()
        .map(marker => marker.options.vehicleData)

      this.$nextTick(() => {
        this.$refs?.features?.$refs?.customPopup?.mapObject?.openPopup(cluster.layer.getLatLng())
      })
    },

    delay (milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    },

    refreshMarkers () {
      // re-render markers
      this.markersComponentKey += 1
    },

    async handleDragEvent (type) {
      this.disableAutoCenter()

      if (type === 'start') {
        this.dragging = true
        // call to delete scheduled rendering
        this.onDataChange('dragging', [])
      }
      if (type === 'end') {
        this.dragging = false
        // call to force rendering
        this.onDataChange('dragging', [])
      }
    },

    centerMap (locations) {
      if (this.vehicleDetailsCheckboxes && this.vehicleDetailsCheckboxes.centering && this.vehicleDetailsCheckboxes.centering[0] && this.vehicleDetailsCheckboxes.centering[0].showInTooltip) {
        if (locations && locations.length > 1) {
          this.$refs?.mapBase?.$refs?.map?.fitBounds(locations, {
            duration: 1,
            padding: [10, 10]
          })
        }
        else if (locations && locations.length === 1) {
          this.$refs?.mapBase?.$refs?.map?.setCenter(locations[0])
          if (this.currentBounds && !this.currentBounds.contains(locations[0])) {
            this.refreshMarkers()
          }
        }
      }
    },

    getMarkerLatLng (vehicle) {
      // cant use latLng(marker.latitude, marker.longitude), clustering doesn't work properly
      // see https://github.com/jperelli/vue2-leaflet-markercluster/issues/17 for details if needed
      return { lat: vehicle.latitude, lng: vehicle.longitude }
    },

    getMarkerAdditionalOptions (marker) {
      return {
        rotationAngle: marker.course,
        rotationOrigin: 'center center'
      }
    },

    getIconObject (vehicle) {
      let markerIcon

      switch (vehicle.status) {
        case 'DRIVING':
        case 'MOVING':
          markerIcon = this.markerIcons.ACTIVE
          break
        case 'CONTACT':
        case 'IDLING':
          markerIcon = this.markerIcons.INACTIVE_ENGINE_ON
          break
        case 'PARKING':
          markerIcon = this.markerIcons.INACTIVE_ENGINE_OFF
          break
        case 'NO_GPS':
          markerIcon = this.markerIcons.NO_GPS_SIGNAL
          break
        case 'OFFLINE':
          markerIcon = this.markerIcons.CONNECTION_LOST
          break
        default:
          markerIcon = this.markerIcons.CONNECTION_LOST
          break
      }

      // check if checkbox for show stop time is active
      var checkbox = this.vehicleDetailsCheckboxes.marker.filter(function (item) {
        return item.key === 'lastPositionChangeDateTime'
      })
      // check if checkbox is displayed
      if (checkbox.length) {
        const stopCheckboxActive = checkbox[0].showInTooltip

        // check if checkox is selected and vehicle threshold is set
        if (stopCheckboxActive && vehicle.positionChangeThreshold != null) {
          if ((getPassedSecondsFromDate(vehicle.lastPositionChangeDateTime) > vehicle.positionChangeThreshold * 60) && vehicle.speed === 0) {
            markerIcon = this.markerIcons.LONG_STOP
          }
        }
      }
      return icon({
        iconUrl: markerIcon,
        iconSize: [13, 13],
        iconAnchor: [6.5, 6.5]
      })
    },

    boundsUpdate (bounds) {
      this.currentBounds = bounds
    },

    clickedZoomControl () {
      this.disableAutoCenter()
    },

    mouseWheelDetected () {
      this.disableAutoCenter()
    },

    disableAutoCenter () {
      if (this.vehicleDetailsCheckboxes.centering && this.vehicleDetailsCheckboxes.centering[0].showInTooltip) {
        const changedVehicleDetailsCheckboxes = cloneDeep(this.vehicleDetailsCheckboxes)
        changedVehicleDetailsCheckboxes.centering[0].showInTooltip = false
        this.updateVehicleDetailsCheckboxes(changedVehicleDetailsCheckboxes)
      }
    }
  }
}
</script>

<style lang="scss">
// not necessary but desirable
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

strong {
  font-size: 0.7rem;
  line-height: 1.2;
}

.custom-vehicle-details {
  p {
    line-height: 0;
  }
}

#map-loader {
  position: absolute;
}

.leaflet-control-container {
  .leaflet-top.leaflet-left,
  .leaflet-top.leaflet-right,
  .leaflet-bottom.leaflet-left,
  .leaflet-bottom.leaflet-right {
    z-index: 500;
  }
}
.custom-vue-leaflet-tooltip {
  border: none !important;
  margin-left: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
}
</style>
