<template>
  <fieldset class="fill-height py-2">
    <legend>
      {{
        $t('satellite-tracking/temperature_sensor.temperatures_from_date_to_date')
          .replace(':from_date', getSelectedDateFrom)
          .replace(':to_date', getSelectedDateTo)
      }}
    </legend>
    <template v-if="getTemperaturesChartData && getTemperaturesChartData.length > 0">
      <v-card
        color="primary-darken-4"
        class="fill-height"
        flat
      >
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(sensor, index) in getTemperaturesChartData"
            :key="'tab_item_' + sensor.sensorName + '_' + index"
          >
            <temperature-sensor-card
              :sensor="sensor"
              :statuses="viewConfig.doorStatuses"
              :temperature-ranges="getTemperatureRanges"
              :vehicle="getSelectedVehicle"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-tabs
          v-model="tab"
          fixed-tabs
          background-color="primary-darken-4"
          active-class="primary-darken-3"
          height="25"
          dark
        >
          <v-tab
            v-for="item in getTemperaturesChartData"
            :key="'tab_' + item.sensorName"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{
                  item.sensorName.length < 10
                    ? item.sensorName
                    : item.sensorName.substr(0, 7) + '...'
                }}
                </span>
              </template>
              <span>{{ item.sensorName }}</span>
            </v-tooltip>
          </v-tab>
        </v-tabs>
      </v-card>
    </template>
  </fieldset>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TemperatureSensorCard
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-details-section/sections/fromDateToDateComponents/TemperatureSensorCard'

const {
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TemperaturesFromDateToDate',

  components: {
    TemperatureSensorCard
  },

  data () {
    return {
      tab: null
    }
  },

  computed: {
    ...mapGettersTemperatureSensor([
      'viewConfig',
      'getSelectedVehicle',
      'getSelectedDateFrom',
      'getSelectedDateTo',
      'getTemperatureRanges',
      'getTemperaturesChartData'
    ])
  }
}
</script>

<style scoped lang="scss">
.v-tabs-items {
  height: 85%;
  overflow: auto;
  background-color: var(--v-primary-darken-4-base);
  overflow-y: hidden;

  .v-window__container {
    height: 100%;
    .v-window-item, .v-window-item--active {
      height: 100%;
    }
  }
}

.v-tab {
  font-size: 12px;
}

</style>
