<template>
  <v-container fluid>
    <v-card @keydown.enter.prevent="closeDatetimePicker">
      <v-card-text>
      <v-row>
        <v-col cols="3">
          <vehicle-picker
            v-model="vehicles"
            :view-config="viewConfig"
            :max-select="1"
            :show-vehicles-with-assignments="true"
            @updated-scope="companyScopeId = $event"
            @has-selected-vehicles="vehiclesSelectedStatus = $event"
          />
        </v-col>
        <v-col cols="2">
          <v-menu
            v-model="fromMenu"
            transition="slide-y-transition"
            offset-y
            nudge-top="450"
            min-width="auto"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromFormatted"
                :label="$t('base.from')"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="parseDmyDate(fromFormatted)"
              />
            </template>
            <datetime-picker
              v-model="from"
              :emit-default-time="true"
              :default-time="'00:00:00'"
              :max-date="today"
              :reset-tabs="resetFromTabs"
              @close-picker="fromMenu = false"
              :on-double-click="closeDatetimePicker"
            >
              <v-col class="white text-right pb-2 px-2">
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="fromMenu = false"
                >
                  {{ $t('base.ok') }}
                </v-btn>
              </v-col>
            </datetime-picker>
          </v-menu>
        </v-col>

        <v-col cols="2">
          <v-menu
            v-model="toMenu"
            transition="slide-y-transition"
            nudge-top="450"
            offset-y
            min-width="auto"
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="toFormatted"
                :label="$t('base.to')"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="parseDmyDate(toFormatted)"
              />
            </template>
            <datetime-picker
              v-model="to"
              :emit-default-time="true"
              :default-time="defaultToTime"
              :min-date="minDate"
              :reset-tabs="resetToTabs"
              @close-picker="toMenu = false"
              :on-double-click="closeDatetimePicker"
            >
              <v-col class="white text-right pb-2 px-2">
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="toMenu = false"
                >
                  {{ $t('base.ok') }}
                </v-btn>
              </v-col>
            </datetime-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="task"
            underlined
            densitiy="comfortable"
            hide-details
            :items="tasks"
            :label="$t('road-maintenance/report.select_task')"
            item-value="id"
            item-text="name"
            class="pr-2"
            @change="setTask($event)"
          />
        </v-col>
        <v-col cols="3" align="right">
          <v-btn-toggle
            v-model="routesButton"
            :title="$t('road-maintenance/report.vehicle_button')"
            tile
            group
            color="black"
          >
            <v-btn
              plain
              @click.native="checkDataForRoutes"
            >
              <v-icon
                large
              >
                mdi-car-connected
              </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-model="tasksButton"
            tile
            group
            :title="$t('road-maintenance/report.task_button')"
            color="black"
          >
            <v-btn
              plain
              @click.native="checkDataForTask"
            >
              <v-icon
                large
              >
                mdi-file-table
              </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-model="overlapButton"
            tile
            :title="$t('road-maintenance/report.overlap_button')"
            group
            color="black"
          >
            <v-btn
              plain
              @click.native="checkDataForOverlapping"
            >
              <v-icon
                large
              >
                mdi-map-marker-path
              </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle
            v-model="exportButton"
            tile
            group
            :title="$t('road-maintenance/report.report_button')"
            color="black"
          >
            <v-btn
              plain
              @click.native="preventToggle"
            >
              <v-icon
                large
              >
                mdi-database-export
              </v-icon>
            </v-btn>
          </v-btn-toggle>

        </v-col>
      </v-row>
    </v-card-text>
    </v-card>
    <v-dialog
      v-model="showDialog"
      content-class="elevation-0"
      :width="defaultDialogSize[$vuetify.breakpoint.name]"
      @keydown.enter.prevent="reportConfirmed"
      @keydown.esc.prevent="closeDialog"
    >
      <v-card>
        <v-row class="flex justify-space-between ma-0">
          <v-card-title>{{ $t('road-maintenance/report.modal_title') }}</v-card-title>

          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="saltAmount"
                :label="$t('road-maintenance/report.salt_amount')"
                type="number"
                min="0"
                ref="saltAmount"
                :rules="rulesInteger"
                >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="grey darken-1"
            text
            @click="closeDialog"
          >
            {{ $t('base.cancel') }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="reportConfirmed"
          >
            {{ $t('road-maintenance/report.show_report') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'

import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
const { mapGetters } = createNamespacedHelpers('road-maintenance/tasks-report')

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'ReportControls',

  components: {
    DatetimePicker,
    VehiclePicker
  },

  props: {
    activator: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      today: new Date().toISOString().slice(0, 10),
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null,
      vehicles: [],
      shaking: false,
      companyScopeId: null,
      fromMenu: false,
      toMenu: false,
      resetFromTabs: false,
      resetToTabs: false,
      openVehiclesModal: false,
      vehiclesSelectedStatus: null,
      task: null,
      tasksButton: null,
      routesButton: null,
      overlapButton: null,
      exportButton: 0,
      tasks: [],
      showDialog: false,
      defaultDialogSize: { xl: '55%', lg: '55%', md: '50%', sm: '66.66%', xs: '100%' },
      saltAmount: 0
    }
  },

  computed: {
    ...mapGetters(['viewConfig']),

    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    },

    maxToDate () {
      if (this.from) {
        const maxDate = dayjs(this.from).add(31, 'day')
        return maxDate.isAfter(this.today) ? this.today : maxDate.toISOString()
      }

      return this.today
    },

    maxToTime () {
      if (dayjs(this.to).isToday()) return dayjs().format('HH:mm')

      return this.defaultToTime
    },
    rules () {
      return [value => !!value || this.$t('validation/no_attributes.required')]
    },

    rulesInteger () {
      return [value => value >= 0 || this.$t('validation/no_attributes.required')]
    }
  },

  watch: {
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },

    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    },

    vehiclesSelectedStatus (value) {
      this.$emit('vehicle-selected-status', value)
    }
  },

  created () {
    this.from = dayjs().startOf('day').toISOString()
    this.to = dayjs().toISOString()
    if (!this.selectedVehiclesCount) {
      this.openVehiclesModal = true
    }
    this.fetchTasks()
  },

  methods: {
    checkDataForRoutes (e) {
      // Emit selection when button is clicked and data is ok
      if (this.vehicles.length && this.from && this.to && this.task && this.routesButton !== undefined) {
        this.$emit('input', {
          vehicles: this.vehicles,
          from: this.from,
          to: this.to,
          company_scope_id: this.companyScopeId,
          task: this.task
        })
        this.routesButton = 0
        this.overlapButton = false
      }
      // Otherwise, report that the selection is invalid
      else {
        this.routesButton = false
        this.$emit('input', {
          invalid: true
        })
      }
    },
    checkDataForTask (e) {
      // Emit selection when button is clicked and data is ok
      e.preventDefault()
      if (this.task && this.tasksButton !== undefined) {
        this.tasksButton = 0
        this.overlapButton = false
        this.$emit('input-tasks', {
          company_scope_id: this.companyScopeId,
          task: this.task
        })
      }
      // Otherwise, report that the selection is invalid
      else {
        this.tasksButton = false
        this.$emit('input-tasks', {
          invalid: true
        })
      }
    },
    checkDataForOverlapping (e) {
      e.preventDefault()
      if (this.task && this.vehicles.length && this.to && this.from && this.overlapButton !== undefined) {
        this.tasksButton = false
        this.routesButton = false
        this.overlapButton = 0
        this.$emit('input-overlap', {
          vehicles: this.vehicles,
          from: this.from,
          to: this.to,
          company_scope_id: this.companyScopeId,
          task: this.task
        })
      }
      else {
        this.overlapButton = false
        this.$emit('input-overlap', {
          invalid: true
        })
      }
    },

    closeDatetimePicker () {
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },
    setTask (event) {
      this.task = event
    },
    deleteItem (item) {
    },
    preventToggle (event) {
      event.preventDefault()
      this.exportButton = 0
      if (this.task && this.vehicles.length && this.to && this.from) {
        this.showDialog = true
      }
    },
    async fetchTasks () {
      const response = await api('road-maintenance').get('/tasks/picker-options')
      this.tasks = response.data.tasks
      this.tasks.unshift({
        id: 'INTERVENTION',
        name: 'Intervencija'
      })
    },
    closeDialog () {
      this.showDialog = false
    },
    reportConfirmed () {
      if (this.saltAmount >= 0) {
        this.showDialog = false
        this.$emit('report', {
          saltAmount: this.saltAmount,
          task: this.task,
          vehicles: this.vehicles,
          from: this.from,
          to: this.to
        })
      }
      else {
        this.$refs.saltAmount.validate(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.picker-submit-btn-wrapper {
  height: 50px;

  .picker-submit-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-25%, -50%);

    .shaking {
      animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
  }
}

</style>
