<template>
  <fieldset class="fill-height overflow-y-auto">
    <legend>{{ $t('satellite-tracking/fuel_probe.refueling_dispensing') }}</legend>
    <template v-if="refuelsAndLeaksData && refuelsAndLeaksData.length > 0">
      <v-row
        v-for="(item, index) in refuelsAndLeaksData"
        :key="item.label + '_' + item.time + '_' + item.date + '_' + index"
        class="map-pin-data"
        @click="setFuelLevelReadoutDetails(item)"
      >
        <v-col class="py-1">
          <p
            class="font-weight-bold text--darken-1 mb-1"
            :class="item.is_leak ? 'red--text' : 'white--text'"
          >
            {{ item.label }} :
          </p>
        </v-col>
        <v-col class="py-1 col-auto">
          <p
            class="font-weight-bold text--darken-1 mb-1"
            :class="item.is_leak ? 'red--text' : 'white--text'"
          >
            {{ item.time }}
          </p>
        </v-col>
        <v-col class="py-1">
          <p
            class="font-weight-bold text--darken-1 mb-1"
            :class="item.is_leak ? 'red--text' : 'white--text'"
          >
            {{ item.date }}
          </p>
        </v-col>
        <v-col
          class="text-right py-1 col-auto"
        >
          <p
            class="font-weight-bold text--darken-1 mb-1"
            :class="item.is_leak ? 'red--text' : 'white--text'"
          >
            {{ item.value ? item.value + ' L' : 'n/a' }}
          </p>
        </v-col>
      </v-row>
    </template>
  </fieldset>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { convertDateTimeToTimestamp } from '@/global/services/helpers/timeConversion'

const {
  mapGetters: mapGettersFuelProbe,
  mapActions: mapActionsFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

export default {
  name: 'FuelDispensing',

  computed: {
    ...mapGettersFuelProbe([
      'refuelingDispensingGeolocation',
      'refuelsAndLeaksData'
    ])
  },
  methods: {
    ...mapActionsFuelProbe([
      'setRefuelingDispensingGeolocation',
      'fetchFuelLevelReadoutDetails'
    ]),

    setFuelLevelReadoutDetails (item) {
      const clickedItemTimestamp = convertDateTimeToTimestamp(item.date, item.time)

      this.fetchFuelLevelReadoutDetails(clickedItemTimestamp).then(readoutData => {
        this.setRefuelingDispensingGeolocation(readoutData)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.test-wrapper {
  max-height: 100%;
  overflow-y: auto;
}

.map-pin-data{
  cursor: pointer;
  &:hover {
    background-color:  var(--v-primary-darken-2-base);
  }
}

p{
  font-size: 12px;
}
</style>
