<template>
  <v-container
    class="fill-height primary-darken-4"
  >
    <v-row
      id="temperatureSensorInfo"
      :class="{ 'fixed-height-info' : $vuetify.breakpoint.mdAndUp }"
    >
      <v-col class="col-max-height">
        <sensor-details-sections />
      </v-col>
    </v-row>
    <fieldset
      id="temperatureSensorGraph"
      class="row no-gutters overflow-y-auto"
      :class="{
        'fixed-height-graph mt-0' : $vuetify.breakpoint.lgAndUp,
        'mt-8' : $vuetify.breakpoint.mdAndDown
      }"
    >
      <legend>{{ $t('satellite-tracking/temperature_sensor.graphical_representation') }}</legend>
      <v-col class="pt-0">
        <temp-sensor-chart />
      </v-col>
    </fieldset>
  </v-container>
</template>

<script>
import SensorDetailsSections
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-details-section/SensorDetailsSections'
import TempSensorChart
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/TempSensorChart'

export default {
  name: 'TemperatureSensorDetails',

  components: { SensorDetailsSections, TempSensorChart }
}
</script>

<style scoped lang="scss">

#temperatureSensorInfo {
  &.fixed-height-info {
    height: 17rem;
  }
}

#temperatureSensorGraph {
  &.fixed-height-graph {
    height: calc(100% - 17rem);
  }
  &::-webkit-scrollbar-track
  {
    border: 3px solid var(--v-primary-darken-4-base);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 4px;
    background-color: var(--v-primary-darken-1-base);
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    min-height: 40px;
    -webkit-box-shadow: inset 0 0 6px rgba(166, 124, 124, 0.3);
    background-color: #555;
  }
}

</style>

<style>
.col-max-height {
  max-height: 100%;
  padding-bottom: 0;
}
fieldset {
  /*if needed change to auto and hide scroller*/
  overflow: hidden;
}
</style>
