<template>
  <line-chart
    v-if="chartData"
    ref="chartRef"
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import parse from 'date-fns/parse'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LineChart } from 'vue-chart-3'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'
import { ref, computed, watch, defineComponent } from '@vue/composition-api'
import { formatSqlDate, formatSqlTime } from '@/global/services/helpers/dates'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { api } from '@/global/services/api'

const {
  useGetters,
  useActions
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

Chart.register(...registerables, zoomPlugin, annotationPlugin)

export default defineComponent({
  name: 'TempSensorChartBuilder',

  components: { LineChart },

  setup (props, { root }) {
    const chartRef = ref()

    // Translate function from the root instance
    const $t = root.$t

    // Map vuex store getters
    const {
      getColors,
      getBackgroundColors,
      getLabelTextColors,
      getTemperaturesChartData,
      getChartLineToggleStatuses,
      getTemperaturesFromDateToDatePinData,
      getSelectedDateFrom,
      getSelectedDateTo,
      getTemperatureRanges,
      getShowReferenceValues,
      getSelectedVehicle
    } = useGetters([
      'getColors',
      'getBackgroundColors',
      'getLabelTextColors',
      'getTemperaturesChartData',
      'getChartLineToggleStatuses',
      'getTemperaturesFromDateToDatePinData',
      'getSelectedDateFrom',
      'getSelectedDateTo',
      'getTemperatureRanges',
      'getShowReferenceValues',
      'getSelectedVehicle'
    ])

    // Map vuex store actions
    const {
      setTemperaturesChartPinData,
      setTemperaturesFromDateToDatePinData
    } = useActions([
      'setTemperaturesChartPinData',
      'setTemperaturesFromDateToDatePinData'
    ])

    watch(
      getChartLineToggleStatuses,
      (newStatuses) => {
        // This code is needed because of this bug:
        // https://github.com/victorgarciaesgi/vue-chart-3/issues/33
        Object.values(newStatuses).forEach((status, index) => {
          if (chartRef.value.chartInstance.data.datasets[index]) {
            chartRef.value.chartInstance.data.datasets[index].hidden = !status
          }
        })

        chartRef.value.chartInstance.update()
      },
      { deep: true }
    )

    // Computed properties for max size of the Y chart axis
    const maxTemperature = computed(() => {
      return Math.max(...getTemperaturesChartData.value.map(item => item.max_temperature))
    })

    // Computed properties for min size of the Y chart axis
    const minTemperature = computed(() => {
      return Math.min(...getTemperaturesChartData.value.map(item => item.min_temperature))
    })

    const rangesMin = computed(() => {
      return Math.min(...Object.values(getTemperatureRanges.value).map(range => range[0]))
    })

    const rangesMax = computed(() => {
      return Math.max(...Object.values(getTemperatureRanges.value).map(range => range[1]))
    })

    const yAxisMin = computed(() => {
      return rangesMin.value < minTemperature.value ? rangesMin.value : minTemperature.value
    })

    const yAxisMax = computed(() => {
      return rangesMax.value > maxTemperature.value ? rangesMax.value : maxTemperature.value
    })

    const setChartPointsGeolocationData = (data) => {
      setTemperaturesChartPinData(data)
      // remove temperature from-to marker if it's clicked on chart
      if (Object.keys(getTemperaturesFromDateToDatePinData.value).length !== 0) {
        setTemperaturesFromDateToDatePinData({})
      }
    }

    // Computed properties for min and max range dates, used to limit panning on the chart
    const minRangeDate = computed(() => {
      return parse(getSelectedDateFrom.value, 'dd.MM.yyyy', new Date())
        .setHours(0, 0, 0, 0)
        .valueOf()
    })
    const maxRangeDate = computed(() => {
      return parse(getSelectedDateTo.value, 'dd.MM.yyyy', new Date())
        .setHours(23, 59, 59, 999)
        .valueOf()
    })

    let dynamicPointHitRadius = 1

    // Computed property for chart dataset
    const getChartDataSet = computed(() => {
      const datasetCommonOptions = {
        label: '',
        pointRadius: 0.1,
        pointHitRadius: dynamicPointHitRadius,
        fill: true,
        borderWidth: 1,
        tension: 0.1,
        hidden: false
      }

      const statuses = Object.values(getChartLineToggleStatuses.value)

      const fallbackColors = ['red', 'blue', 'green', 'yellow']

      return getTemperaturesChartData.value.map((data, index) => {
        return {
          ...datasetCommonOptions,
          hidden: !statuses[index],
          borderColor: getColors.value ? getColors.value[index] : fallbackColors[index],
          backgroundColor: getBackgroundColors.value ? getBackgroundColors.value[index] : fallbackColors[index],
          pointBackgroundColor: getColors.value ? getColors.value[index] : fallbackColors[index],
          data: data.sensorData.map(dataItem => {
            return {
              x: dataItem.dateTime,
              y: dataItem.temperature,
              vehicleSpeed: dataItem.vehicleSpeed,
              lat: dataItem.lat,
              lng: dataItem.lng
            }
          })
        }
      })
    })

    const chartData = computed(() => ({
      datasets: getChartDataSet.value
    }))

    const limitLines = computed(() => {
      const commonOptions = {
        type: 'line',
        borderDash: [7],
        borderWidth: 1
      }
      const commonLabelOptions = {
        enabled: true,
        backgroundColor: 'transparent',
        position: 'start',
        yAdjust: -12
      }

      const lines = {}

      // for each slider crate min and max line, and add it to 'lines' object
      // Slider values are stored as [xx, yy] where xx is left slider value (min in this case)
      // and yy is right side of picker, max slider value in this case, below [0] and [1]
      Object.keys(getTemperatureRanges.value).forEach((key, index) => {
        // create min limit line
        lines['line' + key.replace(/\s/g, '') + 'min'] = {
          ...commonOptions,
          // in getChartLineToggleStatuses keys are sensor1 to sensor4
          display: getChartLineToggleStatuses.value['sensor' + (index + 1)],
          borderColor: 'rgba(139,169,246,0.5)',
          yMin: getTemperatureRanges.value[key][0],
          yMax: getTemperatureRanges.value[key][0],
          label: {
            ...commonLabelOptions,
            color: 'rgb(139,169,246)',
            content: key
          }
        }
        // create max limit line for same slider
        lines['line' + key.replace(/\s/g, '') + 'max'] = {
          ...commonOptions,
          display: getChartLineToggleStatuses.value['sensor' + (index + 1)],
          yMin: getTemperatureRanges.value[key][1],
          yMax: getTemperatureRanges.value[key][1],
          borderColor: 'rgba(255, 99, 132, 0.5)',
          label: {
            ...commonLabelOptions,
            color: 'rgb(255, 99, 132)',
            content: key
          }
        }
      })

      return lines
    })
    const chartOptions = computed(() => ({
      maintainAspectRatio: false,
      animation: false,
      parsing: false,
      hover: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      scales: {
        y: {
          stacked: false,
          // +- 3 just to add gap on graph
          min: yAxisMin.value - 3,
          max: yAxisMax.value + 3,
          grid: {
            display: false,
            color: 'rgba(232,210,215,0.2)'
          },
          ticks: {
            color: 'white',
            stepSize: maxTemperature.value / 2
          }
        },
        x: {
          type: 'time',
          min: minRangeDate.value,
          max: maxRangeDate.value,
          time: {
            displayFormats: {
              day: 'dd.MM.',
              hour: 'dd.MM. HH:mm',
              minute: 'dd.MM. HH:mm',
              second: 'HH:mm:ss'
            }
          },
          grid: {
            display: true
          },
          ticks: {
            color: 'white'
          }
        }
      },
      onClick: async (evt, activeElements) => {
        const dataset = getChartDataSet.value
        const clickedElements = []
        const filteredElements = activeElements.filter((el, index, activeElements) =>
          el.datasetIndex !== activeElements[index - 1]?.datasetIndex
        )
        // On zoomed out graph, on mouse click event picks a lot of data for x-axis
        // and displays them all on the map tooltip.
        // This filter returns only first item from each dataSet from click picked items,
        // so it can be correctly displayed in map tooltip for different line charts(datasets)
        for (const element of filteredElements) {
          const datasetElement = dataset[element.datasetIndex].data[element.index]
          datasetElement.datasetIndex = element.datasetIndex

          // If first element's lat and lon are set, use them
          if (clickedElements[0]?.lat && clickedElements[0]?.lng) {
            datasetElement.lat = clickedElements[0].lat
            datasetElement.lng = clickedElements[0].lng
            datasetElement.vehicleSpeed = clickedElements[0].vehicleSpeed
            datasetElement.speedUnit = clickedElements[0].speedUnit
          }
          // Fetch lat, lon and speed for first element, and use them for next elements
          // to prevent multiple same api requests
          else {
            const result = await api()['satellite-tracking']
              .get('temperature-sensor/get-location-for-sensor-data', {
                vehicle_id: getSelectedVehicle.value?.[0]?.id,
                date_time: formatSqlDate(datasetElement.x) + ' ' + formatSqlTime(datasetElement.x),
                with_speed: true
              })
            datasetElement.lat = result.data.lat
            datasetElement.lng = result.data.lon
            datasetElement.vehicleSpeed = result.data.speed
            datasetElement.speedUnit = result.data.speedUnit
          }
          datasetElement.tooltipData = [
            {
              label: $t('satellite-tracking/temperature_sensor.time'),
              value: formatSqlTime(datasetElement.x)
            },
            {
              label: $t('satellite-tracking/temperature_sensor.date'),
              value: formatSqlDate(datasetElement.x)
            },
            {
              label: $t('satellite-tracking/temperature_sensor.temperature'),
              value: datasetElement.y + ' °C'
            },
            {
              label: $t('satellite-tracking/temperature_sensor.vehicle_speed'),
              value: !datasetElement.vehicleSpeed || false
                ? ': n/a'
                : `${datasetElement.vehicleSpeed} ${datasetElement.speedUnit}`
            }
          ]
          clickedElements.push(datasetElement)
          if (clickedElements[0].speedUnit === 'undefined') clickedElements[0].speedUnit = datasetElement.speedUnit
        }
        setChartPointsGeolocationData(clickedElements)
      },
      plugins: {
        tooltip: {
          mode: 'x',
          displayColors: false,
          intersect: false,
          filter: function (tooltipItem, currentIndex, tooltipItems) {
            // On zoomed out graph, hovering mouse picks a lot of data for x-axis and displays them all in tooltip.
            // This filter returns only first item from each dataSet from hover picked items passed
            // to tooltip, so it can be correctly displayed in tooltip for different line charts(datasets)
            return tooltipItems[currentIndex].datasetIndex !== tooltipItems[currentIndex - 1]?.datasetIndex
          },
          callbacks: {
            title: function () {},
            labelTextColor: function (context) {
              switch (context.datasetIndex) {
                case 0:
                  return getLabelTextColors.value ? getLabelTextColors.value[0] : 'red'
                case 1:
                  return getLabelTextColors.value ? getLabelTextColors.value[1] : 'blue'
                case 2:
                  return getLabelTextColors.value ? getLabelTextColors.value[2] : 'green'
                case 3:
                  return getLabelTextColors.value ? getLabelTextColors.value[3] : 'yellow'
                default:
                  return 'white'
              }
            },
            label: function (tooltipItem) {
              const timeString = [$t('satellite-tracking/fuel_probe_report.time') + ': ' +
                formatSqlTime(tooltipItem.raw.x)]
              const dateString = [$t('satellite-tracking/fuel_probe_report.date') + ': ' +
                formatSqlDate(tooltipItem.raw.x)]
              const temperatureString = [$t('satellite-tracking/fuel_probe_report.state') + ': ' +
                tooltipItem.raw.y + ' °C']
              const hiddenDatasetStatuses = getChartDataSet.value.map(dataset => dataset.hidden)
              // if item is visible and if there is another visible item after it make space between
              // tooltip data with empty array.
              return !hiddenDatasetStatuses[tooltipItem.datasetIndex] &&
              hiddenDatasetStatuses.slice(tooltipItem.datasetIndex + 1).some((el) => el === false)
                ? [timeString, dateString, temperatureString, []]
                : [timeString, dateString, temperatureString]
            }
          }
        },
        legend: {
          display: false,
          position: 'top',
          labels: {
            boxWidth: 40
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl'
          },
          zoom: {
            mode: 'x',
            wheel: {
              enabled: true,
              speed: 0.2
            },
            pinch: {
              enabled: true
            },
            onZoom: (chart) => {
              if (chart.chart.getZoomLevel() >= 50 && chart.chart.getZoomLevel() <= 300) dynamicPointHitRadius = 10
              else if (chart.chart.getZoomLevel() > 300) dynamicPointHitRadius = 20
              else dynamicPointHitRadius = 1
            }
          },
          limits: {
            x: {
              min: minRangeDate.value,
              max: maxRangeDate.value,
              minRange: 1800000 // minimum zoom to half hour
            }
          }
        },
        decimation: {
          enabled: true
        },
        annotation: {
          ...getShowReferenceValues.value && { annotations: limitLines.value }
        }
      }
    }))

    return {
      chartRef,
      chartData,
      chartOptions
    }
  }
})
</script>
