<template>
  <div>
    <v-btn
      outlined
      color="white"
      @click="openReportParamsDialog()"
    >
      {{ $t('satellite-tracking/temperature_sensor.change_the_terms') }}
    </v-btn>
    <temp-sensor-chart-vehicle-date-picker
      :dialog="parametersDialog"
      @close="closeParametersDialog"
    />
  </div>
</template>

<script>
import TempSensorChartVehicleDatePicker
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/TempSensorChartVehicleDatePicker'

export default {
  name: 'TempSensorChartConditionsButton',
  components: { TempSensorChartVehicleDatePicker },
  data () {
    return {
      parametersDialog: true
    }
  },
  methods: {
    closeParametersDialog () {
      this.parametersDialog = false
    },
    openReportParamsDialog () {
      this.parametersDialog = true
    }
  }
}
</script>
