<template>
  <v-container
    class="fill-height primary-darken-4"
  >
    <v-row id="probeInfo">
      <v-col class="col-max-height">
        <probe-details-sections />
      </v-col>
    </v-row>
    <v-row
      id="graph"
      class="mt-0"
    >
      <v-col class="pt-0">
        <probe-chart />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ProbeDetailsSections from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-details-section/ProbeDetailsSections'
import ProbeChart from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/ProbeChart'
export default {
  name: 'ProbeDetails',
  components: { ProbeDetailsSections, ProbeChart }
}
</script>

<style scoped>

#probeInfo {
  height: 34%;
}

#graph {
  height: 66%;
}

</style>

<style>
.col-max-height {
  max-height: 100%;
  padding-bottom: 0;
}
fieldset {
  /*if needed change to auto and hide scroller*/
  overflow: hidden;
}
</style>
