<template>
  <div>
    <input
      id="sensor2"
      v-model="sensor2Checkbox"
      type="checkbox"
    >
    <label
      for="sensor2"
      class="check-trail"
    >
      <span class="check-handler" />
    </label>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions: mapActionsTemperatureSensor,
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TitleButtonChartTogglerSensor2',
  computed: {
    ...mapGettersTemperatureSensor(['getChartLineToggleStatuses']),
    sensor2Checkbox: {
      get () {
        return this.getChartLineToggleStatuses.sensor2
      },
      set (value) {
        this.setChartLineToggleStatuses({ key: 'sensor2', value: value })
      }
    }
  },
  methods: {
    ...mapActionsTemperatureSensor(['setChartLineToggleStatuses'])
  }
}
</script>

<style scoped lang="scss">

/* Hide the input */
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.check-trail {
  display: flex;
  align-items: center;
  width: 2.6em;
  height: 1.1em;
  background: $sensor-2-unchecked-trail-bckg;
  border-radius: 2.1em;
  transition: $sensor-toggle-transition;
  cursor: pointer;
}
.check-handler {
  display: flex;
  margin-left: 0.2em;
  justify-content: center;
  align-items: center;
  width: 0.9em;
  height: 0.9em;
  background: $sensor-2-unchecked-handler-bckg;
  border-radius: 50%;
  transition: $sensor-toggle-transition;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  &:before {
    content: "\00D7";
    color: white;
    font-size: 0.8em;
    font-weight: bold;
  }
}
input[type="checkbox"]:checked + .check-trail {
  background: $sensor-2-check-trail-bckg;
  .check-handler {
    margin-left: 55%;
    background: $sensor-2-check-handler-bckg;
    &::before {
      content: "\2714"
    }
  }
}

</style>
