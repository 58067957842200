<template>
  <v-row class="no-gutters flex-nowrap text-no-wrap">
    <v-col class="pa-0 align-self-center">
      <title-button-chart-toggler-probe1
        v-if="tankNumber === 1"
        class="mr-1"
      />
      <title-button-chart-toggler-probe2
        v-else-if="tankNumber === 2"
        class="mr-1"
      />
    </v-col>
    <v-col class="pa-0 text-no-wrap align-self-center">
      <p class="white--text mb-0">
        {{ fuelLabel }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import TitleButtonChartTogglerProbe1 from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/TitleButtonChartTogglerProbe1.vue'
import TitleButtonChartTogglerProbe2 from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/TitleButtonChartTogglerProbe2.vue'

export default {
  name: 'TitleFuelProbeIconAndLabel',

  components: {
    TitleButtonChartTogglerProbe1,
    TitleButtonChartTogglerProbe2
  },

  props: {
    label: {
      type: String,
      required: true
    },
    tankNumber: {
      type: Number,
      default: null
    },
    showNumber: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    fuelLabel () {
      return this.tankNumber && this.showNumber
        ? `${this.label} ${this.tankNumber}`
        : this.label
    }
  }
}
</script>

<style scoped>
p {
  line-height: 1;
  font-size: 11px;
}
</style>
