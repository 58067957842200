<template>
  <fieldset class="fill-height">
    <legend>
      {{
        $t('satellite-tracking/fuel_probe.info_from_date_to_date')
          .replace(':from_date', getSelectedDateFrom)
          .replace(':to_date', getSelectedDateTo)
      }}
    </legend>
    <template v-if="summaryData && summaryData.length > 0">
      <v-row
        v-for="item in summaryData"
        :key="item.label"
      >
        <v-col class="py-1">
          <p class="font-weight-bold white--text text--darken-1">
            {{ item.label }} :
          </p>
        </v-col>
        <v-col
          class="text-right font-weight-bold py-0 white--text text--darken-1 col-auto"
        >
          <p>{{ item.value ? item.value + ' L' : 'n/a' }}</p>
        </v-col>
      </v-row>
    </template>
  </fieldset>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

export default {
  name: 'FuelProbeInfoFromDateToDate',

  computed: {
    ...mapGettersFuelProbe(['summaryData', 'getSelectedDateFrom', 'getSelectedDateTo'])
  }
}
</script>

<style scoped>
p{
  font-size: 12px;
}
</style>
