<template>
  <map-base
    ref="mapBase"
    :style="{ zIndex: 0 }"
    :showGeoSearchControl="false"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MapBase from '@/global/components/map/MapBase.vue'
import L from 'leaflet'
const { mapGetters: mapGettersFuelProbe } = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

export default {
  name: 'ProbesMap',

  components: {
    MapBase
  },

  computed: {
    ...mapGettersFuelProbe([
      'refuelingDispensingGeolocation',
      'fuelProbeGraphGeolocation'
    ])
  },

  watch: {
    deep: true,
    refuelingDispensingGeolocation: {
      handler (newRdGeolocation) {
        if (newRdGeolocation?.geolocation) {
          this.generateMarkers(newRdGeolocation)
        }
      }
    },
    fuelProbeGraphGeolocation: {
      deep: true,
      handler (newPgGeolocations) {
        if (newPgGeolocations?.geolocation) {
          this.generateMarkers(newPgGeolocations)
        }
      }
    }
  },
  methods: {
    generateMarkers (items) {
      const popupOptions = {
        offset: [0, 0],
        maxWidth: 300,
        autoPan: false,
        openAutomatically: true
      }
      const markers = [
        {
          lat: items?.geolocation?.lat,
          lon: items?.geolocation?.lng,
          icon: 'graph_chart_marker',
          popup: {
            popupData: items.tooltipData,
            popupOptions: popupOptions
          }
        }
      ]

      const icons = {
        graph_chart_marker: new L.Icon({
          iconUrl: '/img/icons/fuel_probe_graph_pin_line_dataset.svg',
          iconSize: [36, 36],
          iconAnchor: [18, 36],
          popupAnchor: [0, -18]
        })
      }
      const markersConfig = {
        markers: markers,
        icons: icons,
        fitBoundsOptions: {
          maxZoom: 16
        }
      }
      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    }
  }
}
</script>

<style lang="scss">
.leaflet-popup {
  border: 2px solid var(--v-primary-darken-4-base);
  border-radius: 15px;
}

.leaflet-popup-right:before{
  border: none;
}
</style>
