<template>
  <div>
    <v-row
      v-if="viewConfig.digital_input_types"
      align="center"
      class="py-0"
    >
      <v-col class="pr-0">
        <div class="d-flex align-center">
          <v-checkbox
            class="ma-0 pa-0 inactive-checkbox"
            dense
            color="info"
            hide-details
            :ripple="false"
            :label="viewConfig.show_din_status_label"
            :input-value="checkboxes.showDinStatus"
            @change="updateShowDinStatus"
          />
          <v-progress-circular
            v-if="positionsLoading"
            class="ml-2"
            indeterminate
            width="2"
            size="15"
          />
        </div>
      </v-col>
      <v-col class="pl-0">
        <v-select
          class="dense-select"
          label=""
          dense
          solo
          flat
          clearable
          hide-details
          height="25"
          :items="digitalInputTypes"
          :value="selectedDigitalInputType"
          @input="updateDigitalInputType"
        />
      </v-col>
    </v-row>
    <v-checkbox
      class="ma-0 pa-0 inactive-checkbox"
      dense
      color="info"
      hide-details
      :ripple="false"
      :label="$t('base/map.show_alarms')"
      :input-value="checkboxes.showRouteAlarms"
      @change="event => updateCheckboxes({ field: 'showRouteAlarms', value: event })"
    />
    <v-checkbox
      class="ma-0 pa-0 inactive-checkbox"
      dense
      color="info"
      hide-details
      :ripple="false"
      :label="$t('satellite-tracking/live-tracking.show_user_locations')"
      :input-value="checkboxes.showUserLocations"
      @change="event => updateCheckboxes({ field: 'showUserLocations', value: event })"
    />
    <v-checkbox
      class="ma-0 pa-0 inactive-checkbox"
      dense
      color="info"
      hide-details
      :ripple="false"
      :label="$t('satellite-tracking/live-tracking.show_partner_locations')"
      :input-value="checkboxes.showPartnerLocations"
      @change="event => updateCheckboxes({ field: 'showPartnerLocations', value: event })"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  name: 'MapControls',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters([
      'checkboxes',
      'viewConfig',
      'getSelectedTrips',
      'selectedDigitalInputType',
      'companyScopeId',
      'positionsLoading'
    ]),

    digitalInputTypes () {
      if (!this.viewConfig.digital_input_types) return []

      return this.viewConfig.digital_input_types.map(item => {
        return {
          value: item.id,
          text: item.name
        }
      })
    }
  },

  methods: {
    ...mapActions([
      'updateCheckboxes',
      'setSelectedDigitalInputType',
      'fetchTripPositions',
      'setPositionsLoading'
    ]),

    updateShowDinStatus (status) {
      this.updateCheckboxes({ field: 'showDinStatus', value: status })
      this.fetchPositions()
    },

    updateDigitalInputType (type) {
      this.setSelectedDigitalInputType(type)
      this.fetchPositions()
    },

    fetchPositions () {
      if (this.checkboxes.showDinStatus && this.selectedDigitalInputType) {
        this.setPositionsLoading(true)
        this.fetchTripPositions()
      }
    }
  }
}
</script>

<style scoped>
.dense-select {
  max-width: 200px;
  border: 1px solid var(--v-primary-base);
}

.dense-select >>> .v-input__control {
  min-height: 25px !important;
}

.dense-select >>> .v-input__control > .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.dense-select >>> .v-select__selection {
  font-size: 14px;
}
</style>
