<template>
  <map-base
    ref="mapBase"
    :style="{ zIndex: 0 }"
    :showGeoSearchControl="false"
  />
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import MapBase from '@/global/components/map/MapBase.vue'
import L from 'leaflet'
const { mapGetters: mapGettersTemperatureSensor } = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TemperatureSensorMap',
  components: {
    MapBase
  },
  data () {
    return {
      popupOptions: {
        offset: [0, 0],
        maxWidth: 300,
        autoPan: false,
        openAutomatically: true
      }
    }
  },
  computed: {
    ...mapGettersTemperatureSensor([
      'getTemperaturesFromDateToDatePinData',
      'getTemperaturesChartPinData',
      'getTooltipTextColors'
    ])
  },
  watch: {
    deep: true,
    getTemperaturesFromDateToDatePinData: {
      handler (newTemperatureGeolocation) {
        if (newTemperatureGeolocation && newTemperatureGeolocation.geolocation && newTemperatureGeolocation.geolocation.lat && newTemperatureGeolocation.geolocation.lng) {
          const markers = [
            {
              lat: newTemperatureGeolocation.geolocation.lat,
              lon: newTemperatureGeolocation.geolocation.lng,
              icon: 'graph_chart_marker',
              popup: {
                popupData: newTemperatureGeolocation.tooltipData,
                popupOptions: this.popupOptions
              }
            }
          ]

          const icons = {
            graph_chart_marker: new L.Icon({
              iconUrl: '/img/icons/fuel_probe_graph_pin_line_dataset.svg',
              iconSize: [36, 36],
              iconAnchor: [18, 36],
              popupAnchor: [0, -18]
            })
          }

          const markersConfig = {
            markers: markers,
            icons: icons,
            fitBoundsOptions: {
              maxZoom: 16
            }
          }
          this.generateMarkers(markersConfig)
        }
      }
    },
    getTemperaturesChartPinData: {
      deep: true,
      handler (newTsChartGeolocations) {
        if (newTsChartGeolocations[0]?.lat && newTsChartGeolocations[0]?.lng) {
          const markers = [
            {
              lat: newTsChartGeolocations[0].lat,
              lon: newTsChartGeolocations[0].lng,
              icon: 'graph_chart_marker',
              popup: {
                popupData: newTsChartGeolocations,
                popupOptions: this.popupOptions,
                popupTextColors: this.getTooltipTextColors
              }
            }
          ]
          const icons = {
            graph_chart_marker: new L.Icon({
              iconUrl: '/img/icons/fuel_probe_graph_pin_line_dataset.svg',
              iconSize: [36, 36],
              iconAnchor: [18, 36],
              popupAnchor: [0, -18]
            })
          }

          const markersConfig = {
            markers: markers,
            icons: icons,
            fitBoundsOptions: {
              maxZoom: 16
            }
          }
          this.generateMarkers(markersConfig)
        }
      }
    }
  },

  methods: {
    generateMarkers (markersConfig) {
      this.$refs?.mapBase?.onMapReady(function (map) {
        map.generateMarkers(markersConfig)
      })
    }
  }
}
</script>

<style lang="scss">
.leaflet-popup {
  border: 2px solid var(--v-primary-darken-4-base);
  border-radius: 15px;
}

.leaflet-popup-right:before {
  border: none;
}
</style>
