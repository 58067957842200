<template>
  <div>
    <v-checkbox
      class="ma-0 pa-0 inactive-checkbox"
      dense
      color="info"
      hide-details
      :ripple="false"
      :label="$t('satellite-tracking/live-tracking.show_user_locations')"
      :input-value="checkboxes.showUserLocations"
      @change="event => updateCheckboxes({ field: 'showUserLocations', value: event })"
    />
    <v-checkbox
      class="ma-0 pa-0 inactive-checkbox"
      dense
      color="info"
      hide-details
      :ripple="false"
      :label="$t('satellite-tracking/live-tracking.show_partner_locations')"
      :input-value="checkboxes.showPartnerLocations"
      @change="event => updateCheckboxes({ field: 'showPartnerLocations', value: event })"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('road-maintenance/tasks-report')

export default {
  name: 'MapControls',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters([
      'checkboxes',
      'viewConfig',
      'getSelectedTrips',
      'selectedDigitalInputType',
      'companyScopeId',
      'positionsLoading'
    ])
  },

  methods: {
    ...mapActions([
      'updateCheckboxes',
      'setSelectedDigitalInputType',
      'fetchTripPositions',
      'setPositionsLoading'
    ])
  }
}
</script>

<style scoped>
.dense-select {
  max-width: 200px;
  border: 1px solid var(--v-primary-base);
}

.dense-select >>> .v-input__control {
  min-height: 25px !important;
}

.dense-select >>> .v-input__control > .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.dense-select >>> .v-select__selection {
  font-size: 14px;
}
</style>
