<template>
  <v-row class="fill-height">
    <v-col class="col-max-height col-12 col-lg-6">
      <current-temperature-sensor-state />
    </v-col>
    <v-col class="col-max-height col-12 col-lg-6">
      <temperatures-from-date-to-date />
    </v-col>
  </v-row>
</template>

<script>
import CurrentTemperatureSensorState
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-details-section/sections/CurrentTemperatureSensorState'
import TemperaturesFromDateToDate
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-details-section/sections/TemperaturesFromDateToDate'
export default {

  name: 'SensorDetailsSections',

  components: { CurrentTemperatureSensorState, TemperaturesFromDateToDate }
}
</script>
