<template>
  <div
    class="d-flex flex-column fill-height"
  >
    <!-- TODO MOVE fetching logic from component to vuex and use everything from store in subcomponents -->
    <live-tracking-map
      :incoming-locations="locations"
      :incoming-markers="markers"
      :markers-count="getSelectedVehicles.length"
      :refresh-marker-tooltip="refreshMarkerTooltip"
    >
      <template #controls>
        <tracker-data-options
          :open-vehicles-modal="openVehiclesModal"
          :use-config-selected-vehicles="useConfigSelectedVehicles"
          :config-selected-vehicles="configSelectedVehicles"
          @vehicle-picker-input-changed="vehiclesSelected()"
          @digital-input-types-fetched="setInputTypesToStore($event)"
          @selected-vehicles="event => setConfigSelectedVehicles(event)"
        />
      </template>
    </live-tracking-map>
    <v-snackbar
      v-if="socketConnected === false"
      v-model="snackbar"
      bottom
      right
      timeout="-1"
      transition="fade-transition"
      class="mb-2 notification-bar"
    >
      <span class="red--text">
        {{ $t('base.connection_lost') }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
import LiveTrackingMap from '@/modules/satellite-tracking-module/live-tracking/components/LiveTrackingMap'
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import TrackerDataOptions from '@/modules/satellite-tracking-module/live-tracking/components/TrackerDataOptions.vue'
import { updatePageToBeFullHeightOfTheViewport, removeHeightStylesFromTheMainElement } from '@/global/services/helpers/elementStyles'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import Vue from 'vue'
import { isEqual } from 'lodash'
import { api } from '@/global/services/api'
import { convertSecondsToTimestamp, getPassedSecondsFromDate } from '@/global/services/helpers/timeConversion'

const websocketOrigin = window.location.hostname + ':' + window.location.port
const socket = io(websocketOrigin, {
  path: '/live-tracking-websocket'
})

Vue.use(VueSocketIOExt, socket)

const {
  mapGetters: mapGettersTracking,
  mapActions: mapActionsTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')
const {
  mapGetters: mapGettersBase,
  mapActions: mapActionsBase
} = createNamespacedHelpers('base/config')
export default {
  name: 'LiveTracking',

  components: {
    LiveTrackingMap,
    TrackerDataOptions
  },

  data () {
    return {
      openVehiclesModal: false,
      showMap: false,
      configSelectedVehicles: [],
      useConfigSelectedVehicles: null,
      vehiclesLiveData: {},
      snackbar: true,
      socketConnected: null,
      markerTooltipTimeout: null,
      lastSelectedVehicles: null,
      currentTimestamp: 0
    }
  },

  sockets: {
    connect () {
      if (this.socketConnected === false) {
        this.startListeningOnWebsocket()
        this.socketConnected = true
        return
      }
      this.socketConnected = true
    },
    message (vehicleData) {
      Vue.set(this.vehiclesLiveData, vehicleData.vehicleId, vehicleData.data)
      this.startTimer(Math.abs(getPassedSecondsFromDate(vehicleData.data.lastPositionChangeDateTime) - vehicleData.data.positionChangeThreshold * 60))
      this.setLoading(false)
    },
    disconnect () {
      this.socketConnected = false
    }
  },

  computed: {
    ...mapGettersBase([
      'authUserSessionId',
      'isCompanyScopeUpdated'
    ]),
    ...mapGettersTracking([
      'loading',
      'viewConfig',
      'getSelectedVehicles',
      'vehicleDetailsCheckboxes',
      'tooltipComponentKey'
    ]),

    markers () {
      return Object.values(this.vehiclesLiveData)
    },

    locations () {
      return Object.values(this.vehiclesLiveData)
        .map(({ latitude, longitude }) => L.latLng(latitude, longitude))
    }
  },

  watch: {
    getSelectedVehicles: {
      immediate: true,
      handler (getSelectedVehicles) {
        if (!getSelectedVehicles.length) {
          this.openVehiclesModal = true
        }
      }
    },

    viewConfig: {
      deep: true,
      handler (viewConfig) {
        this.configSelectedVehicles = viewConfig.remember_vehicle_ids
        this.useConfigSelectedVehicles = viewConfig.remember_vehicles
      }
    }
  },

  beforeCreate () {
    // We need to set height styles manually, because `main` element is located
    // in the parent component, so we can't set its styles from the child component
    updatePageToBeFullHeightOfTheViewport()
  },

  beforeDestroy () {
    // We need to unset styles manually before leaving the page (so it won't affect other index pages),
    // because `main` element is located in the parent component, so we can't set its styles from the child component
    removeHeightStylesFromTheMainElement()
  },

  async created () {
    await this.fetchWebsocketUserToken()
  },

  methods: {
    ...mapActionsBase([
      'fetchWebsocketUserToken'
    ]),
    ...mapActionsTracking([
      'setInputTypes',
      'setLoading',
      'updateTooltipComponentKey',
      'setControlsLoading'
    ]),

    setInputTypesToStore (inputTypes) {
      this.setInputTypes(inputTypes)
    },

    // First time a tracker is selected, shows the map and starts streaming tracker positions
    vehiclesSelected () {
      this.showMap = true
      this.startListeningOnWebsocket()
    },

    startTimer (seconds) {
      if (seconds < 10) {
        seconds = 10
      }

      const targetTimestamp = convertSecondsToTimestamp(seconds)

      if (this.currentTimestamp < 1 || targetTimestamp < this.currentTimestamp) {
        this.currentTimestamp = targetTimestamp

        if (this.markerTooltipTimeout !== null) {
          clearTimeout(this.markerTooltipTimeout)
        }

        this.markerTooltipTimeout = setTimeout(this.refreshMarkerTooltip, seconds * 1000)
      }
    },

    refreshMarkerTooltip () {
      // re-render tooltip component to fix box miss-aligning
      this.updateTooltipComponentKey(this.tooltipComponentKey + 1)
      this.currentTimestamp = 0
    },

    startListeningOnWebsocket () {
      const vehicleIds = this.getSelectedVehicles.map(vehicle => (
        vehicle.id
      ))
      if (!isEqual(this.lastSelectedVehicles, vehicleIds)) {
        if (Object.keys(vehicleIds).length > 0) {
          this.setLoading(true)
        }
        this.$socket.client.send({
          vehicleIds: vehicleIds,
          token: this.authUserSessionId
        })
      }
      this.lastSelectedVehicles = vehicleIds
      if (Object.keys(this.vehiclesLiveData).length !== 0) {
        this.updateLiveDataObject(vehicleIds)
      }
    },

    updateLiveDataObject (selectedVehicleIds) {
      Object.keys(this.vehiclesLiveData).forEach(vehicleId => {
        if (!selectedVehicleIds.includes(parseInt(vehicleId))) {
          Vue.delete(this.vehiclesLiveData, vehicleId)
        }
      })
    },

    async setConfigSelectedVehicles ({ selectedVehicles, oldSelectedVehicles }) {
      if (this.viewConfig.remember_vehicles) {
        const vehicleIds = selectedVehicles.map(vehicle => vehicle.id)
        const oldVehicleIds = oldSelectedVehicles ? oldSelectedVehicles.map(vehicle => vehicle.id) : []

        if (!isEqual(vehicleIds, oldVehicleIds)) {
          const formData = { remember_vehicle_ids: vehicleIds }
          try {
            this.setControlsLoading(true)
            await api().base.patch('remember-vehicles', formData)
          }
          catch (e) {
            console.log(e)
          }
          finally {
            this.setControlsLoading(false)
          }
        }
      }
    }
  }
}
</script>
