<template>
  <div></div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import colors from 'vuetify/lib/util/colors'
import L from 'leaflet'
import { cloneDeep, isEmpty } from 'lodash'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
const { mapGetters: mapGettersTrackingHistory, mapActions: mapActionsTrackingHistory } = createNamespacedHelpers('satellite-tracking/tracking-history')
const { mapGetters: mapGettersTracking, mapActions: mapActionsTracking } = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'LiveRoutes',

  props: {
    activeVehicleIds: {
      type: Array,
      required: true,
      default: () => ([])
    },
    markers: {
      type: Array,
      default: () => ([])
    },
    midMarkerRadius: {
      type: [Number, null],
      default: 4
    }
  },

  data () {
    return {
      liveRoutes: {},
      fromDates: {},
      maxLiveRouteLocations: null,
      clickedRouteKey: null,
      midPoint: L.divIcon({
        iconSize: [8, 8]
      }),
      startPoint: new L.Icon({
        iconSize: [15, 15],
        iconUrl: '/img/icons/icon_route_begin.png'
      }),
      noDrawPolylines: []
    }
  },

  computed: {
    ...mapGettersTracking([
      'vehicleDetailsCheckboxes'
    ]),
    ...mapGettersTrackingHistory([
      'tripPositions'
    ]),

    showLiveRoutes () {
      return this.vehicleDetailsCheckboxes.live_route
        ? this.vehicleDetailsCheckboxes.live_route[0].showInTooltip
        : false
    }
  },

  watch: {
    // Fetch live route data when live route option is selected
    showLiveRoutes (showLiveRoutes) {
      if (showLiveRoutes) {
        this.getLiveRoutes()
      }
      else {
        window.abortController.abort()
        this.setLiveRoutesLoading(false)
        this.$parent.$parent?.removeAllPolylines()
      }
    },

    midMarkerRadius (radius) {
      // If route position markers displayed
      if (this.clickedRouteKey) this.$parent.$parent?.changeAllCircleMarkersRadius(radius)
    },

    markers (updatedMarkers) {
      if (updatedMarkers.length === 0) this.liveRoutes = {}
      if (!this.showLiveRoutes) return
      this.noDrawPolylines = []

      updatedMarkers.forEach(marker => {
        const previousLocations = this.liveRoutes[marker.vehicleId]?.positions || []
        const color = this.liveRoutes[marker.vehicleId]?.color || this.pickRandomColor()
        const newLocation = {
          lat: marker.latitude ? marker.latitude : null,
          lng: marker.longitude ? marker.longitude : null
        }
        if (previousLocations.length && newLocation.lat && newLocation.lng) {
          const latestLocation = previousLocations[previousLocations.length - 1]

          // Check if coordinates are the same (rounded to 5 digits - 11m precision)
          if ((Math.abs(newLocation.lat - latestLocation.lat) + Math.abs(newLocation.lng - latestLocation.lng)) < 0.0001) {
            this.noDrawPolylines.push(marker.vehicleId)
            return
          }
          this.$set(this.liveRoutes, marker.vehicleId, {
            color,
            positions: [latestLocation, newLocation]
          })
        }
      })
      this.drawPolylines()
    }
  },

  methods: {
    ...mapActionsTracking([
      'setLiveRoutesLoading'
    ]),
    ...mapActionsTrackingHistory([
      'fetchTripPositions',
      'startLoader'
    ]),

    formatSqlDateTime,

    mouseOverLiveRoute (event, vehicleId) {
      const markersKeyPart = `live-route-${vehicleId}`
      const isRouteClicked = this.$parent.$parent?.checkIfMarkersExistOnPolyline([markersKeyPart])

      if (!isRouteClicked) {
        const vehicleDetails = this.markers.find(marker => parseInt(marker.vehicleId) === parseInt(vehicleId))
        let tooltipText
        const polyline = event.target
        if (vehicleDetails && vehicleDetails.registration && typeof vehicleDetails.registration === 'string' && vehicleDetails.registration.trim() !== '') {
          tooltipText = this.$t('satellite-tracking/live_tracking.live_route_on_hover_text').replace(':registration', '<b>' + vehicleDetails.registration + '</b>')
        }
        else {
          tooltipText = this.$t('satellite-tracking/live_tracking.live_route_on_hover_text').replace(':registration', '<b>' + 'n/a' + '</b>')
        }
        const popup = L.popup()
          .setLatLng(event.latlng)
          .setContent(tooltipText)

        polyline.bindPopup(popup, { closeButton: false })
        popup.openOn(this.$parent.mapObject)
      }
    },

    mouseOutLiveRoute (polyline) {
      if (polyline) polyline.closePopup()
    },

    drawPolylines () {
      if (!this.liveRoutes || isEmpty(this.liveRoutes)) {
        return
      }

      const polylineConfig = {
        polylines: [],
        fitPolylines: false,
        patterns: false
      }

      for (const vehicleId in this.liveRoutes) {
        if (!Object.prototype.hasOwnProperty.call(this.liveRoutes, vehicleId)) {
          continue
        }

        const route = this.liveRoutes[vehicleId]
        const positions = route.positions || []
        if (positions.length < 2) {
          continue
        }
        // Do not redraw the polyline if the vehicle hasn't changed its position
        if (this.noDrawPolylines.includes(Number(vehicleId))) {
          continue
        }

        const color = route?.color || this.pickRandomColor()

        const polyline = {
          coordinates: positions.map(obj => [obj.lat, obj.lng]),
          options: {
            id: `live-route-${vehicleId}`,
            color,
            weight: 7
          },
          polylineClick: () => this.handleRouteClick(vehicleId),
          polylineMouseOver: (e) => this.mouseOverLiveRoute(e, vehicleId),
          polylineMouseOut: (e) => this.mouseOutLiveRoute(e.target)
        }

        polylineConfig.polylines.push(polyline)
      }

      if (polylineConfig.polylines.length) {
        this.$parent.$parent?.generatePolylines(polylineConfig)
      }
    },

    // Get live route for each selected vehicle
    async getLiveRoutes () {
      window.abortController = new AbortController()
      await this.setLiveRoutesLoading(true)
      const failedRequests = {}
      this.liveRoutes = {}
      this.noDrawPolylines = []

      // activeVehicleIds are vehicles showed on map (not selected vehicles because these items could be filtered)
      // we need to copy array not to affect the original as watcher is triggered wrongly
      const vehicleIds = Array.from(this.activeVehicleIds)

      while (vehicleIds.length) {
        // Shift method removes and returns first of the array
        const vehicleId = vehicleIds.shift()

        // We will try to fetch live routes for the given vehicle id max two times
        // Failed request for vehicle id will be `undefined` on the first run, and it will
        // be set to 1 in the catch block on request error
        while ([undefined, 1].includes(failedRequests[vehicleId])) {
          try {
            // Get live routes data from the API
            const { data } = await api()['satellite-tracking']
              .post('live-tracking/routes', { vehicleId }, {
                signal: window.abortController.signal
              })
            // Skip to the next vehicle id if data is empty
            if (!data || isEmpty(data) || !data.positions || !data.positions.length) break

            // Assign color and positions live route data for the given vehicle id
            const color = this.liveRoutes[vehicleId]?.color || this.pickRandomColor()
            this.$set(this.liveRoutes, vehicleId, {
              color,
              positions: data.positions,
              from: data.from
            })
            // This will be used later when user click on route to get first 'from' date, 'to' date will be now
            this.$set(this.fromDates, vehicleId, {
              from: data.from
            })
            this.drawPolylines()
            // Break from the inner while loop
            break
          }
          catch {
            // If failed request for vehicle id is set to 1, that means this is the second error,
            // so we will simply break from the inner loop
            if (failedRequests[vehicleId] === 1) {
              break
            }
            // Otherwise, this is the first request error, so set failed request for vehicle id to 1
            // which will retry request one more time
            else {
              failedRequests[vehicleId] = 1
            }
          }
        }
      }
      await this.setLiveRoutesLoading(false)
    },

    // Only one route can have mid-points
    async handleRouteClick (vehicleId) {
      try {
        const routeKey = `live-route-${vehicleId}`
        const currentDate = new Date()
        const newFrom = !isEmpty(this.fromDates) && this.fromDates[vehicleId] && this.fromDates[vehicleId].from ? this.fromDates[vehicleId].from : currentDate
        const markersExist = this.$parent.$parent?.checkIfMarkersExistOnPolyline([routeKey])

        // If we click the same route second time, we delete all it's mid-points (if exists) first and then adding new ones
        if (this.clickedRouteKey === routeKey) {
          if (markersExist) {
            this.$parent.$parent?.removeMarkersFromPolyline([routeKey])
            this.clickedRouteKey = null
          }
          else {
            await this.addMidRoutePositions(vehicleId, newFrom, currentDate, routeKey)
          }
        }
        else {
          this.$parent.$parent?.removeMarkersFromPolyline([this.clickedRouteKey])
          await this.addMidRoutePositions(vehicleId, newFrom, currentDate, routeKey)
        }
      }
      catch (e) {
        console.log('Error occurred in handleRouteClick method in LiveRoutes component', {
          error: e,
          vehicleId: vehicleId
        })
      }
    },

    async addMidRoutePositions (vehicleId, newFrom, currentDate, routeKey) {
      this.startLoader()

      await this.fetchTripPositions({
        params: {
          vehicle_ids: [vehicleId],
          from: newFrom,
          to: currentDate
        }
      })

      const additionalMarkers = []
      const positionsData = cloneDeep(this.tripPositions).positions[vehicleId]
      // Omitting last position because it represents current vehicle position and marker for this position won't be visible
      positionsData.pop()

      if (positionsData && positionsData.length) {
        let lastPosition = null

        // Adding another positions to the route
        positionsData.forEach(tripPosition => {
          // At the outset, when the last position is null, draw a circle marker
          if (!lastPosition) {
            additionalMarkers.push({
              type: 'circle',
              popup: {
                popupData: async () => await this.getPositionMarkerTooltipData(tripPosition, vehicleId)
              },
              coordinates: { lat: tripPosition.lat, lng: tripPosition.lng },
              options: {
                id: routeKey + '_' + tripPosition.id,
                icon: this.midPoint,
                radius: this.midMarkerRadius,
                color: 'black',
                fillColor: '#fff',
                fillOpacity: '1'
              }
            })
            lastPosition = tripPosition
          }
          // Verify whether the last and current positions coincide; if they do, remove the previous marker point and render a new one
          else if (!isEmpty(lastPosition) &&
            (Math.abs(tripPosition.lat - lastPosition.lat) + Math.abs(tripPosition.lng - lastPosition.lng)) < 0.0001 &&
            !tripPosition?.inUse) {
            this.$parent.$parent?.removeMarkersFromPolyline([lastPosition?.id])

            additionalMarkers.push({
              type: 'circle',
              popup: {
                popupData: async () => await this.getPositionMarkerTooltipData(tripPosition, vehicleId)
              },
              coordinates: { lat: tripPosition.lat, lng: tripPosition.lng },
              options: {
                id: routeKey + '_' + tripPosition.id,
                icon: this.midPoint,
                radius: this.midMarkerRadius,
                color: 'black',
                fillColor: '#fff',
                fillOpacity: '1'
              }
            })
          }
          // If 'inUse' is true, it indicates that the vehicle has initiated a new trip, thus marking it as the starting point
          else if (tripPosition?.inUse) {
            additionalMarkers.push({
              popup: {
                popupData: async () => await this.getPositionMarkerTooltipData(tripPosition, vehicleId, 'start')
              },
              coordinates: { lat: tripPosition.lat, lng: tripPosition.lng },
              options: {
                id: routeKey + '_' + tripPosition.id,
                icon: this.startPoint
              }
            })
          }
          // If 'inUse' is false and the last and current positions differ, proceed to draw a circle marker
          else {
            additionalMarkers.push({
              type: 'circle',
              popup: {
                popupData: async () => await this.getPositionMarkerTooltipData(tripPosition, vehicleId)
              },
              coordinates: { lat: tripPosition.lat, lng: tripPosition.lng },
              options: {
                id: routeKey + '_' + tripPosition.id,
                icon: this.midPoint,
                radius: this.midMarkerRadius,
                color: 'black',
                fillColor: '#fff',
                fillOpacity: '1'
              }
            })
          }
        })
        if (additionalMarkers.length) {
          this.$parent.$parent?.addMarkersToPolyline(additionalMarkers)
          lastPosition = null
          this.clickedRouteKey = routeKey
        }
      }
    },

    async getPositionMarkerTooltipData (positionData, vehicleId, isStart = '') {
      try {
        const params = {
          lat: positionData && positionData.lat ? positionData.lat : null,
          lng: positionData && positionData.lng ? positionData.lng : null,
          vehicleId: vehicleId ?? null
        }

        const response = params && params.lat && params.lng ? await api()['satellite-tracking'].get('reverse-geocode', params) : null
        const startText = isStart === 'start' ? `<div style="text-align: center; justify-content: center; font-weight: bold;">${this.$t('satellite-tracking/map.route_start')}</div>` : null

        const tooltipContentParts = [
          positionData && positionData.dateTime ? (isStart === 'start' ? `<b>${this.$t('road-maintenance/patrol-service.date_and_time')}: </b>` + this.formatSqlDateTime(positionData.dateTime) : `<b>${this.$t('road-maintenance/patrol-service.date_and_time')}: </b>` + this.formatSqlDateTime(positionData.dateTime)) : null,
          positionData && positionData.speed ? `<b>${this.$t('satellite-tracking/fuel_probe_report.vehicle_speed')}: </b>` + positionData.speed : null,
          response && response.data && response.data.address ? `<b>${this.$t('satellite-tracking/history.pause_address')}: </b>` + response.data.address : null
        ]

        // Filter out null or empty values
        const filteredContentParts = tooltipContentParts.filter(part => part && part.toString().trim() !== '')

        if (startText) {
          return filteredContentParts && filteredContentParts.length ? startText + filteredContentParts.join(' <br> ') : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        }
        else {
          return filteredContentParts && filteredContentParts.length ? filteredContentParts.join(' <br> ') : `<b>${this.$t('satellite-tracking/live_tracking.live_route_positions_unavailable')}</b>`
        }
      }
      catch (e) {
        console.log('Error occurred while getting position marker tooltip data in live routes', {
          error: e,
          positionData: positionData,
          vehicleId: vehicleId
        })
      }
    },

    pickRandomColor () {
      const availableColors = [
        colors.red.base,
        colors.pink.base,
        colors.purple.base,
        colors.blue.base,
        colors.lightBlue.base,
        colors.cyan.base,
        colors.teal.base,
        colors.green.base,
        colors.lime.base,
        colors.amber.base,
        colors.orange.base,
        colors.deepOrange.base,
        colors.brown.base,
        colors.blueGrey.base,
        colors.grey.base
      ]

      const randomIndex = Math.floor(Math.random() * (availableColors.length - 1))

      return availableColors[randomIndex]
    }
  }
}
</script>
