<template>
  <div
    :key="'route-' + route.key"
  >
    <!-- Line tracing the route -->
    <l-polyline
      :lat-lngs="positions"
      :color="route.color"
      :weight="weight"
    />
  </div>
</template>

<script>
import {
  LPolyline
} from 'vue2-leaflet'
import polyUtil from 'polyline-encoded'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('road-maintenance/tasks-report')

export default {
  name: 'MapTask',

  components: {
    LPolyline
  },

  props: {
    route: {
      type: Object,
      required: true
    },
    weight: {
      type: Number,
      default: 11
    }
  },
  computed: {
    ...mapGetters([
      'viewConfig',
      'checkboxes',
      'getSelectedTrips',
      'tripPositions',
      'tripAlarms',
      'sensorActivityData',
      'getSelectedTrips',
      'selectedDigitalInputType',
      'companyScopeId',
      'positionsLoading'
    ]),

    positions () {
      return polyUtil.decode(this.route.polyline)
    }
  }
}
</script>

<style lang="scss">

</style>
