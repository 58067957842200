<template>
  <div
    class="text-center gauge-holder"
  >
    <v-icon
      class="grey--text gas-station-icon-position"
    >
      mdi-gas-station
    </v-icon>
    <radial-gauge
      :value="tankValue"
      :options="options"
    />
    <p
      v-if="tankValue !== null"
      class="white--text mb-0 mt-2"
      style="font-size: 11px"
    >
      {{ fuelProbeName }}:
      {{ fuelTankCurrentValueInPercent }}%
      <template v-if="fuelTankCurrentValueInLitters">
        cca {{ fuelTankCurrentValueInLitters }} L
      </template>
    </p>
    <v-tooltip
      v-if="valueFromChartData"
      bottom
    >
      <template #activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        ><v-icon
          class="red-muted--text"
          small
        >
          mdi-alert-circle-outline
        </v-icon>
        </span>
      </template>
      <span>{{ $t('satellite-tracking/fuel_probe_report.current_value_not_available') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import RadialGauge from 'vue-canvas-gauges/src/RadialGauge'

export default {
  name: 'FuelGauge',

  components: {
    RadialGauge
  },

  props: {
    tankValue: {
      type: Number,
      default: null
    },

    valueFromChartData: {
      type: Boolean,
      default: false
    },

    fuelProbeName: {
      type: String,
      default: ''
    },

    fuelTankCurrentValueInPercent: {
      type: Number,
      default: 0
    },

    fuelTankCurrentValueInLitters: {
      type: Number,
      default: 0
    },

    options: {
      type: Object,
      // https://canvas-gauges.com/documentation/user-guide/configuration
      default: () => ({
        units: 'L',
        minValue: 0,
        width: 150,
        height: 150,
        startAngle: 20,
        ticksAngle: 180,
        valueBox: false,
        maxValue: 100,
        majorTicks: [
          '0',
          '',
          '',
          '1/2',
          '',
          '',
          '1'
        ],
        minorTicks: 12,
        strokeTicks: true,
        highlights: [
          {
            from: 0,
            to: 18,
            color: 'rgba(200, 50, 50, .75)'
          }
        ],
        colorPlate: 'transparent',
        colorMajorTicks: '#485968',
        colorMinorTicks: '#485968',
        colorUnits: '#485968',
        colorNumbers: '#485968',
        colorNeedle: 'red',
        borderShadowWidth: 0,
        borders: false,
        needleType: 'arrow',
        needleWidth: 4,
        needleCircleSize: 10,
        needleCircleOuter: true,
        needleCircleInner: false,
        animationDuration: 1500,
        animationRule: 'linear',
        fontUnitsSize: 36,
        fontNumbersSize: 30
      })
    }
  }
}
</script>

<style scoped lang="scss">
.gauge-holder {
  position: relative;
}

canvas {
  position: relative;
  z-index: 1;
}

.gas-station-icon-position {
  position: absolute;
  opacity: 0.5;
  left: 25%;
  top: 40%;
}
</style>
