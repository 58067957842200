<template>
  <l-layer-group
    ref="customPopup"
    style="position: relative"
  >
    <l-popup class="cluster-popup">
      <div class="mb-0">
        {{ clusterPopupDetails.vehiclesNumber + ' ' + $t('base/alarms.vehicles') }}
      </div>
      <ul>
        <li
          v-for="vehicle in clusterPopupDetails.vehicleList"
          :key="'tooltip-vehicle-' + vehicle.vehicleId"
          style="cursor: pointer"
        >
          <v-row
            no-gutters
            @mouseover="hoveredVehicleId = vehicle.vehicleId"
            @mouseleave="hoveredVehicleId = null"
            @click="setSingleTooltipIdToStore(vehicle.vehicleId)"
          >
            <v-col>
              {{ vehicle.registration }} ({{ vehicle.label }})
            </v-col>
            <div
              class="ml-1"
              style="position: absolute; left: 100%"
            >
              <custom-marker-tooltip-details
                v-show="vehicle.vehicleId === hoveredVehicleId"
                :vehicle="vehicle"
              />
            </div>
          </v-row>
        </li>
      </ul>
    </l-popup>
  </l-layer-group>
</template>
<script>
import { LLayerGroup, LPopup } from 'vue2-leaflet'
import { createNamespacedHelpers } from 'vuex'
import CustomMarkerTooltipDetails
  from '@/modules/satellite-tracking-module/live-tracking/components/CustomMarkerTooltipDetails'

const {
  mapGetters: mapGettersTracking,
  mapActions: mapActionsTracking
} = createNamespacedHelpers('satellite-tracking/live-tracking')

export default {
  name: 'ClusterFeatures',

  components: {
    CustomMarkerTooltipDetails,
    LPopup,
    LLayerGroup
  },

  props: {
    clusterPopupDetails: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      hoveredVehicleId: null
    }
  },

  computed: {
    ...mapGettersTracking(['vehicleDetailsCheckboxes', 'getSingleTooltipId'])
  },

  methods: {
    ...mapActionsTracking(['setSingleTooltipId']),

    setSingleTooltipIdToStore (deviceId) {
      this.getSingleTooltipId === deviceId
        ? this.setSingleTooltipId(null)
        : this.setSingleTooltipId(deviceId)
    }
  }

}
</script>
<style lang="scss">
// not necessary but desirable
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.cluster-popup {
  ul {
    max-height: 10rem;
    overflow-y: auto;

    li {
      padding-right: 1rem;
    }
  }
}

strong {
  font-size: 0.7rem;
  line-height: 1.2;
}

.custom-vehicle-details {
  p {
    line-height: 0;
  }
}
</style>
