<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '479px' : '600px'"
    @keydown.enter.prevent="fetchDataAndCloseDialog"
    @keydown.esc.prevent="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-break">
        <span class="headline">
          {{ $t('satellite-tracking/temperature_sensor.choose_parameters') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <!-- Date picker -->
          <v-row class="mt-3 mb-2">
            <v-col>
              <v-menu
                v-model="menuDateFrom"
                :close-on-content-click="false"
                :nudge-right="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 0 : 40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFromFormatted"
                    :label="$t('satellite-tracking/temperature_sensor.date_from')"
                    prepend-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @blur="parseDmyDate(dateFromFormatted)"
                  />
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  first-day-of-week="1"
                  @input="setParametersForDateToPicker"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuDateTo"
                :close-on-content-click="false"
                :nudge-right="$vuetify.breakpoint.xs ? -70 : $vuetify.breakpoint.sm ? -30 : 40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateToFormatted"
                    :label="$t('satellite-tracking/temperature_sensor.date_to')"
                    prepend-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @blur="parseDmyDate(dateToFormatted)"
                  />
                </template>
                <v-date-picker
                  v-model="dateTo"
                  :min="dateFrom"
                  :max="maxToDate"
                  first-day-of-week="1"
                  @input="menuDateTo = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <!-- ./ Date picker -->
          <!-- Sensor min max temperature slider selectors -->
          <template v-if="getSelectedVehicle && getAssignedTemperatureSensorsBetweenDates.length > 0">
            <div v-show="getShowReferenceValues">
              <v-row
                v-for="(sensorName, index) in getAssignedTemperatureSensorsBetweenDates"
                :key="'slider_' + sensorName + '_' + index"
              >
                <v-col>
                  <v-range-slider
                    :value="getTemperatureRanges[sensorName]"
                    min="-55"
                    max="110"
                    :label="$t('satellite-tracking/temperature_sensor.celsius')"
                    inverse-label
                    :hint="sensorName.toString()"
                    persistent-hint
                    thumb-label="always"
                    thumb-size="25px"
                    class="mt-5"
                    @input="setSliderRange(sensorName, $event)"
                  />
                </v-col>
              </v-row>
            </div>
            <!-- Reference value toggle switch -->
            <v-row>
              <v-col>
                <v-switch
                  :input-value="getShowReferenceValues"
                  class="mb-5"
                  hide-details
                  @change="setShowReferenceValues"
                >
                  <template #label>
                    {{ $t('satellite-tracking/temperature_sensor.show_reference_values') }}
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            <!-- Reference value toggle switch -->
          </template>
          <!-- ./ Sensor min max temp slider selector -->
          <!-- Vehicle picker -->
          <v-row>
            <v-col>
              <vehicle-picker
                key="singleVehicleSelect"
                v-model="selectedVehicle"
                temperature-sensors-only
                :max-select="1"
              />
            </v-col>
          </v-row>
          <!-- ./ Vehicle picker -->
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          @click="$emit('close')"
        >
          {{ $t('satellite-tracking/temperature_sensor.close') }}
        </v-btn>
        <v-btn
          small
          color="primary"
          @click="fetchDataAndCloseDialog()"
        >
          {{ $t('satellite-tracking/temperature_sensor.show_data') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { formatSqlDate, parseDmyDate } from '@/global/services/helpers/dates'
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import dayjs from 'dayjs'
import withoutWatchers from '@/global/mixins/withoutWatchersMixin'

const {
  mapActions: mapActionsTemperatureSensor,
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

const {
  mapGetters: mapGettersBase
} = createNamespacedHelpers('base/config')

export default {
  name: 'TempSensorChartVehicleDatePicker',
  components: { VehiclePicker },
  mixins: [withoutWatchers],
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      menuDateFrom: false,
      dateFrom: null,
      dateFromFormatted: null,
      dateTo: null,
      menuDateTo: false,
      dateToFormatted: null,
      showReferenceValues: false
    }
  },
  computed: {
    ...mapGettersBase(['isCompanyScopeUpdated']),

    ...mapGettersTemperatureSensor([
      'getShowReferenceValues',
      'getSelectedVehicle',
      'getTemperatureRanges',
      'getAssignedTemperatureSensorsBetweenDates'
    ]),
    showDialog () {
      return this.dialog
    },
    selectedVehicle: {
      get () {
        return this.getSelectedVehicle
      },
      set (vehicleObj) {
        this.setSelectedVehicle(vehicleObj)
      }
    },
    maxToDate () {
      return dayjs(this.dateFrom).add(6, 'day').format('YYYY-MM-DD')
    }
  },
  watch: {
    dateFrom: function (newDate) {
      this.dateFromFormatted = formatSqlDate(newDate)
      this.setDateFromFormattedToStore(this.dateFromFormatted)
      const from = dayjs(newDate)
      const to = dayjs(this.dateTo)

      if (from.add(31, 'day').isBefore(to)) {
        this.$withoutWatchers(() => {
          this.dateTo = null
          this.dateToFormatted = null
        })
      }
      this.fetchAssignedTemperatureSensorsBetweenDates()
    },

    dateTo: function (newDate) {
      this.dateToFormatted = formatSqlDate(newDate)
      this.setDateToFormattedToStore(this.dateToFormatted)
      this.fetchAssignedTemperatureSensorsBetweenDates()
    },

    getSelectedVehicle: function () {
      this.fetchAssignedTemperatureSensorsBetweenDates()
    },

    isCompanyScopeUpdated: function () {
      this.datesSetup()
    }
  },
  created () {
    this.datesSetup()
  },
  methods: {
    ...mapActionsTemperatureSensor([
      'setShowReferenceValues',
      'setSelectedVehicle',
      'setTemperatureRange',
      'setSelectedDateFrom',
      'setSelectedDateTo',
      'fetchAssignedTemperatureSensorsBetweenDates',
      'fetchTemperatureSensorData'
    ]),
    parseDmyDate,
    setParametersForDateToPicker () {
      this.menuDateFrom = false
    },
    setDateFromFormattedToStore (date) {
      this.setSelectedDateFrom(date)
    },
    setDateToFormattedToStore (date) {
      this.setSelectedDateTo(date)
    },
    datesSetup () {
      this.dateFrom = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      this.dateFromFormatted = formatSqlDate(this.dateFrom)
      this.setDateFromFormattedToStore(this.dateFromFormatted)
      this.dateTo = dayjs().format('YYYY-MM-DD')
      this.dateToFormatted = formatSqlDate(this.dateTo)
      this.setDateToFormattedToStore(this.dateToFormatted)
    },
    setSliderRange (sensorName, tempRange) {
      this.setTemperatureRange({ key: sensorName, value: tempRange })
    },
    fetchDataAndCloseDialog () {
      this.fetchTemperatureSensorData()
      this.$emit('close')
    }
  }
}
</script>
