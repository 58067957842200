<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import { marker, DomEvent, Icon } from 'leaflet'
import { findRealParent, propsBinder } from 'vue2-leaflet'
import 'leaflet.marker.slideto'
const props = {
  markerVehicleData: {
    type: Object,
    custom: true,
    default: () => {}
  },
  draggable: {
    type: Boolean,
    custom: true,
    default: false
  },
  visible: {
    type: Boolean,
    custom: true,
    default: true
  },
  latLng: {
    type: [Object, Array],
    custom: true
  },
  icon: {
    custom: false,
    default: () => new Icon.Default()
  },
  zIndexOffset: {
    type: Number,
    custom: false
  },
  options: {
    type: Object,
    default: () => ({})
  },
  duration: {
    type: Number,
    required: true
  },
  keepAtCenter: {
    type: Boolean,
    default: false
  }
}
export default {
  name: 'CustomMovingMarker',
  props,
  data () {
    return {
      ready: false,
      map: null,
      isMarkerSliding: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      const options = this.options
      if (this.icon) {
        options.icon = this.icon
      }
      options.vehicleData = this.markerVehicleData
      options.draggable = this.draggable

      this.map = this.$parent?.$parent?.$parent?.$refs?.map?.mapObject
      this.mapObject = this.map
      this.mapObject = marker(this.latLng, options)
      this.mapObject.on('move', ev => {
        if (Array.isArray(this.latLng)) {
          this.latLng[0] = ev.latlng.lat
          this.latLng[1] = ev.latlng.lng
        }
        else {
          this.latLng.lat = ev.latlng.lat
          this.latLng.lng = ev.latlng.lng
        }
      })
      DomEvent.on(this.mapObject, this.$listeners)
      propsBinder(this, this.mapObject, props)
      this.ready = true
      this.parentContainer = findRealParent(this.$parent)
      this.parentContainer.addLayer(this, !this.visible)
    })
  },
  beforeDestroy () {
    if (this.parentContainer) {
      if (this.mapObject && this.isMarkerSliding) {
        this.mapObject.slideCancel()
      }
      this.parentContainer.removeLayer(this)
    }
  },
  methods: {
    setDraggable (newVal) {
      if (this.mapObject && this.mapObject.dragging) {
        newVal
          ? this.mapObject.dragging.enable()
          : this.mapObject.dragging.disable()
      }
    },
    setVisible (newVal, oldVal) {
      if (newVal === oldVal) return
      if (this.mapObject) {
        if (newVal) {
          this.parentContainer.addLayer(this)
        }
        else {
          this.parentContainer.removeLayer(this)
        }
      }
    },
    setLatLng (newVal) {
      if (newVal == null) {
        return
      }
      try {
        if (this.mapObject) {
          const oldLatLng = this.mapObject.getLatLng()
          const newLatLng = {
            lat: newVal[0] || newVal.lat,
            lng: newVal[1] || newVal.lng
          }
          if (
            newLatLng.lat !== oldLatLng.lat ||
            newLatLng.lng !== oldLatLng.lng
          ) {
            let mapObject = null
            if (this.map) {
              this.isMarkerSliding = true
              mapObject = this.mapObject.slideTo(newLatLng, {
                duration: this.duration,
                keepAtCenter: this.keepAtCenter
              })
            }
            const that = this
            setTimeout(() => {
              const temp = that.mapObject.getLatLng()
              if (temp.lat && temp.lng && newLatLng.lat && newLatLng.lng) {
                if (temp.lat.toFixed(2) === newLatLng.lat.toFixed(2) && temp.lng.toFixed(2) === newLatLng.lng.toFixed(2)) {
                  that.isMarkerSliding = false
                }
              }
            }, 1500)
            // @todo sliding doesn't work when marker is entering the viewport so we manually set marker's position
            if (!mapObject) {
              this.mapObject.setLatLng(newLatLng)
            }
          }
        }
      }
      catch (err) {
        console.log('Sliding error occurred')
        console.log(err)
      }
    }
  }
}
</script>
