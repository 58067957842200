var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"},{name:"stripped",rawName:"v-stripped"}],staticClass:"fill-height overflow-y-auto",attrs:{"headers":_vm.header,"item-key":"id","items":_vm.items,"footer-props":{
    itemsPerPageOptions: [10, 20, 50]
  }},scopedSlots:_vm._u([{key:"item.vehicle",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.vehicle ? item.vehicle : '-')+" ")]}},{key:"item.address",fn:function(ref){
  var item = ref.item;
return [_c('reverse-geocoding-field',{attrs:{"lat-lng":{ lat: item.lat, lng: item.lon },"text-alignment":'center',"loading-indicator-alignment":'center'}})]}},{key:"item.started_at",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.startedAt ? item.startedAt : '-')+" ")]}},{key:"item.finished_at",fn:function(ref){
  var item = ref.item;
return [_c('div',{class:_vm.finishTimeClass[item.status]},[_vm._v(" "+_vm._s(item.finishedAt ? item.finishedAt : '-')+" ")])]}},{key:"item.days",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.days)+" ")]}},{key:"item.show_on_map",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.emitSelectedIcon(item)}}},[_c('v-icon',[_vm._v("mdi-map-marker-radius")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }