<template>
  <div>
  <v-data-table
    v-show="true"
    v-stripped
    class="fill-height overflow-y-auto"
    :headers="header"
    item-key="id"
    :items="items"
    :footer-props="{
      itemsPerPageOptions: [10, 20, 50]
    }"
  >
    <template #[`item.vehicle`]="{ item }">
      {{ item.vehicle ? item.vehicle : '-' }}
    </template>
    <template #[`item.address`]="{ item }">
      <reverse-geocoding-field
        :lat-lng="{ lat: item.lat, lng: item.lon }"
        :text-alignment="'center'"
        :loading-indicator-alignment="'center'"
      />
    </template>
    <template #[`item.started_at`]="{ item }">
      {{ item.startedAt ? item.startedAt : '-' }}
    </template>
    <template #[`item.finished_at`]="{ item }">
      <div
        :class="finishTimeClass[item.status]"
      >
        {{ item.finishedAt ? item.finishedAt : '-' }}
      </div>
    </template>
    <template #[`item.days`]="{ item }">
      {{ item.days }}
    </template>
    <template
      #[`item.show_on_map`]="{ item }"
    >
      <v-btn
        icon
        @click="emitSelectedIcon(item)"
      >
        <v-icon>mdi-map-marker-radius</v-icon>
      </v-btn>
    </template>
  </v-data-table>
  </div>
</template>
<script>

import { forEach } from 'lodash'
import ReverseGeocodingField from '@/global/components/geocoding/ReverseGeocodingField.vue'

export default {
  name: 'LocationHistoryTable',
  components: {
    ReverseGeocodingField
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    header () {
      const data = []
      const columns = ['vehicle', 'address', 'started_at', 'finished_at', 'days', 'show_on_map']
      const translatedColumns = [
        this.$t('satellite-tracking/location-history.vehicle_container'),
        this.$t('satellite-tracking/report.address'),
        this.$t('satellite-tracking/report.start_time'),
        this.$t('satellite-tracking.finish_time'),
        this.$t('satellite-tracking.days'),
        this.$t('satellite-tracking.show_on_map')
      ]
      forEach(columns, function (column, key) {
        data.push({
          text: translatedColumns[key],
          value: column,
          align: 'center',
          class: 'data-table-header',
          sortable: false
        })
      })
      return data
    },
    finishTimeClass () {
      return {
        old_record: '',
        newest_record: 'orange-text',
        up_to_date_record: 'green-text'
      }
    }
  },
  methods: {
    emitSelectedIcon (item) {
      this.$emit('select-icon', item)
    }
  }
}

</script>
<style scoped>
  .bold-text {
    font-weight: bold;
  }
</style>
