<template>
  <line-chart
    v-if="chartData"
    ref="chartRef"
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import parse from 'date-fns/parse'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LineChart } from 'vue-chart-3'
import zoomPlugin from 'chartjs-plugin-zoom'
import { ref, computed, watch, defineComponent } from '@vue/composition-api'
import { formatSqlDate, formatSqlTime } from '@/global/services/helpers/dates'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const {
  useGetters,
  useActions
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

Chart.register(...registerables, zoomPlugin)

export default defineComponent({
  name: 'ProbeChartBuilder',

  components: { LineChart },

  setup (props, { root }) {
    const chartRef = ref()

    // Translate function from the root instance
    const $t = root.$t

    // Map vuex store getters
    const {
      getColors,
      getBackgroundColors,
      getLabelTextColors,
      fuelProbesData,
      getChartLineToggleStatuses,
      getSelectedDateFrom,
      getSelectedDateTo
    } = useGetters([
      'getColors',
      'getBackgroundColors',
      'getLabelTextColors',
      'fuelProbesData',
      'getChartLineToggleStatuses',
      'getSelectedDateFrom',
      'getSelectedDateTo'
    ])

    // Map vuex store actions
    const { setFuelProbeGraphGeolocation, fetchFuelLevelReadoutDetails } = useActions([
      'setFuelProbeGraphGeolocation',
      'fetchFuelLevelReadoutDetails'
    ])

    watch(
      getChartLineToggleStatuses,
      (newStatuses) => {
        // This code is needed because of this bug:
        // https://github.com/victorgarciaesgi/vue-chart-3/issues/33
        Object.values(newStatuses).forEach((status, index) => {
          if (chartRef.value.chartInstance.data.datasets[index]) {
            chartRef.value.chartInstance.data.datasets[index].hidden = !status
          }
        })

        chartRef.value.chartInstance.update()
      },
      { deep: true }
    )

    // Computed properties for max size and step size of the Y chart axis
    const maxSize = computed(() => {
      return Math.max(...fuelProbesData.value.map(item => item.max_size), 50)
    })
    const stepSize = computed(() => {
      return Math.max(...fuelProbesData.value.map(item => item.step_size), 10)
    })

    // Computed properties for min and max range dates, used to limit panning on the chart
    const minRangeDate = computed(() => {
      return parse(getSelectedDateFrom.value, 'dd.MM.yyyy', new Date())
        .setHours(0, 0, 0, 0)
        .valueOf()
    })
    const maxRangeDate = computed(() => {
      return parse(getSelectedDateTo.value, 'dd.MM.yyyy', new Date())
        .setHours(23, 59, 59, 999)
        .valueOf()
    })

    const setFuelLevelReadoutDetails = (data) => {
      const dataset = getChartDataSet.value
      const clickedItemTimestamp = dataset[data.datasetIndex].data[data.index].x

      fetchFuelLevelReadoutDetails(clickedItemTimestamp).then(readoutData => {
        setFuelProbeGraphGeolocation(readoutData)
      })
    }

    // Computed property for chart dataset
    const getChartDataSet = computed(() => {
      const datasetCommonOptions = {
        label: '',
        pointRadius: 0.1,
        fill: true,
        spanGaps: false,
        borderWidth: 2,
        tension: 0.1,
        hidden: false
      }

      return fuelProbesData.value.map(item => {
        return {
          ...datasetCommonOptions,
          hidden: !getChartLineToggleStatuses.value[`probe${item.number}`],
          label: `${item.label} ${item.number}`,
          borderColor: getColors.value ? getColors.value[`probe${item.number}`] : 'red',
          backgroundColor: getBackgroundColors.value ? getBackgroundColors.value[`probe${item.number}`] : 'red',
          data: item.data.map(dataItem => {
            return {
              x: dataItem.date,
              y: dataItem.value,
              unit: dataItem.unit || 'l',
              vehicleSpeed: dataItem.speed,
              lat: dataItem.lat,
              lng: dataItem.lng
            }
          })
        }
      })
    })

    const chartData = computed(() => ({
      datasets: getChartDataSet.value
    }))

    const chartOptions = computed(() => ({
      maintainAspectRatio: false,
      parsing: false,
      hover: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      scales: {
        y: {
          stacked: false,
          beginAtZero: true,
          min: 0,
          max: maxSize.value,
          grid: {
            display: false,
            color: 'rgba(255,99,132,0.2)'
          },
          ticks: {
            color: 'white',
            stepSize: stepSize.value
          }
        },
        x: {
          type: 'time',
          min: minRangeDate.value,
          max: maxRangeDate.value,
          time: {
            displayFormats: {
              day: 'dd.MM.',
              hour: 'dd.MM. HH:mm',
              minute: 'dd.MM. HH:mm',
              second: 'HH:mm:ss'
            }
          },
          grid: {
            display: true
          },
          ticks: {
            color: 'white'
          }
        }
      },
      onClick: (evt, activeElements) => {
        setFuelLevelReadoutDetails(activeElements[0])
      },
      plugins: {
        tooltip: {
          mode: 'index',
          displayColors: false,
          intersect: false,
          callbacks: {
            title: function () {},
            labelTextColor: function (context) {
              switch (context.datasetIndex) {
                case 0:
                  return getLabelTextColors.value ? getLabelTextColors.value.probe1 : 'red'
                case 1:
                  return getLabelTextColors.value ? getLabelTextColors.value.probe2 : 'blue'
                default:
                  return 'white'
              }
            },
            label: function (tooltipItem) {
              const timeString = [$t('satellite-tracking/fuel_probe_report.time') + ': ' +
              formatSqlTime(tooltipItem.parsed.x)]
              const dateString = [$t('satellite-tracking/fuel_probe_report.date') + ': ' +
              formatSqlDate(tooltipItem.parsed.x)]
              const stateString = [$t('satellite-tracking/fuel_probe_report.state') + ': ' +
              `${tooltipItem.parsed.y} ${tooltipItem.parsed.unit}`]
              return tooltipItem.datasetIndex === 0 && chartData.value[1]?.data[tooltipItem.dataIndex]
                // add space between two datasets with empty array if both datasets are present
                ? [...timeString, ...dateString, ...stateString, []]
                : [...timeString, ...dateString, ...stateString]
            }
          }
        },
        legend: {
          display: false,
          position: 'top',
          labels: {
            boxWidth: 40
          }
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl'
          },
          zoom: {
            mode: 'x',
            wheel: {
              enabled: true,
              speed: 0.2
            },
            pinch: {
              enabled: true
            }
          },
          limits: {
            x: {
              min: minRangeDate.value,
              max: maxRangeDate.value,
              minRange: 1800000 // minimum zoom to minutes
            }
          }
        },
        decimation: {
          enabled: true
        }
      }
    }))

    return {
      chartRef,
      chartData,
      chartOptions
    }
  }
})
</script>
