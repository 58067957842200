<template>
  <fieldset class="fill-height">
    <legend>{{ $t('satellite-tracking/fuel_probe.graphical_representation') }}</legend>
    <v-row class="py-0">
      <v-col />
      <v-col class="text-center col-auto pa-0 pt-3 align-self-center">
        <probe-chart-title />
      </v-col>
      <v-col class="py-0 text-right">
        <probe-chart-conditions-button />
      </v-col>
    </v-row>
    <v-row class="py-0">
      <v-col class="pb-0">
        <probe-chart-builder v-if="fuelProbesData && fuelProbesData.length" />
      </v-col>
    </v-row>
  </fieldset>
</template>

<script>
import ProbeChartBuilder from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/ProbeChartBuilder'
import ProbeChartTitle from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/ProbeChartTitle'
import ProbeChartConditionsButton from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/ProbeChartConditionsButton'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

export default {
  name: 'ProbeChart',

  components: {
    ProbeChartConditionsButton,
    ProbeChartBuilder,
    ProbeChartTitle
  },

  computed: {
    ...mapGettersFuelProbe(['fuelProbesData'])
  }
}
</script>
