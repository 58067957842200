<template>
  <fieldset class="fill-height">
    <legend>
      {{ $t('satellite-tracking/temperature_sensor.current_temperature_sensor_state') }}
    </legend>
    <v-row
      :class="{ 'flex-nowrap': $vuetify.breakpoint.smAndUp }"
    >
      <template v-if="!getSelectedVehicle || getTemperatureSensorCurrentData.length === 0">
        <v-col
          v-for="index in 4"
          :key="'sensor_template_' + index"
          class="col-6 col-sm pa-1 lower-opacity"
        >
          <temperature-sensor-gauge
            :temperature-sensor-name="$t('base/tracker.sensor') + ' ' + index"
          />
        </v-col>
      </template>
      <template v-else-if="getTemperatureSensorCurrentData.length > 0">
        <v-col
          v-for="(sensor, index) in getTemperatureSensorCurrentData"
          :key="'sensor_current_state_' + index"
          class="pa-1 col-6 col-sm"
          :class="getTemperatureSensorCurrentData.length > 0 ? 'col-autos': 'col'"
        >
          <temperature-sensor-gauge
            :temperature-sensor-name="sensor.label"
            :temperature-value="sensor.temperature_value"
            :date="sensor.datetime"
            :options="tempSensorGaugeOptions"
          />
        </v-col>
      </template>
    </v-row>
  </fieldset>
</template>

<script>
import TemperatureSensorGauge
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-details-section/sections/TemperatureSensorGauge'
import { createNamespacedHelpers } from 'vuex'
const {
  mapGetters: mapGettersTemperatureSensor,
  mapActions: mapActionsTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'CurrentTemperatureSensorState',
  components: { TemperatureSensorGauge },
  data () {
    return {
      tempSensorRealMin: -55,
      tempSensorRealMax: 110,
      autoRefreshInterval: null
    }
  },
  computed: {
    ...mapGettersTemperatureSensor(['getSelectedVehicle', 'getTemperatureSensorCurrentData']),
    tempSensorGaugeOptions () {
      return {
        minValue: this.getMinValue,
        maxValue: this.getMaxValue,
        majorTicks: this.getMajorTicks
      }
    },
    getMinValue () {
      const minOfAllSensors = Math.min(...this.getTemperatureSensorCurrentData.map(
        item => item.temperature_value
      ))
      return minOfAllSensors - 10
    },
    getMaxValue () {
      const maxOfAllSensors = Math.max(...this.getTemperatureSensorCurrentData.map(
        item => item.temperature_value
      ))
      return maxOfAllSensors + 10
    },
    getMajorTicks () {
      // We want 6 major ticks for the gauge to look nice
      const thickStep = Math.abs(this.getMaxValue - this.getMinValue) / 5
      const thick = []
      let step = this.getMinValue
      for (let i = 0; i <= 5; i++) {
        thick.push(Math.round(step))
        step += thickStep
      }
      return thick
    }
  },
  mounted () {
    this.autoRefreshInterval = setInterval(() => this.updateTemperatureSensorData(), 60000)
  },
  destroyed () {
    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval)
    }
  },
  methods: {
    ...mapActionsTemperatureSensor(['fetchCurrentTemperatureSensorData']),
    updateTemperatureSensorData () {
      if (this.getSelectedVehicle) {
        return this.fetchCurrentTemperatureSensorData()
      }
    }
  }
}

</script>

<style scoped lang="scss">
.lower-opacity {
  opacity: 0.5;
}
</style>
