<template>
  <div
    class="text-center gauge-holder"
  >
    <linear-gauge
      :value="temperatureValue"
      :options="mergedOptions"
    />
    <div class="mt-n4">
      <p
        class="white--text mb-0 d-inline"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{
                temperatureSensorName.length < 17
                  ? temperatureSensorName
                  : temperatureSensorName.substr(0, 14) + '...'
              }}
            </span>
          </template>
          <span>{{ temperatureSensorName }}</span>
        </v-tooltip>
      </p>
      <p
        class="mb-0 d-inline ml-2"
        :class="getTextColorClassBasedOnTemperatureRange(temperatureValue, temperatureSensorName)"
      >
        {{ temperatureValue }} °C
      </p>
      <p
        class="grey--text mb-0"
      >
        {{ formatSqlDateTime(date) }}
      </p>
    </div>
  </div>
</template>

<script>
import LinearGauge from 'vue-canvas-gauges/src/LinearGauge'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import { createNamespacedHelpers } from 'vuex'
const {
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TemperatureSensorGauge',
  components: {
    LinearGauge
  },
  props: {
    temperatureValue: {
      type: Number,
      default: 36.6
    },
    temperatureSensorName: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: '2001-01-01'
    },
    options: { // https://canvas-gauges.com/documentation/user-guide/configuration
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      defaultOptions: {
        width: 80,
        height: 175,
        barBeginCircle: 15,
        minorTicks: '10',
        minValue: '35',
        maxValue: '42',
        majorTicks: [35, 36, 37, 38, 39, 40, 41, 42],
        ticksWidth: '18',
        ticksWidthMinor: '7.5',
        highlights: false,
        valueBox: false,
        colorValueText: '#C0C7CF',
        valueBoxStroke: '1',
        colorValueBoxShadow: 'white',
        colorValueBoxBackground: false,
        fontValueSize: 28,
        valueInt: 1,
        valueDec: 1,
        colorPlate: 'transparent',
        colorMajorTicks: '#C0C7CF',
        colorMinorTicks: '#C0C7CF',
        colorUnits: '#C0C7CF',
        colorNumbers: '#C0C7CF',
        colorBarProgress: '#E54E4E',
        colorBar: '#f5f5f5',
        borderShadowWidth: 0,
        borders: false,
        needleWidth: 6,
        animationDuration: 2000,
        animationRule: 'linear',
        barWidth: 6,
        fontUnitsSize: 30,
        fontNumbersSize: 30
      }
    }
  },
  computed: {
    ...mapGettersTemperatureSensor(['getTemperatureRanges']),
    mergedOptions () {
      return {
        ...this.defaultOptions,
        ...this.options
      }
    }
  },
  methods: {
    formatSqlDateTime,
    getTextColorClassBasedOnTemperatureRange (temperature, sensorName) {
      if (Object.keys(this.getTemperatureRanges).length === 0) return 'white--text'
      // Slider values are stored as [xx, yy] where xx is left slider value (min in this case)
      // and yy is right side of picker, max slider value in this case

      switch (true) {
        case temperature > this.getTemperatureRanges[sensorName][1]:
          return 'red--text'
        case temperature < this.getTemperatureRanges[sensorName][0]:
          return 'blue--text'
        default:
          return 'white--text'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gauge-holder {
  position: relative;
}

p {
  font-size: 11px
}
</style>
