<template>
  <div
    class="overflow-y-hidden"
    :style="wrapperStyles"
  >
    <!-- Desktop view -->
    <splitpanes
      horizontal
      :push-other-panes="false"
      class="default-theme h-full"
      @resize="resizeMap = !resizeMap"
    >
      <pane
        :size="firstPaneSize"
        max-size="88"
        min-size="12"
      >
        <!-- lazy because of problems with vuetify and vue-leaflet https://github.com/vue-leaflet/Vue2Leaflet/issues/96 -->
        <v-lazy class="h-full">
          <!-- Important to have wrapping div when using `v-lazy`, otherwise map layout will be broken -->
          <div class="h-full">
            <map-base
              ref="mapBase"
              :style="mapStyle"
              :mapOptions="mapOptions"
              :updateCenter="resetIcon"
            />
          </div>
        </v-lazy>
      </pane>
      <pane
        :size="secondPaneSize"
        min-size="12"
        max-size="88"
        class="overflow-y-auto"
      >
        <v-container fluid>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  cols="auto"
                  md="auto" lg="auto" xl="auto"
                  class="mt-0"
                >
                  <v-select
                    v-model="vehicle"
                    :items="vehicles"
                    :label="$t('satellite-tracking/location-history.vehicle_container')"
                    item-text="registration_and_label"
                    item-value="id"
                    prepend-inner-icon="mdi-map-marker"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="auto"
                  md="auto" lg="auto" xl="auto"
                >
                  <v-menu
                    v-model="fromMenu"
                    transition="slide-y-transition"
                    nudge-top="450"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromFormatted"
                        :label="$t('base.from')"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @blur="parseDmyDate(fromFormatted)"
                      />
                    </template>
                    <datetime-picker
                      v-model="from"
                      :emit-default-time="true"
                      :default-time="'00:00:00'"
                      :min-date="minFromDate"
                      :max-date="today"
                      :reset-tabs="resetFromTabs"
                      @close-picker="fromMenu = false"
                      :on-double-click="closeDatetimePicker"
                    >
                      <v-col class="white text-right pb-2 px-2">
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="closeFromMenu"
                        >
                          {{ $t('base.ok') }}
                        </v-btn>
                      </v-col>
                    </datetime-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="auto"
                  md="auto" lg="auto" xl="auto"
                >
                  <v-menu
                    v-model="toMenu"
                    transition="slide-y-transition"
                    nudge-top="450"
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="toFormatted"
                        :label="$t('base.to')"
                        prepend-inner-icon="mdi-calendar-end"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @blur="parseDmyDate(toFormatted)"
                      />
                    </template>
                    <datetime-picker
                      v-model="to"
                      :emit-default-time="true"
                      :default-time="defaultToTime"
                      :min-date="minDate"
                      :reset-tabs="resetToTabs"
                      @close-picker="toMenu = false"
                      :on-double-click="closeDatetimePicker"
                    >
                      <v-col class="white text-right pb-2 px-2">
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="closeToMenu"
                        >
                          {{ $t('base.ok') }}
                        </v-btn>
                      </v-col>
                    </datetime-picker>
                  </v-menu>
                </v-col>
                <v-col
                  class="pt-7"
                  cols="auto"
                  md="auto" lg="auto" xl="auto"
                >
                  <v-btn
                    plain
                    @click="getLocations"
                  >
                    <v-icon
                      large
                    >
                      mdi-file-table
                    </v-icon>
                    <span class="ml-5">{{ $t('satellite-tracking/location-history.show_locations') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  class="pt-7"
                  cols="auto"
                  md="auto" lg="auto" xl="auto"
                >
                  <v-btn
                    plain
                    @click="exportData"
                  >
                    <v-icon
                      large
                    >
                      mdi-application-export
                    </v-icon>
                    <span class="ml-5">{{ $t('satellite-tracking/report.export') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <location-history-table
                    :items="locations"
                    @select-icon="selectIcon"
                  >
                  </location-history-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
      </pane>
    </splitpanes>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="650"
    >
      <v-card
      >
        <v-row class="flex justify-space-between ma-0 px-2">
          <v-card-title>{{ $t('satellite-tracking/location-history.warning') }}</v-card-title>
          <v-btn
            text
            x-small
            class="mt-3 mr-2 pa-0 no-background-hover"
            elevation="0"
            @click="closeDialog"
          >
            <v-icon
              color="grey darken-1"
              size="1.8rem"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDialog"
          >
            {{ $t('base.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay
      :value="showLoader"
      z-index="1000"
    >
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </div>
</template>

<script>
import { api } from '@/global/services/api'
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import dayjs from 'dayjs'
import { debounce, isEmpty } from 'lodash'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import LocationHistoryTable from '../components/LocationHistoryTable.vue'
import MapBase from '@/global/components/map/MapBase.vue'
import L from 'leaflet'

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'ExcavationOrderIndex',
  components: {
    DatetimePicker,
    LocationHistoryTable,
    Pane,
    Splitpanes,
    MapBase
  },

  data () {
    return {
      dialog: false,
      dialogMessage: '',
      firstPaneSize: 50,
      secondPaneSize: 50,
      zoom: 20,
      resetFromTabs: false,
      resetToTabs: false,
      fromMenu: false,
      toMenu: false,
      today: new Date().toISOString().slice(0, 10),
      from: null,
      to: null,
      fromFormatted: null,
      toFormatted: null,
      resizeMap: false,
      vehicles: [],
      locations: [],
      vehicle: null,
      markers: [],
      selectedIcon: null,
      oldSelectedItem: false,
      mapStyle: {
        zIndex: 0,
        flex: 1
      },
      showLoader: true,
      mapOptions: {
        renderer: L.canvas()
      }
    }
  },
  computed: {
    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    },
    icons () {
      const commonIconDataObject = {
        iconSize: [12, 12],
        iconAnchor: [6, 6]
      }
      return {
        up_to_date_record: L.divIcon({
          ...commonIconDataObject,
          className: 'green_icon'
        }),
        old_record: L.divIcon({
          ...commonIconDataObject,
          className: 'gray_icon'
        }),
        newest_record: L.divIcon({
          ...commonIconDataObject,
          className: 'orange_icon'
        })
      }
    },

    minFromDate () {
      return dayjs(this.to).subtract(2, 'years').toISOString()
    },

    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    }
  },

  watch: {
    selectedIcon (item) {
      if (!isEmpty(item)) {
        const marker = this.$refs.mapBase?.findMarkerById(item.id)
        if (this.oldSelectedItem) {
          const marker2 = this.$refs.mapBase?.findMarkerById(this.oldSelectedItem.id)
          var currentClass2 = marker2.getIcon().options.className
          const oldIcon = L.divIcon({
            className: currentClass2.replace(' selected', ''),
            iconSize: [12, 12]
          })
          marker2.setIcon(oldIcon)
        }
        this.$refs?.mapBase?.$refs?.map?.mapObject?.setView(L.latLng([item.lat, item.lon]), this.zoom)
        var currentClass = marker.getIcon().options.className
        const newIcon = L.divIcon({
          className: currentClass + ' selected',
          iconSize: [32, 32]
        })
        marker.setIcon(newIcon)
        this.oldSelectedItem = item
      }
    },
    resizeMap: debounce(function (newVal) {
      this.$refs?.mapBase?.$refs?.map?.mapObject?.invalidateSize()
    }, 200),

    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    },

    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    }
  },

  created () {
    this.from = dayjs().subtract(1, 'year').toISOString()
    this.to = dayjs().toISOString()
    this.getVehicles()
    this.showLoader = false
  },

  methods: {
    resetIcon (value) {
      if (!isEmpty(this.selectedIcon)) {
        const marker = this.$refs.mapBase?.findMarkerById(this.selectedIcon?.id)
        if (marker && (value.lat.toFixed(4) !== this.selectedIcon.lat.toFixed(4) || value.lng.toFixed(4) !== this.selectedIcon.lon.toFixed(4))) {
          var currentClass2 = marker.getIcon().options.className
          const oldIcon = L.divIcon({
            className: currentClass2.replace(' selected', ''),
            iconSize: [12, 12]
          })
          marker.setIcon(oldIcon)
          this.selectedIcon = {}
        }
      }
    },

    selectIcon (icon) {
      this.selectedIcon = icon
    },

    showDialog (message) {
      this.dialogMessage = message
      this.dialog = true
    },

    closeDialog () {
      this.dialogMessage = ''
      this.dialog = false
    },

    closeDatetimePicker () {
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
    },

    closeFromMenu () {
      this.getLocations()
      this.fromMenu = false
    },

    closeToMenu () {
      this.getLocations()
      this.toMenu = false
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },

    async exportData () {
      if (this.vehicle) {
        this.showLoader = true
        const params = {
          columns: [
            'vehicle',
            'address',
            'startedAt',
            'finishedAt',
            'days'
          ],
          from: formatSqlDateTime(this.from, 'YYYY-MM-DD'),
          to: formatSqlDateTime(this.to, 'YYYY-MM-DD'),
          vehicleId: this.vehicle
        }
        const url = new URL(`${window.location.origin}/api/satellite-tracking/location-history/export`)
        params.columns.forEach(column => url.searchParams.append('columns[]', column))
        url.searchParams.append('from', params.from)
        url.searchParams.append('to', params.to)
        url.searchParams.append('vehicle_id', params.vehicleId)
        let objectURL = null
        fetch(url.toString())
          .then((res) => {
            if (res.status === 413) {
              this.showLoader = false
              this.showDialog(this.$t('satellite-tracking/location-history.too_many_results'))
            }
            if (!res.ok) {
              throw new Error(res.statusText)
            }
            return res.arrayBuffer()
          })
          .then(arrayBuffer => {
            const byteArray = new Uint8Array(arrayBuffer)
            const link = document.createElement('a')
            objectURL = URL.createObjectURL(
              new Blob(
                [byteArray],
                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
              )
            )
            link.href = objectURL
            const filename = this.$t('satellite-tracking.location_history_menu')
            link.download = `${filename}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .finally(() => {
            this.showLoader = false
            if (objectURL) URL.revokeObjectURL(objectURL)
          })
      }
      else {
        this.showDialog(this.$t('satellite-tracking/location-history.choose_vehicle'))
      }
    },

    async getVehicles () {
      const fetched = await api('satellite-tracking').get('/location-history/get-vehicles')
      this.vehicles = fetched.data
    },

    async getLocations () {
      if (this.vehicle) {
        this.showLoader = true
        const data = []
        this.oldSelectedItem = false
        this.selectedIcon = null
        const params = {
          vehicle_id: this.vehicle,
          from: formatSqlDateTime(this.from, 'YYYY-MM-DD'),
          to: formatSqlDateTime(this.to, 'YYYY-MM-DD')
        }

        const fetched = await api('satellite-tracking').get('location-history/get-locations', params)
        if (fetched.dialog) {
          this.showDialog(fetched.dialog)
        }
        else if (fetched.data) {
          this.locations = fetched.data
          this.locations.forEach((marker) => {
            const params = {
              lat: marker.lat,
              lng: marker.lon,
              startedAt: marker.startedAt,
              finishedAt: marker.finishedAt,
              days: marker.days
            }
            data.push({
              id: marker.id,
              lat: marker.lat,
              lon: marker.lon,
              icon: marker.status,
              popup: {
                popupData: () => this.resolveAddress(params),
                tooltipOptions: {
                  direction: 'auto',
                  offset: [15, 0]
                }
              }
            })
          })
          this.markers = data
          const markersConfig = {
            markers: this.markers,
            icons: this.icons,
            markerClusterOptions: {
              maxClusterRadius: 40
            }
          }
          this.$refs.mapBase?.generateMarkers(markersConfig)
        }
        this.showLoader = false
      }
      else {
        this.showDialog(this.$t('satellite-tracking/location-history.choose_vehicle'))
      }
    },
    async resolveAddress (params) {
      try {
        const response = await api()['satellite-tracking'].get('reverse-geocode', { lat: params.lat, lng: params.lng })
        if (response.data.address) {
          return [
            {
              label: this.$t('satellite-tracking/report.start_time'),
              value: params.startedAt
            },
            {
              label: this.$t('satellite-tracking.finish_time'),
              value: params.finishedAt
            },
            {
              label: this.$t('satellite-tracking/history.trip_duration'),
              value: params.days + (params.days % 10 !== 1 || params.days % 100 === 11 ? ' dana' : ' dan')
            },
            {
              label: this.$t('satellite-tracking/report.address'),
              value: response.data.address
            }
          ]
        }
      }
      catch (error) {
        console.error('Error in resolveAddress:', error)
      }
    }
  }
}
</script>

<style>
  .green-text,
  .orange-text {
    font-weight: bold;
  }
  .green-text {
    color: #519548;
  }

  .orange-text {
    color: #ff8f40;
  }

  .gray_icon {
    background: #4f4f4f;
  }

  .green_icon {
    background: #519548;
  }

  .orange_icon {
    background: #ff8f40;
  }

  .green_icon,
  .gray_icon,
  .orange_icon {
    height: 12px;
    width: 12px;
    z-index:1000;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 5px white;
  }

  .green_icon:hover,
  .gray_icon:hover,
  .orange_icon:hover,
  .green_icon.selected,
  .gray_icon.selected,
  .orange_icon.selected {
    box-shadow: 0px 0px 10px 5px orange;
  }
</style>
