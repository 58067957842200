<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    :mapOptions="mapOptions"
    :showUserLocationsValue="showUserLocations"
    :showPartnerLocationsValue="showPartnerLocations"
    @set-position-marker-radius="positionMarkerRadius => this.$emit('set-position-marker-radius', positionMarkerRadius)"
  >
    <template #controls>
      <slot name="controls"/>
    </template>
    <template #mapContent>
      <slot name="default"/>
    </template>
  </map-base>
</template>

<script>
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import { defaultMapOptions } from '@/global/common/mapConfig'
import MapBase from '@/global/components/map/MapBase.vue'

const { mapGetters } = createNamespacedHelpers('satellite-tracking/tracking-history')

export default {
  name: 'MapBaseTrackingHistory',

  components: {
    MapBase
  },

  props: {
    mapOptions: {
      type: Object,
      default: () => ({
        ...defaultMapOptions,
        zoom: 4,
        renderer: L.canvas(),
        doubleClickZoom: false
      })
    }
  },

  data () {
    return {
      mapStyle: {
        zIndex: 0,
        flex: 1
      }
    }
  },

  computed: {
    ...mapGetters(['checkboxes']),

    showUserLocations () {
      return this.checkboxes.showUserLocations
    },

    showPartnerLocations () {
      return this.checkboxes.showPartnerLocations
    }
  }
}
</script>

<style lang="scss">
/*
  If multiple controls are added into the controls slot, they should be separated with a v-divider.
  This styles the v-divider to maintain consistent spacing.
*/
.leaflet-control .v-divider{
  margin: 8px 0;
}
.v-icon {
  font-size: 1.5rem;
}
.v-label {
  font-size: 0.82rem;
}
.picker-activator-wrapper {
  position: absolute;
  top: 0.75rem;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
}
@media (max-width: 960px) {
  .picker-activator-wrapper {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 1.75rem;
    right: 1rem;
    z-index: 1000;
    transform: translateX(0);
  }
}
@mixin alarm-icons {
  width: 28px;
  height: 40px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  transform-origin: bottom center;
  -webkit-transform-origin: bottom center;
  &:hover {
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}

.pause-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
}

.marker-icon {
  z-index: 201 !important;
}

.l-marker-icon-route-start {
  background: url("/img/icons/icon_route_begin.png") center / contain;
}

.l-marker-icon-route-end {
  background: url("/img/icons/icon_route_end.png") center / contain;
}

.l-marker-icon-route-pause {
  background: url("/img/icons/icon_route_stop.png") center / contain;
}

.l-marker-icon-sensor-start {
  background: url("/img/icons/icon_sensor_start.svg") center / contain;
}

.l-marker-icon-sensor-end {
  background: url("/img/icons/icon_sensor_end.svg") center / contain;
}

.l-marker-icon-route-mid {
  background: white;
  box-shadow: gray 0 0 1px;
  border-radius: 50%;
  z-index: -1 !important;
}

.alarm-legal-overspeed-icon {
  background: url("/img/icons/legal_speed_limit_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-overspeed-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.engine-rpm-overspeed-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-geofence-enter-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-geofence-exit-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-device-fuel-drop {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-device-inactive {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-low-power {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.route-alarm-tooltip {
  &:before {
    border: none;
  }
  .route-alarm-tooltip-title {
    font-weight: bold;
    font-size: 0.83rem;
  }
}
</style>
