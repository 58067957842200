<template>
  <v-row v-if="getSelectedVehicle && getSelectedVehicle.length > 0">
    <v-col class="pa-0 mr-6 align-self-center col-12 col-sm">
      <p class="text-no-wrap white--text mb-0">
        {{ getSelectedVehicle[0].registration }}
      </p>
    </v-col>
    <v-col class="pa-0 mr-6 align-self-center col-12 col-sm">
      <p class="text-no-wrap white--text mb-0">
        {{ getSelectedDateFrom }}
        {{ $t('satellite-tracking/temperature_sensor.to') }}
        {{ getSelectedDateTo }}
      </p>
    </v-col>
    <v-col
      class="align-self-center col-12 col-sm"
      :class="{ 'pa-0': !$vuetify.breakpoint.mobile}"
    >
      <v-row
        v-if="getTemperaturesChartData && getTemperaturesChartData.length > 0"
        :class="{ 'flex-nowrap': !$vuetify.breakpoint.mobile }"
      >
        <v-col
          v-for="(item, index) in getTemperaturesChartData"
          :key="item.sensorName"
          class="ml-6 col-12 col-sm"
          :class=" {
            'pa-0': $vuetify.breakpoint.mdAndUp,
            'py-1': $vuetify.breakpoint.mdAndDown,
          }"
        >
          <title-temp-sensor-icon-and-label
            class="sensor-icon-and-label"
            :sensor-name="item.sensorName"
            :sensor-index="index"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TitleTempSensorIconAndLabel
  from '@/modules/satellite-tracking-module/temperature-sensor/components/sensor-details-components/sensor-chart/TitleTempSensorIconAndLabel'
const {
  mapGetters: mapGettersTemperatureSensor
} = createNamespacedHelpers('satellite-tracking/temperature-sensor')

export default {
  name: 'TempSensorChartTitle',
  components: { TitleTempSensorIconAndLabel },
  computed: {
    ...mapGettersTemperatureSensor([
      'getSelectedVehicle',
      'getSelectedDateFrom',
      'getSelectedDateTo',
      'getTemperaturesChartData'
    ])
  }
}
</script>

<style scoped lang="scss">
p {
  line-height: 1;
  font-size: 11px;
}
</style>
