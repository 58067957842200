var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.vehicleDetailsCheckboxes
      .vehicle
      .find(function (checkbox) { return checkbox.key === 'registration'; })
      .showInTooltip || _vm.getSingleTooltipId === _vm.vehicle.vehicleId)?_c('v-row',{staticClass:"black px-2 rounded fit-content",attrs:{"no-gutters":""}},[_c('strong',{staticClass:"white--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.markerDetails.registration)+" ")])]):_vm._e(),(_vm.vehicleDetailsCheckboxes
      .marker
      .find(function (checkbox) { return checkbox.key === 'speed'; })
      .showInTooltip || _vm.getSingleTooltipId === _vm.vehicle.vehicleId)?_c('v-row',{staticClass:"grey darken-1 px-2 rounded mt-01 fit-content",attrs:{"no-gutters":""}},[_c('strong',{staticClass:"white--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.markerDetails.speed)+" ")])]):_vm._e(),(_vm.vehicleDetailsCheckboxes
      .marker
      .find(function (checkbox) { return checkbox.key === 'voltage'; })
      .showInTooltip || _vm.getSingleTooltipId === _vm.vehicle.vehicleId)?_c('v-row',{staticClass:"grey darken-1 px-2 rounded mt-01 fit-content",attrs:{"no-gutters":""}},[_c('strong',{staticClass:"white--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.markerDetails.voltage)+" ")])]):_vm._e(),((_vm.vehicleDetailsCheckboxes
      .marker
      .find(function (checkbox) { return checkbox.key === 'lastPositionChangeDateTime'; })
      .showInTooltip || _vm.getSingleTooltipId === _vm.vehicle.vehicleId)
      && _vm.vehicle.positionChangeThreshold && (_vm.getPassedTime(_vm.vehicle.lastPositionChangeDateTime) >= _vm.vehicle.positionChangeThreshold) && _vm.markerDetails.speedNumber == 0)?_c('v-row',{staticClass:"grey darken-1 px-2 rounded mt-01 fit-content",attrs:{"no-gutters":""}},[_c('strong',{staticClass:"white--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.markerDetails.lastPositionChangeDateTime)+" ")])]):_vm._e(),(_vm.vehicleDetailsCheckboxes
      .details
      .find(function (checkbox) { return checkbox.key === 'details'; })
      .showInTooltip || _vm.getSingleTooltipId === _vm.vehicle.vehicleId)?_c('div',{staticClass:"pa-2 mt-01 custom-vehicle-details"},[_c('p',{staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s(_vm.markerDetails.labelAndDriver)+" ")])]),_c('p',{staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s(_vm.markerDetails.altitude)+" ")])]),_c('p',{staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s(_vm.markerDetails.deviceTime)+" ")])]),((_vm.markerDetails.digitalSensors && Object.keys(_vm.markerDetails.digitalSensors).length > 0) ||
        (_vm.markerDetails.bluetoothSensors && Object.keys(_vm.markerDetails.bluetoothSensors).length > 0) ||
        (_vm.markerDetails.temperatureSensors && Object.keys(_vm.markerDetails.temperatureSensors).length > 0) ||
        (_vm.markerDetails.analogSensors && Object.keys(_vm.markerDetails.analogSensors).length > 0))?[_c('p',{key:'sensor-status-label',staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s(((this.$t('base/tracker.sensor_status')) + ":"))+" ")])])]:_vm._e(),(_vm.markerDetails.digitalSensors && Object.keys(_vm.markerDetails.digitalSensors).length > 0)?_vm._l((Object.entries(_vm.markerDetails.digitalSensors)),function(ref,index){
        var inputName = ref[0];
        var value = ref[1];
return _c('p',{key:_vm.markerDetails.registration + '-din-' + index,staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s((inputName + ": " + value))+" ")])])}):_vm._e(),(_vm.markerDetails.bluetoothSensors && Object.keys(_vm.markerDetails.bluetoothSensors).length > 0)?_vm._l((Object.entries(_vm.markerDetails.bluetoothSensors)),function(ref,index){
        var inputName = ref[0];
        var value = ref[1];
return _c('p',{key:_vm.markerDetails.registration + '-bt-' + index,staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s((inputName + ": " + value))+" ")])])}):_vm._e(),(_vm.markerDetails.analogSensors && Object.keys(_vm.markerDetails.analogSensors).length > 0)?_vm._l((Object.entries(_vm.markerDetails.analogSensors)),function(ref,index){
        var inputName = ref[0];
        var value = ref[1];
return _c('p',{key:_vm.markerDetails.registration + '-ain-' + index,staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s(inputName + ": " + value + (isNaN(value) ? '' : 'V'))+" ")])])}):_vm._e(),(_vm.markerDetails.temperatureSensors && Object.keys(_vm.markerDetails.temperatureSensors).length > 0)?_vm._l((Object.entries(_vm.markerDetails.temperatureSensors)),function(ref,index){
        var sensorName = ref[0];
        var value = ref[1];
return _c('p',{key:_vm.markerDetails.registration + '-temp-' + index,staticClass:"my-0"},[_c('strong',[_vm._v(" "+_vm._s((sensorName + ": " + value))+" ")])])}):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }