<template>
  <v-container
    :class="{
      'overflow-y-auto': $vuetify.breakpoint.mdAndDown,
      'fill-height fixed-height-container': $vuetify.breakpoint.lgAndUp
    }"
  >
    <v-row  :class="{'fill-height': !$vuetify.breakpoint.md}">
      <v-col
        id="probeDetails"
        class="py-0 pr-1 col-12 col-md-8"
      >
        <probe-details />
      </v-col>
      <v-col
        id="map"
        class="pl-0 py-0 col-12 col-md-4"
        :class="{ 'fixed-height-map pl-4': $vuetify.breakpoint.smAndDown}"
      >
        <probes-map />
        <v-overlay
          id="map-overlay"
          :value="mapLoading"
        >
          <v-progress-circular
            indeterminate
            size="60"
          />
        </v-overlay>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="60"
      />
    </v-overlay>
  </v-container>
</template>

<script>
import ProbeDetails from '@/modules/satellite-tracking-module/fuel-probe/components/ProbeDetails'
import ProbesMap from '@/modules/satellite-tracking-module/fuel-probe/components/ProbesMap'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters: mapGettersFuelProbe,
  mapActions: mapActionsFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

const {
  mapGetters: mapGettersBase,
  mapActions: mapActionsBase
} = createNamespacedHelpers('base/config')

export default {
  name: 'FuelProbeIndexView',

  components: { ProbeDetails, ProbesMap },

  computed: {
    ...mapGettersFuelProbe(['loading', 'mapLoading']),

    ...mapGettersBase(['isCompanyScopeUpdated'])
  },

  watch: {
    isCompanyScopeUpdated: async function () {
      this.resetState()
      await this.fetchConfig()
      this.unsetCompanyScopeUpdated()
    }
  },

  mounted () {
    this.fetchConfig()
  },

  beforeDestroy () {
    this.resetState()
  },

  methods: {
    ...mapActionsFuelProbe(['fetchConfig', 'resetState']),

    ...mapActionsBase(['unsetCompanyScopeUpdated'])
  }
}
</script>

<style scoped lang="scss">
.container {
  .fixed-height-container {
    // --NAVBAR_HEIGHT is height of navbar
    max-height: calc(100vh - var(--NAVBAR_HEIGHT));
  }
  #probeDetails {
    max-height: 100%;
  }
  .fixed-height-map {
    height: 300px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100%;
  }
}
</style>
