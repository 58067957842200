<template>
  <v-row v-if="fuelProbesData && fuelProbesData.length">
    <v-col class="pa-0 mr-6 align-self-center">
      <p class="text-no-wrap white--text mb-0">
        {{ getSelectedVehicle && getSelectedVehicle[0] && getSelectedVehicle[0].registration ? getSelectedVehicle[0].registration : '' }}
      </p>
    </v-col>
    <v-col class="pa-0 mr-6 align-self-center">
      <p class="text-no-wrap white--text mb-0">
        {{ getSelectedDateFrom }}
        {{ $t('satellite-tracking/fuel_probe_report.date_to').slice(-2) }}
        {{ getSelectedDateTo }}
      </p>
    </v-col>
    <v-col class="pa-0 align-self-center">
      <v-row class="flex-nowrap">
        <v-col
          v-for="fuelProbe in fuelProbesData"
          :key="fuelProbe.number"
          class="pa-0 ml-6"
        >
          <title-fuel-probe-icon-and-label
            v-if="fuelProbe.data.length"
            :label="fuelProbe.label"
            :tank-number="fuelProbe.number"
            :show-number="fuelProbe.show_number"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TitleFuelProbeIconAndLabel from '@/modules/satellite-tracking-module/fuel-probe/components/probe-details-components/probe-chart/TitleFuelProbeIconAndLabel'

const {
  mapGetters: mapGettersFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

export default {
  name: 'ProbeChartTitle',

  components: { TitleFuelProbeIconAndLabel },

  computed: {
    ...mapGettersFuelProbe([
      'getSelectedVehicle',
      'getSelectedDateFrom',
      'getSelectedDateTo',
      'fuelProbesData'
    ])
  }
}
</script>

<style scoped lang="scss">
p {
  line-height: 1;
  font-size: 11px;
}
</style>
