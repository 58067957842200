<template>
  <div
    :key="'route-' + route.key"
  >
    <!-- Line tracing the route -->
    <l-polyline
      :lat-lngs="positions"
      :color="route.color"
      :weight="10"
      @mouseover="mouseOverRoute($event, route)"
      @mouseout="mouseOutRoute($event.target)"
      @click="handleRouteClick"
    />
    <!-- Line tracing the route -->
    <l-polyline-decorator
      :paths="[positions]"
      :patterns="patterns"
      @mouseover="mouseOverRoute($event, route)"
      @mouseout="mouseOutRoute($event.target)"
      @click="handleRouteClick"
    />

    <!-- Alarms that happened along the route -->
    <template v-if="checkboxes.showRouteAlarms && alarmData">
      <l-marker
        v-for="alarmEvent in alarmData"
        :key="'alarm-event-' + alarmEvent.id"
        :icon="alarmIcons[alarmEvent.type]"
        :lat-lng="parseLatLng(alarmEvent.coordinates)"
      >
        <l-tooltip :options="tooltipOptions">
          <span class="route-alarm-tooltip-title">
            {{ alarmEvent.title }}
          </span>
          <br>
          {{ alarmEvent.text }}
          <br>
          {{ alarmEvent.dateTime }}
          <br>
          <div v-if="alarmEvent.type !== 'engineRpmOverspeed'">
            {{ alarmEvent.speed }}
          </div>
          <reverse-geocoding-field
            :address="alarmEvent.address"
            :lat-lng="parseLatLng(alarmEvent.coordinates)"
            :vehicle-id="vehicleId"
            :text-alignment="'left'"
            :loading-indicator-alignment="'center'"
          />
        </l-tooltip>
      </l-marker>
    </template>

    <template v-if="positionsData && displayPositions">
      <!-- Points along the route -->
      <l-circle-marker
        v-for="point in positionsData"
        :key="'position-' + point.id"
        :lat-lng="{lat: point.lat, lng: point.lng}"
        :icon="icons.midPoint"
        :radius="positionMarkerRadius"
        color="#0D47A1"
        fill-color="#fff"
        :fill-opacity="1"
        @mouseover="routeDetailHover(point)"
      >
        <l-tooltip>
          {{ formatSqlDateTime(point.dateTime) }}
          <br>
          {{ point.speed }}
          <br>
          <reverse-geocoding-field
            v-if="isHovered.includes(point.id)"
            :address="point.address"
            :lat-lng="{ lat: point.lat, lng: point.lng }"
            :vehicle-id="route.vehicle.id"
            :text-alignment="'center'"
            :loading-indicator-alignment="'center'"
            :tracking-history-config="true"
          />
        </l-tooltip>
      </l-circle-marker>
    </template>

    <!-- Route start marker -->
    <l-marker
      :lat-lng="startCoordinates"
      :icon="icons.startPoint"
    >
      <l-tooltip>
        {{ $t('satellite-tracking/map.route_start') }}
        <br>
        {{ route.startTime }}
        <br>
        <reverse-geocoding-field
          :address="route.startAddress"
          :lat-lng="startCoordinates"
          :vehicle-id="route.vehicle.id"
          :text-alignment="'center'"
          :loading-indicator-alignment="'center'"
        />
        <template v-if="route.startMarkerParkingTime">
          {{ route.startMarkerParkingTime }}
        </template>
      </l-tooltip>
    </l-marker>
    <!-- Route end marker -->
    <l-marker
      :lat-lng="endMarkerPoint"
      :icon="icons.endPoint"
    >
      <l-tooltip>
        {{ $t('satellite-tracking/map.route_end') }}
        <br>
        {{ route.endTime }}
        <br>
        <reverse-geocoding-field
          :address="route.endAddress"
          :lat-lng="{ lat: route.endLat, lng: route.endLng }"
          :vehicle-id="route.vehicle.id"
          :text-alignment="'center'"
          :loading-indicator-alignment="'center'"
        />
        <template v-if="route.endMarkerParkingTime">
          {{ route.endMarkerParkingTime }}
        </template>
      </l-tooltip>
    </l-marker>

    <!-- Route pause marker -->
    <l-marker
      v-for="(pause, index) in route.pauses"
      :key="index"
      :lat-lng="pause"
      :icon="icons.pause"
    >
      <l-tooltip>
        <div class="pause-title">
          {{ $t('satellite-tracking/map.pause') }}
        </div>
        {{ $t('satellite-tracking/history.pause_start_time') }}: {{ pause.startTime }}<br>
        {{ $t('satellite-tracking/history.pause_duration') }}: {{ pause.duration }}<br>
        <reverse-geocoding-field
          :address="pause.address"
          :lat-lng="{ lat: pause.lat, lng: pause.lng }"
          :vehicle-id="vehicleId"
          :text-alignment="'left'"
          :loading-indicator-alignment="'center'"
        />
      </l-tooltip>
    </l-marker>

    <!-- DIN activity polyline and marker icons -->
    <template v-if="checkboxes.showDinStatus && selectedDigitalInputType && !positionsLoading">
      <div
        v-for="(data, index) in sensorData"
        :key="'din-' + index"
      >
        <!-- Positions with active DIN status -->
        <l-polyline
          :lat-lngs="data.positions"
          color="#f4a734"
          :weight="11"
          @click="handleRouteClick"
          @mouseover="mouseOverRoute($event, route)"
          @mouseout="mouseOutRoute($event.target)"
        />
        <!-- DIN active start icon -->
        <l-marker
          v-if="data.startPoint.lat && data.startPoint.lng"
          :lat-lng="data.startPoint"
          :icon="icons.sensorStartPoint"
        >
          <l-tooltip>
            {{ $t('satellite-tracking/map.sensor_operation_start') }}
            <br>
            {{ formatSqlDateTime(data.startPoint.dateTime) }}
            <br>
            <reverse-geocoding-field
              :address="data.startPoint.address"
              :lat-lng="{ lat: data.startPoint.lat, lng: data.startPoint.lng }"
              :vehicle-id="route.vehicle.id"
              :text-alignment="'center'"
              :loading-indicator-alignment="'center'"
            />
          </l-tooltip>
        </l-marker>
        <!-- DIN active end icon -->
        <l-marker
          :lat-lng="data.endPoint"
          :icon="icons.sensorEndPoint"
        >
          <l-tooltip>
            {{ $t('satellite-tracking/map.sensor_operation_end') }}
            <br>
            {{ formatSqlDateTime(data.endPoint.dateTime) }}
            <br>
            <reverse-geocoding-field
              :address="data.endPoint.address"
              :lat-lng="{ lat: data.endPoint.lat, lng: data.endPoint.lng }"
              :vehicle-id="route.vehicle.id"
              :text-alignment="'center'"
              :loading-indicator-alignment="'center'"
            />
          </l-tooltip>
        </l-marker>
      </div>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import L, { latLng } from 'leaflet'
import {
  LPolyline,
  LMarker,
  LCircleMarker,
  LTooltip
} from 'vue2-leaflet'
import polyUtil from 'polyline-encoded'
import LPolylineDecorator from '@/global/components/map/Vue2LeafletPolylinedecorator'
import { formatSqlDateTime } from '@/global/services/helpers/dates'
import ReverseGeocodingField from '@/global/components/geocoding/ReverseGeocodingField'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters, mapActions
} = createNamespacedHelpers('road-maintenance/tasks-report')

export default {
  name: 'MapRoute',

  components: {
    LPolyline,
    LMarker,
    LCircleMarker,
    LTooltip,
    LPolylineDecorator,
    ReverseGeocodingField
  },

  props: {
    route: {
      type: Object,
      required: true
    },
    positionMarkerRadius: {
      type: Number,
      required: true
    },
    alarmIcons: {
      type: Object,
      default: () => ({})
    },
    repositionEndMarker: {
      type: Boolean,
      default: false
    },
    vehicleType: {
      type: [String, null],
      default: null
    },
    vehicleId: {
      type: Number,
      default: null
    },
    isRouteClicked: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      icons: {
        startPoint: L.divIcon({
          className: 'l-marker-icon-route-start marker-icon',
          iconSize: [15, 15]
        }),
        endPoint: L.divIcon({
          className: 'l-marker-icon-route-end marker-icon',
          iconSize: [15, 15]
        }),
        pause: L.divIcon({
          className: 'l-marker-icon-route-pause marker-icon',
          iconSize: [15, 15]
        }),
        midPoint: L.divIcon({
          className: 'l-marker-icon-route-mid marker-icon',
          iconSize: [8, 8]
        }),
        sensorStartPoint: L.divIcon({
          className: 'l-marker-icon-sensor-start marker-icon',
          iconSize: [25, 33.3],
          iconAnchor: [12.5, 33.3]
        }),
        sensorEndPoint: L.divIcon({
          className: 'l-marker-icon-sensor-end marker-icon',
          iconSize: [25, 33.3],
          iconAnchor: [12.5, 33.3]
        })
      },
      patterns: [
        {
          offset: 25,
          repeat: 50,
          symbol: L.Symbol.arrowHead({
            pixelSize: 12,
            headAngle: 30,
            pathOptions: {
              color: '#FFF',
              weight: 0,
              fillOpacity: 0.5
            }
          })
        }
      ],
      // 10 meters
      sameLocationThreshold: 0.0001,
      tooltipOptions: {
        direction: 'right',
        offset: [25, -40],
        className: 'route-alarm-tooltip'
      },
      isHovered: [],
      displayPositions: false
    }
  },

  computed: {
    ...mapGetters([
      'viewConfig',
      'checkboxes',
      'getSelectedTrips',
      'tripPositions',
      'tripAlarms',
      'sensorActivityData',
      'getSelectedTrips',
      'selectedDigitalInputType',
      'companyScopeId',
      'positionsLoading'
    ]),

    endMarkerPoint () {
      if (this.repositionEndMarker) {
        const { startLat, startLng, endLat, endLng } = this.route

        // Check if start and end point are the same, or in the given threshold distance
        if (
          (startLat === endLat && startLng === endLng) ||
          Math.abs(startLat - endLat) <= this.sameLocationThreshold ||
          Math.abs(startLng - endLng) <= this.sameLocationThreshold
        ) {
          // Return new LatLng object moved to the right side of the original position
          return new window.L.LatLng(endLat, endLng + 0.00009)
        }
      }

      return {
        lat: this.route.endLat,
        lng: this.route.endLng
      }
    },

    positions () {
      return polyUtil.decode(this.route.polyline)
    },

    startCoordinates () {
      return {
        lat: this.route.startLat,
        lng: this.route.startLng
      }
    },

    sensorData () {
      const results = []

      // Copy sensor data or set to an empty array
      let sensorDataUnfiltered = []
      try {
        sensorDataUnfiltered = this.sensorActivityData.sensorData[this.route.vehicle.id] ?? []
      }
      catch (e) {
        return results
      }
      if (sensorDataUnfiltered.length === 0) {
        return results
      }
      const tripStart = new Date(this.route.startTimeUnformatted)
      const tripEnd = new Date(this.route.endTimeUnformatted)
      let gotFirstSensor = false
      let previousSensorIndex = null

      const sensorData = sensorDataUnfiltered.filter(function (item, index) {
        const date = new Date(item.date_time)
        if (date <= tripEnd && date >= tripStart) {
          if (!gotFirstSensor) {
            previousSensorIndex = index - 1
            gotFirstSensor = true
          }
          return true
        }
      })

      if (sensorData.length === 0) {
        previousSensorIndex = null
        sensorDataUnfiltered.forEach(function (item, index) {
          const date = new Date(item.date_time)
          if (date <= tripStart) {
            previousSensorIndex = index
          }
        })
        sensorData.push(sensorDataUnfiltered[previousSensorIndex])
      }
      else {
        sensorData.unshift(sensorDataUnfiltered[previousSensorIndex])
      }

      // Return empty results if no sensor data
      if (sensorData.length === 0) {
        return results
      }

      let startPoint = null
      let endPoint = null
      let positions = []

      let sensorActivityStartDate = null
      let sensorActivityEndDate = null
      let sensorDataIndex = 0

      // Function to set sensor activity start and end times
      const getSensorActivityTimes = () => {
        // Find first next sensor item with active status
        const activeSensorItem = sensorData.find((item, index) => {
          if (item) {
            return item.sensor_active && index >= sensorDataIndex
          }
        })
        // Set sensor activity start date to the first active item, null otherwise
        sensorActivityStartDate = activeSensorItem
          ? new Date(activeSensorItem.date_time)
          : null
        // Increment the index so that we skip previous sensor items
        sensorDataIndex++
        // Find first next sensor item with inactive status
        const inactiveSensorItem = sensorData.find((item, index) => {
          if (item) {
            return !item.sensor_active && index >= sensorDataIndex
          }
        })
        // Set sensor activity end date to the first active item
        if (inactiveSensorItem) {
          sensorActivityEndDate = new Date(inactiveSensorItem.date_time)
        }
        // Otherwise, use trip end date if sensor start date is set, null if not
        else {
          sensorActivityEndDate = sensorActivityStartDate ? tripEnd : null
        }
        // Increment the index so that we skip previous sensor items
        sensorDataIndex++
      }

      getSensorActivityTimes()

      if (!sensorActivityStartDate && !sensorActivityEndDate) {
        return results
      }

      // Function to set end poimnt of sensor activity, push to results array and reset data
      const finishSensorActivityPeriod = (position) => {
        endPoint = {
          lat: position.lat,
          lng: position.lng,
          address: position.address,
          dateTime: position.dateTime
        }

        if (tripEnd <= sensorActivityEndDate) {
          endPoint = {
            lat: this.route.endLat,
            lng: this.route.endLng,
            address: this.route.endAddress,
            dateTime: this.parseTripDateTime(this.route.endTimeUnformatted)
          }
        }

        positions.push({
          lat: endPoint.lat,
          lng: endPoint.lng
        })

        results.push({
          startPoint,
          endPoint,
          positions
        })

        startPoint = null
        endPoint = null
        positions = []

        getSensorActivityTimes()
      }

      const positionsCount = this.positionsData?.length

      /* eslint-disable no-unmodified-loop-condition */
      while (sensorActivityStartDate || sensorActivityEndDate) {
        let lastPosition = null
        // edge case
        if (!positionsCount) {
          startPoint = {
            lat: this.route.endlat,
            lng: this.route.endLng,
            address: this.route.endAddress,
            dateTime: sensorActivityStartDate
          }
          finishSensorActivityPeriod({
            lat: this.route.endLat,
            lng: this.route.endLng,
            address: this.route.endAddress,
            dateTime: sensorActivityEndDate
          })
        }
        let shouldSkip = false
        this.positionsData?.forEach((position, index) => {
          if (shouldSkip) {
            return
          }
          const positionDate = new Date(position.dateTime)
          if (lastPosition) {
            const lastPositionDateTime = new Date(lastPosition.dateTime)
            if ((sensorActivityStartDate >= lastPositionDateTime && sensorActivityStartDate < positionDate) || sensorActivityStartDate <= tripStart) {
              if (!startPoint) {
                startPoint = {
                  lat: lastPosition.lat,
                  lng: lastPosition.lng,
                  address: lastPosition.address,
                  dateTime: sensorActivityStartDate
                }
              }
              positions.push({
                lat: lastPosition.lat,
                lng: lastPosition.lng
              })
            }

            if (lastPositionDateTime >= sensorActivityStartDate && lastPositionDateTime <= sensorActivityEndDate) {
              positions.push({
                lat: lastPosition.lat,
                lng: lastPosition.lng
              })
            }

            if (sensorActivityEndDate >= lastPositionDateTime && sensorActivityEndDate < positionDate && startPoint) {
              finishSensorActivityPeriod({
                lat: lastPosition.lat,
                lng: lastPosition.lng,
                address: lastPosition.address,
                dateTime: sensorActivityEndDate
              })
              shouldSkip = true
              return
            }
            // check if last position
            if (index + 1 === positionsCount) {
              if (!startPoint) {
                startPoint = {
                  lat: position.lat,
                  lng: position.lng,
                  address: position.address,
                  dateTime: sensorActivityStartDate
                }
                positions.push({
                  lat: startPoint.lat,
                  lng: startPoint.lng
                })
              }
              finishSensorActivityPeriod({
                lat: position.lat,
                lng: position.lng,
                address: position.address,
                dateTime: sensorActivityEndDate
              })
              return
            }
          }
          lastPosition = position
        })
      }

      return results
    },

    positionsData () {
      let positionsDataUnfiltered = []
      try {
        positionsDataUnfiltered = this.tripPositions.positions[this.route.vehicle.id] ?? []
      }
      catch (e) {
        return null
      }
      const tripStart = new Date(this.route.startTimeUnformatted)
      const tripEnd = new Date(this.route.endTimeUnformatted)

      const positionsFiltered = []
      let lastPos = null

      const positionsCount = positionsDataUnfiltered.length
      positionsDataUnfiltered.forEach((position, index) => {
        if (lastPos) {
          const lastPosDateTime = new Date(lastPos.dateTime)
          const currentPosDateTime = new Date(position.dateTime)
          if ((lastPosDateTime >= tripStart && lastPosDateTime <= tripEnd) || (currentPosDateTime >= tripStart && currentPosDateTime <= tripEnd)) {
            positionsFiltered.push(lastPos)
          }
          // last iteration is not included in check
          if (index + 1 === positionsCount) {
            if (currentPosDateTime >= tripStart && currentPosDateTime <= tripEnd) {
              positionsFiltered.push(position)
            }
          }
        }

        lastPos = position
      })

      return positionsFiltered
    },

    alarmData () {
      let alarmsDataUnfiltered = []
      try {
        alarmsDataUnfiltered = this.tripAlarms.alarms[this.route.vehicle.id] ?? []
      }
      catch (e) {
        return null
      }
      const tripStartDayjs = dayjs(this.route.startTimeUnformatted)
      const tripEndDayjs = dayjs(this.route.endTimeUnformatted)

      return alarmsDataUnfiltered.filter(function (item) {
        const date = dayjs(item.dateTimeUnformated)
        if (date.isBefore(tripEndDayjs) && date.isAfter(tripStartDayjs)) {
          return true
        }
      })
    }
  },

  watch: {
    displayPositions () {
      if (this.isRouteClicked && this.displayPositions) {
        if (!this.selectedDigitalInputType || !this.checkboxes.showDinStatus) {
          this.fetchRoutePositions()
        }
      }
      else {
        this.displayPositions = false
      }
    },

    isRouteClicked () {
      if (!this.isRouteClicked && this.displayPositions) {
        this.displayPositions = false
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchTripPositions',
      'fetchTripAlarms',
      'fetchSensorActivityData',
      'updateTripPositions',
      'updateTripAlarms',
      'updateSensorActivityData',
      'startLoader',
      'stopLoader'
    ]),

    formatSqlDateTime,

    handleRouteClick () {
      this.$emit('clicked-route', this.route.key)
      this.displayPositions = !this.displayPositions
    },

    routeDetailHover (routeDetail) {
      if (!this.isHovered.includes(routeDetail.id)) {
        this.isHovered.push(routeDetail.id)
      }
    },

    mouseOverRoute (event, route) {
      if (this.displayPositions) {
        return
      }
      const polyline = event.target

      const popup = L.popup()
        .setLatLng(event.latlng)
        .setContent(this.$t('satellite-tracking/history.click_for_details'))

      polyline.bindPopup(popup, { closeButton: false })

      popup.openOn(this.$parent.mapObject)
    },

    mouseOutRoute (polyline) {
      polyline.closePopup()
    },

    parseTripDateTime (dateTime) {
      return dayjs(dateTime, 'DD.MM.YYYY. HH:mm:ss').toISOString()
    },

    parseLatLng (coordinatesObject) {
      return latLng(coordinatesObject)
    },

    fetchRoutePositions () {
      this.startLoader()
      this.fetchTripPositions({
        params: {
          vehicle_ids: [this.route.vehicle.id],
          from: this.route.startTime,
          to: this.route.endTime,
          company_scope_id: this.companyScopeId
        }
      })
    }
  }
}
</script>

<style lang="scss">
@mixin alarm-icons {
  width: 28px;
  height: 40px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  transform-origin: bottom center;
  -webkit-transform-origin: bottom center;
  &:hover {
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}

.pause-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
}

.marker-icon {
  z-index: 201 !important;
}

.l-marker-icon-route-start {
  background: url("/img/icons/icon_route_begin.png") center / contain;
}

.l-marker-icon-route-end {
  background: url("/img/icons/icon_route_end.png") center / contain;
}

.l-marker-icon-route-pause {
  background: url("/img/icons/icon_route_stop.png") center / contain;
}

.l-marker-icon-sensor-start {
  background: url("/img/icons/icon_sensor_start.svg") center / contain;
}

.l-marker-icon-sensor-end {
  background: url("/img/icons/icon_sensor_end.svg") center / contain;
}

.l-marker-icon-route-mid {
  background: white;
  box-shadow: gray 0 0 1px;
  border-radius: 50%;
  z-index: -1 !important;
}

.alarm-legal-overspeed-icon {
  background: url("/img/icons/legal_speed_limit_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-overspeed-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.engine-rpm-overspeed-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-geofence-enter-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-geofence-exit-icon {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-device-fuel-drop {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-device-inactive {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.alarm-low-power {
  background: url("/img/icons/rough_driving_big.svg") center / contain;
  @include alarm-icons;
}

.route-alarm-tooltip {
  &:before {
    border: none;
  }
  .route-alarm-tooltip-title {
    font-weight: bold;
    font-size: 0.83rem;
  }
}
</style>
