<template>
  <v-row
    no-gutters
    class="white--text"
  >
    <v-col class="py-0 text-center">
      <p>
        {{ $t('satellite-tracking/temperature_sensor.date') }}
      </p>
    </v-col>
    <v-col class="py-0 text-center">
      <p>
        {{ $t('satellite-tracking/temperature_sensor.time') }}
      </p>
    </v-col>
    <v-col class="py-0 text-center">
      <p>
        {{ $t('satellite-tracking/temperature_sensor.temperature') }}
      </p>
    </v-col>
    <v-col class="py-0 text-center">
      <p>
        {{ $t('satellite-tracking/temperature_sensor.door_sensor') }}
      </p>
    </v-col>
    <v-col class="pb-0 mt-n1 text-center filter">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="grey--text"
            v-bind="attrs"
            v-on="on"
          >
            mdi-filter-variant
          </v-icon>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="selectedOption"
            color="primary"
          >
            <v-list-item
              v-for="(text, key) in options"
              :key="key"
              class="option-item"
            >
              <v-list-item-title v-text="text"></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'VirtualScrollerHeader',

  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      selectedOption: 'all'
    }
  },

  watch: {
    selectedOption (value) {
      this.$emit('filter-updated', Object.keys(this.options)[value])
    }
  }
}
</script>

<style scoped lang="scss">
.option-item {
  min-height: 30px;
}

.row {
  // as virtual scrollbar is 8px width
  margin-right: 8px;

  p {
    font-size: 0.75rem !important;
    padding: 0;
    margin: 0;
  }

  .filter {
    cursor: pointer;
  }
}
</style>
