<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '479px' : '600px'"
    @keydown.enter.prevent="handleConfirm"
    @keydown.esc.prevent="$emit('close')"
  >
    <v-card>
      <v-card-title class="text-break">
        <span class="headline">
          {{ $t('satellite-tracking/fuel_probe_report.choose_parameters') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <!-- Date picker -->
          <v-row class="mt-3 mb-2">
            <v-col>
              <v-menu
                v-model="menuDateFrom"
                :close-on-content-click="false"
                :nudge-right="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 0 : 40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFromFormatted"
                    :label="$t('satellite-tracking/fuel_probe_report.date_from')"
                    prepend-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @blur="parseDmyDate(dateFromFormatted)"
                  />
                </template>
                <v-date-picker
                  v-model="dateFrom"
                  first-day-of-week="1"
                  @input="setParametersForDateToPicker"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuDateTo"
                :close-on-content-click="false"
                :nudge-right="$vuetify.breakpoint.xs ? -70 : $vuetify.breakpoint.sm ? -30 : 40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateToFormatted"
                    :label="$t('satellite-tracking/fuel_probe_report.date_to')"
                    prepend-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @blur="parseDmyDate(dateToFormatted)"
                  />
                </template>
                <v-date-picker
                  v-model="dateTo"
                  :min="dateFrom"
                  :max="maxToDate"
                  first-day-of-week="1"
                  @input="menuDateTo = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <!-- ./ Date picker -->
          <v-row v-if="viewConfig && viewConfig.dataSource">
            <v-col>
              <v-select
                v-model="dataSource"
                :label="viewConfig.dataSource.label"
                :prepend-icon="viewConfig.dataSource.icon"
                :items="viewConfig.dataSource.options"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <vehicle-picker
                key="singleVehicleSelect"
                v-model="selectedVehicle"
                :max-select="1"
                v-bind="vehiclePickerProps"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="secondary"
          @click="$emit('close')"
        >
          {{ $t('satellite-tracking/fuel_probe_report.close') }}
        </v-btn>
        <v-btn
          small
          color="primary"
          @click="handleConfirm"
        >
          {{ $t('satellite-tracking/fuel_probe_report.show_data') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { formatSqlDate, parseDmyDate } from '@/global/services/helpers/dates'
import VehiclePicker from '@/modules/satellite-tracking-module/vehicle-picker/VehiclePicker'
import dayjs from 'dayjs'

const {
  mapActions: mapActionsFuelProbe,
  mapGetters: mapGettersFuelProbe
} = createNamespacedHelpers('satellite-tracking/fuel-probe-report')

const {
  mapGetters: mapGettersBase
} = createNamespacedHelpers('base/config')

export default {
  name: 'ProbeChartVehicleDatePicker',

  components: { VehiclePicker },

  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      menuDateFrom: false,
      dateFrom: null,
      dateFromFormatted: null,
      dateTo: null,
      menuDateTo: false,
      dateToFormatted: null
    }
  },

  computed: {
    ...mapGettersBase(['isCompanyScopeUpdated']),

    ...mapGettersFuelProbe([
      'viewConfig',
      'getDataSource',
      'getSelectedVehicle',
      'getSelectedDateFrom',
      'getSelectedDateTo'
    ]),

    showDialog () {
      return this.dialog
    },

    selectedVehicle: {
      get () {
        return this.getSelectedVehicle
      },
      set (vehicleObj) {
        this.setSelectedVehicle(vehicleObj)
      }
    },

    dataSource: {
      get () {
        return this.getDataSource
      },
      set (dataSource) {
        this.setDataSource(dataSource)
      }
    },

    vehiclePickerProps () {
      return {
        'can-bus-only': this.getDataSource === 'canbus',
        'fuel-probes-only': this.getDataSource === 'probe'
      }
    },

    maxToDate () {
      return dayjs(this.dateFrom).add(31, 'day').format('YYYY-MM-DD')
    }
  },

  watch: {
    dateFrom: function (newDate) {
      this.dateFromFormatted = newDate ? formatSqlDate(newDate) : null
      this.setDateFromFormattedToStore(this.dateFromFormatted)
      const from = dayjs(newDate)
      const to = dayjs(this.dateTo)

      if (from.add(31, 'day').isBefore(to)) {
        this.dateTo = null
      }
    },

    dateTo: function (newDate) {
      this.dateToFormatted = newDate ? formatSqlDate(newDate) : null
      this.setDateToFormattedToStore(this.dateToFormatted)
    },

    isCompanyScopeUpdated: function () {
      this.datesSetup()
    }
  },

  created () {
    this.datesSetup()
  },

  methods: {
    parseDmyDate,

    ...mapActionsFuelProbe([
      'setDataSource',
      'setSelectedVehicle',
      'setSelectedDateFrom',
      'setSelectedDateTo',
      'fetchFuelTanksData',
      'fetchFuelProbesData'
    ]),

    setParametersForDateToPicker () {
      this.menuDateFrom = false
    },

    setDateFromFormattedToStore (date) {
      this.setSelectedDateFrom(date)
    },

    setDateToFormattedToStore (date) {
      this.setSelectedDateTo(date)
    },

    handleConfirm () {
      if (
        !this.getSelectedVehicle ||
        !this.getSelectedVehicle.length ||
        !this.getSelectedDateFrom ||
        !this.getSelectedDateTo
      ) {
        alert(this.$t('satellite-tracking/report.you_should_choose_all_parameters'))
      }
      else {
        this.$emit('close')
        this.fetchFuelTanksData(false)
        this.fetchFuelProbesData()
      }
    },

    datesSetup () {
      this.dateFrom = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      this.dateFromFormatted = formatSqlDate(this.dateFrom)
      this.setDateFromFormattedToStore(this.dateFromFormatted)
      this.dateTo = dayjs().format('YYYY-MM-DD')
      this.dateToFormatted = formatSqlDate(this.dateTo)
      this.setDateToFormattedToStore(this.dateToFormatted)
    }
  }
}
</script>
